import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
// import SalesCahier from './SalesCahier'
import withRouter from '../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import PhBills from './PhBills';
import PhSalesOnHold from './PhSalesOnHold';
import { getPrintSetting as onGetPrintSetting} from '../../../slice/thunks';
import PhSalesPostedToPatient from './PhSalesPostedToPatient';

const PhInvoiceList = (props) => {
    const { section } = useParams();
    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState(1);
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) => ({
            printSettings: setting.printSettings,
            error: setting.error,
        })
    );
    const { printSettings } = useSelector(SettingsProperties);

    const loadPrintSettings = useCallback(() => {
        dispatch(onGetPrintSetting());
    });

    useEffect(() => {
        loadPrintSettings();
    }, [dispatch]);


    useEffect(() => {
        setCurrentTab(parseInt(section) || 1);
    }, [section]);

    const tabs = [
        { id: 1, title: props.t("bills"), component: <PhBills printSettings={printSettings} /> },
        { id: 2, title: props.t("sales_on_hold"), component: <PhSalesOnHold printSettings={printSettings} /> },
        { id: 3, title: props.t("posted_bills"), component: <PhSalesPostedToPatient printSettings={printSettings} /> },
    ];
  
    return (
        <div className="container-fluid pb-2 px-4">
            {/* TABS */}
            <ul className="nav nav-tabs pt-4" id="myTab" role="tablist" style={{ borderBottom: "1px solid #A2A1A833" }}>
                {tabs.map(tab => (
                    <li className="nav-item" role="presentation" key={tab.id} onClick={() => setCurrentTab(tab.id)}>
                        <button
                            className={`nav-link ${currentTab === tab.id ? "active" : ""}`}
                            id={`${tab.title}-tab`}
                            type="button"
                            role="tab"
                            aria-controls={`${tab.title}-tab-pane`}
                            aria-selected={currentTab === tab.id}
                        >
                            <i className="fa fa-user ms-1 me-2" aria-hidden="true"></i>
                            {tab.title}
                        </button>
                    </li>
                ))}
            </ul>

            {/* TABS CONTENT */}
            <div className="tab-content" id="myTabContent">
                {tabs.map(tab => (
                    <div
                        key={tab.id}
                        className={`tab-pane fade ${currentTab === tab.id ? "show active" : ""}`}
                        id={`${tab.title}-tab-pane`}
                        role="tabpanel"
                        aria-labelledby={`${tab.title}-tab`}
                        tabIndex="0"
                    >
                        {React.cloneElement(tab.component, { isActive: currentTab === tab.id })}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default withRouter(withTranslation()(PhInvoiceList))
