import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import Flatpickr from "react-flatpickr"
import { createSelector } from "reselect"
import moment from 'moment'
import makeAnimated from "react-select/animated"
import Select from "react-select"
import TableContainerWarehouse from '../../../components/Common/TableContainerWarehouse'
import { Row, Col, Label } from 'reactstrap'
import {
    getPharmacyInventories as onGetPharmacyInventories,
  } from "../../../slice/thunks"

const PhInventory = (props) => {
    const dispatch = useDispatch()
    const [periods, setPeriods] = useState([]);
    const fp = useRef(null);

    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            pharmacyInventories: setting.pharmacyInventories,
        })
    )
    const { pharmacyInventories } = useSelector(SettingsProperties)

    const loadInventory = useCallback((pageNumber, pageSize) => {
        let reqObject = {}
        if ((pageNumber !== null) && (pageNumber !== undefined)) reqObject.pageNumber = pageNumber;
        if ((pageSize !== null) && (pageSize !== undefined)) reqObject.pageSize = pageSize;
        if (periods.length > 0) reqObject.startDate = moment(periods[0]).format("YYYY-MM-DD");
        if (periods.length > 0) reqObject.endDate = moment(periods[1]).format("YYYY-MM-DD");
        dispatch(onGetPharmacyInventories(reqObject))
    })

    const searchInventory = useCallback((term, pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
            dispatch(loadInventory({term: term, page: pageNumber, size: pageSize}))
        } else {
            dispatch(loadInventory({term: term, page: 1, size: 10}))
        }
    })

    useEffect(() => {
        loadInventory()
    },[dispatch])

    useEffect(() => {
        if (periods.length > 1) {
            loadInventory()
        }
    },[periods])


    const columns = useMemo(
        () => [
          {
            header: props.t("date"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <div className='d-flex align-items-center'>
                          <span>
                            {moment(cell.row.original.inventoryDate).format('DD / MM/ YYYY')}
                          </span>
                  </div>
            }
          },
          {
            header: props.t("service_unit"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{cell.row.original.serviceUnit}</span>
            }
          },
          {
            header: props.t("action"),
            style: "action",
            cell: (cellProps) => {
              return (
                <div className="d-flex justify-content-center">
                    <ul className="list-inline hstack mb-0">
                        <li className="list-inline-item edit" title={props.t("see")}>
                            <Link
                                to={`/pharmacy/inventory-new/${cellProps.row.original.id}/see`}
                                className="text-primary d-inline-block edit-item-btn"
                            >
                                <i className="fa fa-eye" aria-hidden="true"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item edit" title={props.t("edit")}>
                            <Link
                                to={`/pharmacy/inventory-new/${cellProps.row.original.id}/edit`}
                                className="text-primary d-inline-block edit-item-btn"
                            >
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
              );
            },
          },
        ],
        []
    );

  return (
    <div className="patients-list-content">
            {/* <!-- Create patients Start --> */}
            <div className="patients-list  px-2 pt-4 pb-5 ">
                <div className="container-fluid">
                    <div className="heading-parent-sec">
                        <div className="heading-child-one-sec">
                            <h6>{props.t("inventories")}</h6>
                        </div>
                        <div className="heading-child-two-sec d-flex justify-content-end align-items-center">
                            <div className="create-new-patient-btn my-2 ps-2">
                                <img src="../assets/images/new-patient/img-one.png" className="img-fluid" />
                                <div className="create-new-patient-btn-child-two">
                                    <Link to="/pharmacy/inventory-new">
                                        <button className="btn">{props.t("add_inventory")}</button>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pb-2">
                        <div className="see-doctor-patient ">
                            <Row className="second-select pt-3">
                                <Col xl={3} sm={4} >
                                    <div className='mb-2'>
                                        <label className="form-label" htmlFor="date-range">{props.t("select_date_range")}</label>
                                        <div className='d-flex align-items-center'>
                                            <Flatpickr
                                                className='flatpick-custom'
                                                id="date-range"
                                                ref={fp}
                                                placeholder={props.t("select_date_range")}
                                                defaultValue={periods}
                                                value={periods.length > 0 ? periods : null}
                                                options={{
                                                    altInput: true,
                                                    altFormat: "d / m/ Y",
                                                    maxDate: new Date(),
                                                    mode: "range",
                                                }}
                                                onChange={(e) => {
                                                    if (e.length > 1) {
                                                        setPeriods(e)
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                {/* <Col xl={3} sm={4}>
                                    <div className='mb-2'>
                                        <Label htmlFor="status-field" className="form-label"></Label><br/>
                                        <div className="d-flex align-items-center mt-2">
                                            <button
                                                type="button"
                                                className="btn btn-warning mx-1"
                                                onClick={() => {
                                                    if (!fp?.current?.flatpickr) return;
                                                    fp.current.flatpickr.clear();
                                                    setPeriods([]);
                                                }}
                                            >
                                                {props.t("clear")}
                                            </button>
                                        </div>
                                    </div>
                                </Col> */}
                            </Row>
                            <div className="row">
                                <TableContainerWarehouse
                                    columns={columns}
                                    data={((pharmacyInventories && pharmacyInventories.items) || [])}
                                    isGlobalFilter={false}
                                    customPageSize={10}
                                    divClass="card table-card table-warehouse table-responsive"
                                    tableClass="table"
                                    theadClass="thead-sec table-light"
                                    tdClass="td"
                                    tableSize={ pharmacyInventories && pharmacyInventories.size }
                                    tablePage={ pharmacyInventories && pharmacyInventories.page }
                                    tableTotal= { pharmacyInventories && pharmacyInventories.total_items }
                                    tableTotalPage= { pharmacyInventories && pharmacyInventories.total_page }
                                    onNextPage= {loadInventory}
                                    onSearch= {searchInventory}
                                    SearchPlaceholder={props.t("search_in_inventory")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default withRouter(withTranslation()(PhInventory))
