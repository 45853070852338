import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import withRouter from '../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import * as Yup from "yup"
import { useFormik } from 'formik'
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import { Col, Input, Label, Row, Form, FormFeedback, Modal, ModalBody, ModalHeader} from 'reactstrap'
import moment from 'moment'
import makeAnimated from "react-select/animated"
import Select from "react-select"
import {
    getOdpAppointments as onGetOdpAppointments,
    getOdpAppointmentDetail as onGetOdpAppointmentDetail,
    createOdpAppointment as onCreateOdpAppointment,
    updateOdpAppointment as onUpdateOdpAppointment,
    deleteOdpAppointment as onDeleteOdpAppointment,
    markOdpAppointmentAttended as onMarkOdpAppointmentAttended,
} from "../../../slice/thunks"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import TableContainer from '../../../components/Common/TableContainer'
import { Link, useNavigate } from "react-router-dom"
import DeleteModal from '../../../components/Global/DeleteModal'
import GlobalLoader from '../../../components/Common/GlobalLoader'

const MainAppointment = (props) => {
    const dispatch = useDispatch()
    const animatedComponents = makeAnimated()
    const outputLayoutState = (state) => state.Outputs
    const OutputsProperties = createSelector(
        outputLayoutState,
        (output) =>({
            odpAppointments: output.odpAppointments,
            odpAppointment: output.odpAppointment,
            createOdpApointmentSuccess: output.createOdpApointmentSuccess,
            updateOdpSuccess: output.updateOdpSuccess,
            deleteOdpSuccess: output.deleteOdpSuccess,
            markOdpAppointmentSuccess: output.markOdpAppointmentSuccess,
            error: output.error
        })
    )
    const { odpAppointments, odpAppointment, createOdpApointmentSuccess, updateOdpSuccess, deleteOdpSuccess, markOdpAppointmentSuccess, error } = useSelector(OutputsProperties)
    const [status, setStatus] = useState({label: 'All', value: 'all'})
    const [searchInput, setSearchInput] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const fp = useRef(null);
    const [periods, setPeriods] = useState([
        // moment().startOf("days").format("YYYY-MM-DD"),
        // moment().format("YYYY-MM-DD")
    ]);
    const [currentAppointment, setCurrentAppointment] = useState(null)
    const [modal, setModal] = useState(false)
    const [modalAttended, setModalAttended] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)

    const statusList = [
        {label: props.t("all"), value: 'all'},
        {label: props.t("yes"), value: 'yes' },
        {label: props.t("no"), value: 'no' },
    ]

    const loadAppointmentsList = useCallback((pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
            dispatch(onGetOdpAppointments({page: pageNumber, size: pageSize}))
        } else {
            dispatch(onGetOdpAppointments({page: 1, size: 10}))
        }
    })

    const markAsAttended = useCallback(() => {
        if (currentAppointment) {
            dispatch(onMarkOdpAppointmentAttended(currentAppointment.id))
        }
    })

    const toggle = useCallback(() => {
        if (modal) {
          setModal(false);
          validation.resetForm();
          setCurrentAppointment(null);
        } else {
          setModal(true);
        }
    }, [modal])

    const toggleAttend = useCallback(() => {
        if (modalAttended) {
            setModalAttended(false);
            setCurrentAppointment(null);
        } else {
            setModalAttended(true);
        }
    }, [modalAttended])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
        initialValues: {
            appointmentDate: (currentAppointment && currentAppointment.appointmentDate) || '',
            doctorId: (currentAppointment && currentAppointment.doctor && currentAppointment.doctor.id) || '',
        },
        validationSchema: Yup.object({
            appointmentDate: Yup.string().required(props.t("you_need_provide_date")),
        }),
        onSubmit: (values) => {
            const updateAppointment = {
                id: currentAppointment.id,
                body: {
                    appointmentDate: values.appointmentDate,
                    doctorId: currentAppointment.doctor.id,
                }
            }
            dispatch(onUpdateOdpAppointment(updateAppointment))
        },
    })

    const handleSelectAppointment = (arg) => {
        setCurrentAppointment(arg)
        toggle();
    }

    const handleMarkAttended = (arg) => {
        setCurrentAppointment(arg)
        toggleAttend();
    }

    const onClickDelete = (appointment) => {
        setCurrentAppointment(appointment);
        setDeleteModal(true);
    }

    useEffect(() => {
        if (props.isActive) {
            loadAppointmentsList()
        }
    },[props.isActive])

    useEffect(() => {
        if (odpAppointments && odpAppointments.page) {
          setIsLoading(false)
        }
      },[odpAppointments])

    useEffect(() =>{
        if (updateOdpSuccess && !error) {
          validation.resetForm()
          setModal(false)
          setCurrentAppointment(null)
          loadAppointmentsList()
        }
    },[updateOdpSuccess, error, odpAppointment])

    useEffect(() =>{
        if (markOdpAppointmentSuccess && !error) {
          validation.resetForm()
          setModalAttended(false)
          setCurrentAppointment(null)
          loadAppointmentsList()
        }
    },[markOdpAppointmentSuccess, error, odpAppointment])

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function handleDeleteAppointment() {
        if (currentAppointment) {
          dispatch(onDeleteOdpAppointment({id: currentAppointment.id, body: currentAppointment }))
          loadAppointmentsList()
          setDeleteModal(false)
        }
    }

    function capitalizeName(name) {
        if (!name) return '';
        return name
            .split(' ') 
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
            .join(' '); 
    }

    const columns = useMemo(
        () => [
          {
            header: props.t("patient_id"),
            accessorKey: "index",
            style: "button",
            enableColumnFilter: false,
            cell: (cellProps) => {
                return <span>#{cellProps.row.original.patient.patientRef}</span>;
            },
          },
          {
            header: props.t("patients_name"),
            accessorKey: "firstName",
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
                return <div className='d-flex align-items-center'>
                          <span >
                              {capitalizeName((cell.row.original.patient.lastName != null ) ? cell.row.original.patient.firstName+" "+cell.row.original.patient.lastName : cell.row.original.patient.firstName)}
                          </span>
                  </div>
            }
          },
          {
            header: props.t("doctor_consultant"),
            accessorKey: "firstName",
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
                return <div className='d-flex align-items-center'>
                          <span >
                              {(cell.row.original.patient.lastName != null ) ? cell.row.original.patient.firstName+" "+cell.row.original.patient.lastName : cell.row.original.patient.firstName}
                          </span>
                  </div>
            }
          },
          {
            header: props.t("date"),
            accessorKey: "dob",
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
                return <div className='d-flex align-items-center'>
                          <span>
                            {moment(cell.row.original.appointmentDate).format('DD / MM/ YYYY')}
                          </span>
                  </div>
            }
          },
          {
            header: props.t("attended"),
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
                switch (cell.row.original.attended) {
                    case true:
                            return <div className='d-flex align-items-center'><span> {props.t('yes')} </span></div>
                        break;
                    case false:
                            return <div className='d-flex align-items-center'><span> {props.t('no')} </span></div>
                        break;
                
                    default:
                        return <div className='d-flex align-items-center'><span> - </span></div>
                        break;
                }
                
            }
          },
          {
            header: props.t("action"),
            style: "",
            cell: (cellProps) => {
              return (
                <div className="">
                    <ul className="list-inline hstack mb-0">
                        <li className="list-inline-item edit" title={props.t("edit")}>
                            <Link 
                                to="#"
                                className="text-primary d-inline-block edit-item-btn"
                                onClick={() => { const appointmentData = cellProps.row.original; handleSelectAppointment(appointmentData); }}
                            >
                                <i className="fa-solid fa-pencil"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item edit" title={props.t("mark_attended")}>
                            { !cellProps.row.original.attended ?
                                <Link 
                                    to="#"
                                    className="text-success d-inline-block edit-item-btn"
                                    onClick={() => { const appointmentData = cellProps.row.original; handleMarkAttended(appointmentData); }}
                                >
                                    <i className="fa-solid fa-check"></i>
                                </Link>
                                :<Link
                                    to="#"
                                    className="text-success d-inline-block edit-item-btn">

                                </Link>
                            }
                        </li>
                        <li className="list-inline-item edit" title={props.t("delete")}>
                            <Link 
                                to="#"
                                className="text-danger d-inline-block remove-item-btn"
                                onClick={() => { const appointmentData = cellProps.row.original; onClickDelete(appointmentData); }}
                            >
                                <i className="fa-solid fa-trash text-danger"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
              );
            },
          },
        ],
        [props]
    );


  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="patients-list-content p-1 m-0" id="table1">
                    <div className="container-fluide px-2">
                        <div className="row">
                            <div className="pt-1">
                                <div className='justify-content-center'>
                                    <TableContainer
                                        columns={columns}
                                        data={((odpAppointments && odpAppointments.items) || [])}
                                        isGlobalFilter={false}
                                        customPageSize={10}
                                        divClass="table-card-ward"
                                        tableClass="align-middle"
                                        theadClass="thead-light table-light"
                                        tableSize={ odpAppointments && odpAppointments.size }
                                        tablePage={ odpAppointments && odpAppointments.page }
                                        tableTotal= { odpAppointments && odpAppointments.total_items }
                                        tableTotalPage= { odpAppointments && odpAppointments.total_page }
                                        onNextPage= {loadAppointmentsList}
                                        onSearch= {loadAppointmentsList}
                                        SearchPlaceholder={props.t("search_patient")}
                                    />
                                </div>
                            </div>
                        </div>
                        <DeleteModal show={deleteModal} onDeleteClick={handleDeleteAppointment} onCloseClick={() =>setDeleteModal(false)} />
                        <Modal id='showModal' size='md' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                            <ModalHeader className="bg-light p-3" toggle={toggle}>
                                {props.t("edit_appointment")}
                            </ModalHeader>
                            <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}>
                            <ModalBody>
                                <input type="hidden" id="id-field" />
                                <Row>
                                    <Col xl={12} sm={12}>
                                        <div className='mb-2 mt-1'>
                                            <Label htmlFor="appointmentDate-field" className="form-label">{props.t("appointment_date")}<span className='text-danger'>*</span></Label><br/>
                                            <Input 
                                                name="appointmentDate" id='appointmentDate-field' className="form-control" type="date"
                                                validate={{ required: { value: true } }} min={getTodayDate()} onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} value={validation.values.appointmentDate || ""}
                                                autoComplete="off"
                                                invalid={
                                                    validation.touched.appointmentDate && validation.errors.appointmentDate ? true : false
                                                } 
                                            />
                                            {validation.touched.appointmentDate && validation.errors.appointmentDate ? (
                                                <FormFeedback type="invalid">{validation.errors.appointmentDate}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <div className='p-3'>
                                <Row className="align-items-center">
                                <div className="col-sm">
                                    <span className='text-danger align-middle'>* :{props.t("required_fields")}</span>
                                </div>
                                <div className="col-sm-auto">
                                    <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm() }}> {props.t("close")} </button>
                                    <button type="submit" className="btn btn-success"> {props.t("update")} </button>
                                    </div>
                                </div>
                                </Row>
                            </div>
                        </Form>
                        </Modal>

                        <Modal id='modalAttended' size='md' isOpen={modalAttended || false} toggle={toggleAttend} backdrop={'static'} centered>
                            <ModalBody className="py-3 px-5">
                                <div className="mt-2 text-center">
                                    <div className="mt-4 pt-2 mx-4 mx-sm-5">
                                        <h4>{props.t("are_you_sure")}</h4>
                                        <span>{props.t("mark_this_appointment_as_attended")}</span>
                                    </div>
                                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                        <button
                                            type="button"
                                            className="btn w-sm btn-light"
                                            data-bs-dismiss="modal"
                                            onClick={() => { setModalAttended(false)}}
                                        >
                                            {props.t("close")}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn w-sm btn-success "
                                            id="delete-record"
                                            onClick={() => {markAsAttended()}}
                                        >
                                            <i className="fa fa-check"></i>
                                            {props.t("yes_mark_it")}
                                        </button>
                                    </div>

                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(MainAppointment))
