import React, { Fragment, useCallback, useEffect, useState } from "react"
import { t } from 'i18next'
import { Link } from "react-router-dom"
import { CardBody, Col, Row, Table } from "reactstrap"

import CustomPagination from "./CustomPaginaition"

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender
} from '@tanstack/react-table'

import { rankItem } from '@tanstack/match-sorter-utils';
import withRouter from "./withRouter"
import { withTranslation } from "react-i18next"


  // Column Filter
const Filter = ({
    column,
    // table
  }) => {
    const columnFilterValue = column.getFilterValue();

    return (
      <>
        <DebouncedInput
          type="text"
          value={(columnFilterValue ?? '')}
          onChange={(event) => column.setFilterValue(event.target.value)}
          placeholder="Search..."
          className="w-36 border shadow rounded"
          list={column.id + 'list'}
        />
        <div className="h-1" />
      </>
    );
};

// Global Filter
const DebouncedInput = ({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
  }) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value);
      }, debounce);

      return () => clearTimeout(timeout);
    }, [debounce, onChange, value]);

    const handleInputChange = (event) => {
        event.preventDefault();
        setValue(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); 
        }
    };

    return (
      <input
        {...props}
        value={value}
        type="text"
        id="search-bar-0"
        className="form-control search"
        onChange={(e) =>
            handleInputChange(e)
        }
        onKeyPress={handleKeyPress}
      />
    );
  };

const TableContainer = ({
    columns,
    data,
    isGlobalFilter,
    customPageSize,
    tableClass,
    theadClass,
    trClass,
    tdClass,
    divClass,
    tablePage,
    tableTotal,
    onNextPage,
    onSearch,
    tableTotalPage,
    SearchPlaceholder,
    t = (key) => key,
}) => {
    const [columnFilters, setColumnFilters] = useState ([])
    const [globalFilter, setGlobalFilter] = useState('')
    const [currentPage, setCurrentPage] = useState(1)

    const fuzzyFilter = (row, columnId, value, addMeta) => {
        const itemRank = rankItem(row.getValue(columnId), value);
        addMeta({
          itemRank
        });
        return itemRank.passed;
    }

    const table = useReactTable({
        columns,
        data,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
        },
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel()
    })

    const {
        getHeaderGroups,
        getRowModel,
        setPageSize,
    } = table

    useEffect(() => {
        (customPageSize) && setPageSize((customPageSize));
    }, [customPageSize, setPageSize])

    useEffect(() => {
        if (globalFilter && globalFilter.length > 2) {
            onSearch(globalFilter)
        } else if (globalFilter  && globalFilter.length === 0) {
            onNextPage()
        }
    },[globalFilter])


    const handleSearch = useCallback((value) => {
        if (value.length > 0 ) {
            onSearch(globalFilter)
        } else if (value.length === 0) {
            onNextPage()
        }
    },[globalFilter])

    return (
        <Fragment>

            {data && data.length ?
                <>
                    {isGlobalFilter && <Row className="mb-2 show-print">
                        <CardBody className=" border-end-0 border-start-0">
                        <form>
                            <Row>
                                <Col sm={5}>
                                    <div className="d-flex align-items-center">
                                        <div className="search-box border rounded me-2 mb-1 col-10">
                                            <DebouncedInput
                                                value={globalFilter ?? ''}
                                                onChange={(value) => setGlobalFilter(value)}
                                                placeholder={SearchPlaceholder}
                                                className="w-100" 
                                            />
                                        </div>
                                        <button type="button" onClick={() => handleSearch(globalFilter)} className="btn btn-primary">
                                            <i className="fa fa-search p-0"></i>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                        </CardBody>
                    </Row>}
                    <div className={divClass}>
                        <Table className={tableClass}>
                            <thead className={theadClass}>
                                {getHeaderGroups().map((headerGroup) => (
                                    <tr className={trClass} key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                        <th key={header.id} className={header.column.columnDef ? header.column.columnDef.style : ''}  {...{
                                            onClick: header.column.getToggleSortingHandler(),
                                        }}>
                                            {header.isPlaceholder ? null : (
                                            <React.Fragment>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{
                                                    asc: ' ',
                                                    desc: ' ',
                                                }
                                                [header.column.getIsSorted()] ?? null}
                                                {header.column.getCanFilter() ? (
                                                <div>
                                                    <Filter column={header.column} table={table} />
                                                </div>
                                                ) : null}
                                            </React.Fragment>
                                            )}
                                        </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                { 
                                    getRowModel().rows.map((row) => {
                                        return (
                                            <tr key={row.id}>
                                                {row.getVisibleCells().map((cell) => {
                                                    return (
                                                    <td className={tdClass} key={cell.id}>
                                                        {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                        )}
                                                    </td>
                                                    );
                                                })}
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                    <Row className="align-items-center mt-2 g-3 p-2 text-center text-sm-start show-print">
                        <div className="col-sm">
                            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                                <li className={currentPage > 1 ? "page-item" : "page-item disabled"}>
                                    <Link to="#" className="page-link"
                                        onClick={() => {
                                            if (isGlobalFilter && globalFilter !== '') {
                                                onSearch(globalFilter, currentPage - 1, 10);
                                                setCurrentPage(currentPage - 1);
                                            } else {
                                                onNextPage(currentPage - 1, 10);
                                                setCurrentPage(currentPage - 1);
                                            }
                                        }}
                                    >
                                        <i className="fa fa-chevron-left"></i>
                                    </Link>
                                </li>
                                {[...Array(tableTotalPage).keys()].map((index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <CustomPagination item={ index + 1} gotoPage={onNextPage} tablePage={tablePage} tableTotalPage={tableTotalPage} setCurrentPage={setCurrentPage}/>
                                        </React.Fragment>
                                    );
                                })}
                                <li className={currentPage < tableTotalPage ? "page-item" : "page-item disabled"}>
                                    <Link to="#" className="page-link"
                                        onClick={() => {
                                            if (isGlobalFilter && globalFilter !== '') {
                                                onSearch(globalFilter, currentPage + 1, 10);
                                                setCurrentPage(currentPage + 1);
                                            } else {
                                                onNextPage(currentPage + 1, 10);
                                                setCurrentPage(currentPage + 1);
                                            }
                                        }}
                                    >
                                        <i className="fa fa-chevron-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-auto">
                            <div className="text-muted">{t("showing")}<span className="fw-semibold ms-1">{data.length}</span> {t("of")} <span className="fw-semibold">{tableTotal}</span> {t("results")}
                            </div>
                        </div>
                    </Row>
                </>
                :
                <>
                    <div className='d-flex justify-content-center p-3'>
                        <div className='d-flex align-items-center flex-column p-3'>
                            <i className="fa-solid fa-triangle-exclamation global_empty"></i>
                            <h4> {t("no_result_found")}</h4>
                        </div>
                    </div>
                </>
            }
        </Fragment>
    )
}

export default withRouter(withTranslation()(TableContainer))
