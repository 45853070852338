import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter';
import { useDispatch } from "react-redux"

import {clearPayrollSetting} from "../../../slice/thunks"

const PayrollSettings = (props) => {
    const dispatch = useDispatch()
    const permission = JSON.parse(localStorage.getItem("perm"));

    useEffect(() => {
        dispatch(clearPayrollSetting())
    },[dispatch])

  return (
    <div className="dashboard">
        {/* <!-- Start settings-three-settings Section --> */}
        <div className="settings-three-settings-sec py-3 px-2">
            <div className="container">
                <div className="heading-parent-sec">
                    <h6>{props.t("settings")}</h6>
                </div>
                <div className="settings-three-main-settings-sec ps-4">
                    <div className="container">
                        <div className="row-parent-sec">
                            <div className="row-child-sec">
                                <Link to="/payroll/setting/staffType">
                                    <div className="img-sec">
                                        <img alt="" src={'../../../assets/images/settings-three-settings/settings-one.png'}
                                            className="img-fluid" />
                                    </div>
                                    <div className="text-sec">
                                        <p>{props.t("staff_type")}</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="row-child-sec">
                                <Link to="/payroll/setting/departments">
                                    <div className="img-sec">
                                        <img alt="" src={"../../../assets/images/settings-three-settings/settings-two.png"}
                                            className="img-fluid" />
                                    </div>
                                    <div className="text-sec">
                                        <p>{props.t("departments")}</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="row-child-sec">
                                <Link to="/payroll/setting/positions">
                                    <div className="img-sec">
                                        <img alt="" src={"../../../assets/images/settings-three-settings/settings-three.png"}
                                            className="img-fluid" />
                                    </div>
                                    <div className="text-sec">
                                        <p>{props.t("positions")}</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="row-child-sec">
                                <Link to="/payroll/setting/echelons">
                                    <div className="img-sec">
                                        <img alt="" src={"../../../assets/images/settings-three-settings/settings-four.png"}
                                            className="img-fluid" />
                                    </div>
                                    <div className="text-sec">
                                        <p>{props.t("echelon")}</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="row-child-sec">
                                <Link to="/payroll/setting/categories">
                                    <div className="img-sec">
                                        <img alt="" src={"../../../assets/images/settings-three-settings/settings-five.png"}
                                            className="img-fluid" />
                                    </div>
                                    <div className="text-sec">
                                        <p>{props.t("categories")}</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="row-child-sec">
                                <Link to="/payroll/setting/bonus">
                                    <div className="img-sec">
                                        <img alt="" src={"../../../assets/images/settings-three-settings/settings-six.png"}
                                            className="img-fluid" />
                                    </div>
                                    <div className="text-sec">
                                        <p>{props.t("bonus")}</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default withRouter(withTranslation()(PayrollSettings))