import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'

import withRouter from '../../Common/withRouter'

const SaleRow = (props) => {

    const [quantity, setQuantity] = useState(props.row.quantity)
    const [rowTotal, setRowTotal] = useState(null)

    const handleBlur = (e, setValue) => {
        if (e.target.value === '' || e.target.value === '0' || e.target.value <= 1) {
            setValue(1);
        }
        if (e.target.value >= props.row.qtyStock) {
            setValue(props.row.qtyStock);
        }
    };

    useEffect(() => {
        
        if (quantity >= 0) {
            let disc = (props.row.unitPrice * quantity) - (((props.row.unitPrice * quantity)*props.row.discount)/100);
            setRowTotal(disc); 
        }
    }, [quantity]);
    

    useEffect(() =>{
        if (rowTotal) {
            if (quantity > 0) {
                if (props.isValidate) {
                    let object = {
                        productId: props.row.productId,
                        name: props.row.name,
                    }
                    props.updateRow(object)
                } else {
                    let object = {
                        value: props.row.value,
                        quantity: quantity,
                        rowTotal: rowTotal
                    }
                    props.updateRow(object)
                }
            }
        }
    },[rowTotal])

  return (
    < >
        <td style={{ width: "10%" }}>{props.index+1}</td>
        <td style={{ width: "20%" }}>{props.row.label}</td>
        <td style={{ width: "10%" }}>{props.row.unitPrice}</td>
        <td style={{ width: "10%" }}>{props.row.discount}</td>
        <td style={{ width: "10%" }}>
            <input 
                type="number" 
                className="form-control m-0" 
                value={quantity} 
                onChange={(e) => setQuantity(Number(e.target.value))} onBlur={(e) => handleBlur(e, setQuantity)} 
                aria-label="Text input with checkbox" 
                disabled = {props.isWatch || (props.row.type === 'Service') ? true : false} 

            />
        </td>
        <td style={{ width: "10%" }}>{rowTotal}</td>
        {!props.isWatch && (props.isAuthor && (props.sale && props.sale.id)) || !(props.sale && props.sale.id) ?
            <td style={{ width: "10%" }} className="delete-action" onClick={() => props.deleteRow(props.row) }>
                <button 
                    className="delete-action-btn">
                    <i className="fa-solid fa-trash-can"></i>
                </button>
            </td>
            :
            <></>
        }
    </>
  )
}

export default withRouter(withTranslation()(SaleRow))
