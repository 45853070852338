import React from "react"
import { Modal, ModalBody } from "reactstrap"
import { withTranslation } from 'react-i18next'
import withRouter from '../Common/withRouter'

const ApproveModal = (props) => {
  return (
    <Modal isOpen={props.show} toggle={props.onCloseClick} backdrop={'static'} centered={true}>
        <ModalBody className="py-2 px-3">
            <div className="mt-2 text-center">
                <div className="mt-4 pt-2 mx-4 mx-sm-5">
                    <i className="fa fa-check-double"></i>
                    <h4>{props.t("are_you_sure")}</h4>
                    <span>
                        {props.message ?
                            props.message
                            : props.t("sure_to_confirm")
                        }
                    </span>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button
                        type="button"
                        className="btn w-sm btn-light"
                        data-bs-dismiss="modal"
                        onClick={props.onCloseClick}
                    >
                        {props.t("close")}
                    </button>
                    <button
                        type="button"
                        className="btn w-sm btn-success "
                        id="delete-record"
                        onClick={props.onConfirmClick}
                    >
                        {props.t("yes_approve")}
                    </button>
                </div>

            </div>
        </ModalBody>

    </Modal>
  )
}

export default withRouter(withTranslation()(ApproveModal))
