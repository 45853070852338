import { APIClient } from "./api_helper"
import * as url from "./url_helper"

const api = new APIClient();

export const postLogin = data => api.create(url.POST_LOGIN, data)
export const postRefresh = data => api.create(url.REFRESH_TOKEN, data)
export const validateToken = () => api.get(url.VALIDATE_TOKEN)
export const getUser = () => api.get(url.GET_USER)
export const postLogout = () => api.delete(url.POST_LOGOUT)

// Roles
export const getRoles = (config) => api.get(config ?  url.GET_ROLES + '?page=' + config.page + '&size=' + config.size : url.GET_ROLES)
export const getAllRoles = () => api.get(url.GET_ALL_ROLES)
export const addNewRole = data => api.create(url.POST_ROLE, data)
export const getRole = () => api.get(url.GET_ROLE)
export const updateRole = (config) => api.put(url.UPDATE_ROLE + '/' + config.id, config.data)
export const deleteRole = (config) => api.delete(url.DELETE_ROLE + '/' + config.id, config.data)
export const searchRole = (config) => api.get(url.SEARCH_ROLE+ '?term=' +config.term+ '&page=' +config.page+ '&size=' +config.size)

// Permissions
export const getPermissions = () => api.get(url.GET_PERMISSIONS)
export const getPermission = id => api.get(url.GET_PERMISSION + '/' + id)
export const updatePermission = (data, id) => api.put(url.UPDATE_PERMISSION + '/' + id, data)

// Users
export const getUsers = (config) => api.get(config ?  url.GET_USERS + '?page=' + config.page + '&size=' + config.size : url.GET_USERS)
export const loadUser = () => api.get(url.LOAD_USER)
export const addNewUser = data => api.create(url.ADD_USER, data)
export const updateUser = (config) => api.put(url.UPDATE_USER + '/' + config.id, config.data)
export const deleteUser = (config) => api.delete(url.DELETE_USER + '/' + config.id, config.data)
export const searchUser = (config) => api.get(url.SEARCH_USER+ '?term=' +config.term+ '&page=' +config.page+ '&size=' +config.size)

// Wards
export const getWards = (config) => api.get(config ?  url.GET_WARDS + '?page=' + config.page + '&size=' + config.size : url.GET_WARDS)
export const loadWards = () => api.get(url.LOAD_WARDS)
export const addNewWard = data => api.create(url.ADD_WARD, data)
export const updateWard = (config) => api.put(url.UPDATE_WARD+ '/' +config.id, config.data)
export const deleteWard = (config) => api.delete(url.DELETE_WARD + '/' + config.id, config.data)
export const searchWard = (config) => api.get(url.SEARCH_WARD+ '?term=' +config.term+ '&page=' +config.page+ '&size=' +config.size)

// Beds
export const getBeds = (config) => api.get(config ?  url.GET_BEDS + '?page=' + config.page + '&size=' + config.size : url.GET_BEDS)
export const getBedsByWard = (config) => api.get(config.status ? url.GET_BEDS_BY_WARD+ '/' +config.id+ '/beds?status=' +config.status : url.GET_BEDS_BY_WARD+ '/' +config.id+ '/beds' )
export const addNewBed = data => api.create(url.ADD_BED, data)
export const updateBed = (config) => api.put(url.UPDATE_BED+ '/' +config.id, config.data)
export const deleteBed = (config) => api.delete(url.DELETE_BED + '/' + config.id, config.data)
export const searchBed = (config) => api.get(url.SEARCH_BED+ '?term=' +config.term+ '&page=' +config.page+ '&size=' +config.size)
export const assignBedPatient = (config) => api.create(url.ASSIGN_BED_PATIENT+ '/' +config.id+ '/assign-bed', config.data)
export const leaveBed = (config) => api.put(url.LEAVE_BED+'/'+config.id+'/release-bed', config.data)

// Patients
export const getPatients =  (config) => api.get(config ? url.GET_PATIENTS + '?page=' + config.page + '&size=' + config.size : url.GET_PATIENTS)
export const getPatientTypes = () => api.get(url.GET_PATIENTS_TYPE)
export const getPatien = (config) => api.get(url.GET_PATIENT+'/'+config.id)
export const getRecordPatient = (config) => api.get(url.GET_PATIENT_RECORD+'/'+config.id+'/patient-records')
export const getRecordByPatient = (config) => api.get(url.GET_RECORD_BY_PATIENT+'/'+config.id)
export const addNewPatient = data => api.create(url.ADD_PATIENT, data)
export const updatePatient = (config) => api.put(url.UPDATE_PATIENT+ '/' +config.id, config.data)
export const deletePatient = (config) => api.delete(url.DELETE_PATIENT + '/' + config.id, config.data)
export const admitPatient = (config) => api.create(url.ADMIT_PATIENT+'/'+config.id+ '/admit', config.data)
export const searchPatient = (config) => api.get(url.SEARCH_PATIENT+ '?term=' +config.term+ '&page=' +config.page+ '&size=' +config.size)
export const getHospitalisationList = (config) => api.get(
    config ? url.GET_HOSPITALISATION_LIST+(config.term ? '?term='+config.term : '?term=')+(config.page ? '&page='+config.page : '&page=1')+ (config.size ? '&size='+config.size : '&size=10'):
    url.GET_HOSPITALISATION_LIST)
export const markPatientConsulted = (config) => api.put(url.MARK_PATIENT_CONSULTED+'/'+config.id+'/mark-as-consulted', config.body)

// Doctors
export const getDoctors = (config) => api.get(config ? url.GET_DOCTORS + '?page=' + config.page + '&size=' + config.size : url.GET_DOCTORS)
export const getAllDoctors = () => api.get(url.GET_ALL_DOCTORS)
export const getDoctorTitle = () => api.get(url.GET_DOCTOR_TITLE)
export const getDoctorInfo = (id) => api.get(url.GET_DOCTOR_INFO+ '/' +id)
export const updateDocotor = (config) => api.put(url.UPDATE_DOCTOR + '/' + config.id, config.data)
export const deleteDoctor = (id) => api.delete(url.DELETE_DOCTOR + '/' +id)
export const searchDoctor = (config) => api.get(url.SEARCH_DOCTOR+ '?term=' +config.term+ '&page=' +config.page+ '&size=' +config.size)
export const assignDoctor = (config) => api.create(url.ASSIGN_DOCTOR+ '/'+config.recordId+'/assign-doctor', config.data)
export const markPatientConsultedDoctor = (config) => api.put(url.MARK_PATIENT_CONSULTED_DOCTOR+'/'+config.id+'/mark-as-consulted', config.body)
export const getPatientLabRecords = (config) => api.get(
    config ? url.GET_PATIENTS_LAB_RECORDS+(config.page ? '?page='+config.page : '&page=1')+ (config.size ? '&size='+config.size : '&size=10'):
    url.GET_PATIENTS_LAB_RECORDS
)


// Patient vitals
export const getPatientVitals = (id) => api.get(url.LOAD_PATIENT_VITALS+ '/' +id)
export const getPatientAllVitals = (id) => api.get(url.GET_PATIENT_VITALS+ '/' +id+ '/vitals')
export const getPatientAllVitalsTable = (config) => api.get(url.LOAD_PATIENT_VITALS_PAGE+'/'+config.id+'/vitals/all'+( config.page ? +'?page='+config.page: '')+( config.size ? '&size='+config.size: '') )
export const addNewPatientVitals = (data) => api.create(url.ADD_PATIENT_VITALS, data)
export const updatePatientVitals = (config) => api.put(url.UPDATE_PATIENT_VITALS+ '/' +config.id, config.data)
export const getConsultationList = (config) => api.get(
    config ? url.GET_CONSULTATION_LIST+ '?page='+config.page+ '&size='+config.size+ (config.term ?'&term='+config.term : '' )+ (config.status ?'&status='+config.status : '' ) +(config.fromDate ?'&fromDate='+config.fromDate+'&toDate='+config.toDate : '') :
    url.GET_DOCTORS
)

// Record
export const changeDoctorToSee = (config) => api.put(url.CHANGE_DOCTOR_TO_SEE+ '/'+config.recordId+'/change-doctor', config.data)
export const getDoctorToSee = (id) => api.get(url.GET_DOCTOR_TO_SEE+ '/'+id+'/get-doctor')
export const getPatientVitalsRecords = (id) => api.get(url.LOAD_PATIENT_VITALS_RECORD+ '/' +id+ '/vitals')
export const releasePatient = (config) => api.put(url.RELEASE_PATIENT+'/'+config.id+'/release-patient', config.data)

// Categoies
export const getCategories = (config) => api.get(config ? url.GET_CATEGORIES+ '?page=' + config.page + '&size=' + config.size : url.GET_CATEGORIES)
export const getAllCategories = () => api.get(url.GET_CATEGORIES_PARENT)
export const getCategory = (id) => api.get(url.GET_CATEGORY+'/'+id)
export const createCategory = (data) => api.create(url.CREATE_CATEGORY, data)
export const updateCategory = (config) => api.put(url.UPDATE_CATEGORY+ '/'+config.id, config.data)
export const deleteCategory = (id) => api.delete(url.DELETE_CATEGORY+ '/' +id)
export const searchCategory = (config) => api.get(url.SEARCH_CATEGORY+ '/search?term=' +config.term)

// Doctor panel
export const getConsultationListDoctor = (config) => api.get(
    config ? url.GET_CONSULTATION_LIST_FOR_DOCTOR+ '?page='+config.page+ '&size='+config.size+ (config.term ?'&term='+config.term : '' )+ (config.status ?'&status='+config.status : '' ) +(config.fromDate ?'&fromDate='+config.fromDate+'&toDate='+config.toDate : '') :
    url.GET_CONSULTATION_LIST_FOR_DOCTOR
)

export const getDoctorsAppointments = (config) => api.get(config ? url.GET_DOCTOR_APPOINTMENTS+ '?page=' + config.page + '&size=' + config.size : url.GET_DOCTOR_APPOINTMENTS)
export const registerAppointment = (config) => api.create(url.REGISTER_APPOINTMENTS, config)
export const getAppointmentsDetails = (id) => api.get(url.GET_APPOINTMENTS_DETAIL+'/'+id)
export const updateAppointment = (config) => api.put(url.UPDATE_APPOINTMENT+'/'+config.id, config.body)
export const deleteAppointment = (config) => api.delete(url.DELETE_APPOINTMENT+'/'+config.id, config.body)
export const markAppointmentAttended = (id) => api.put(url.MARK_APPOINTMENT_ATTENDED+'/'+id+'/attended')

// Opd Appointment
export const getOdpAppointments = (config) => api.get(config ? url.GET_OPD_APPOINTMENTS+ '?page=' + config.page + '&size=' + config.size : url.GET_OPD_APPOINTMENTS)
export const createOdpAppointment = (config) => api.create(url.CREATE_OPD_APPOINTMENT, config)
export const getOdpAppointmentDetail = (id) => api.get(url.GET_OPD_APPOINTMENT+'/'+id)
export const updateOdpAppointment = (config) => api.put(url.UPDATE_OPD_APPOINTMENT+'/'+config.id, config.body)
export const deleteOdpAppointment = (config) =>api.delete(url.DELETE_OPD_APPOINTMENT+'/'+config.id, config.body)
export const markOdpAppointmentAttended = (id) => api.put(url.MARK_OPD_APPOINTMENT_ATTENDED+'/'+id+'/attended')

// Class Account
export const getClassesAccounts = (config) => api.get(
    config ? url.GET_CLASSES_ACCOUNTS+ '?page='+config.page+ '&size='+config.size+ (config.term ?'&term='+config.term : '' )+ (config.parent ?'&filter_by_parent='+config.parent : '' ) : url.GET_CLASSES_ACCOUNTS
)
export const getParentClassesAccount = () => api.get(url.GET_PARENT_CLASSES_ACCOUNT)
export const getTypesClassesAccount = (config) => api.get(url.GET_TYPE_CLASSES_ACCOUNT+'/all'+(config ? '?type='+config : '' ))
export const getAllClassesAccount = (config) => api.get(url.GET_TYPE_CLASSES_ACCOUNT)
export const createClassAccount = (config) => api.create(url.CREATE_CLASS_ACCOUNT, config)
export const getClassAccount = (id) => api.get(url.GET_CLASS_ACCOUNT+'/'+id)
export const updateClassAccount = (config) => api.put(url.UPDATE_CLASS_ACCOUNT+'/'+config.id, config.body)
export const deleteClassAccount = (id) => api.delete(url.DELETE_CLASS_ACCOUNT+'/'+id)
export const searchClassAccount = (config) => api.get(url.SEARCH_CLASS_ACCOUNT+ '?page=' + config.page + '&size=' + config.size + '&term=' +config.term)
export const getSubClassAccount = () => api.get(url.GET_SUB_CLASS_ACCOUNT)
export const getParentSubClassAccount = (id) => api.get(url.GET_PARENT_SUB_CLASS_ACCOUNT+'/'+id+'/sub-accounts')

// Supplier
export const getSuppliers = (config) => api.get(config ? url.GET_SUPPLIERS+ '?page=' + config.page + '&size=' + config.size : url.GET_SUPPLIERS)
export const getSupplier = (id) => api.get(url.GET_SUPPLIER +'/'+ id)
export const createSupplier = (config) => api.create(url.CREATE_SUPPLIER, config)
export const getAllSuppliers = () => api.get(url.GET_ALL_SUPPLIERS)
export const updateSupplier = (config) => api.put(url.UPDATE_SUPPLIER+'/'+config.id, config.body)
export const deleteSupplier = (id) => api.delete(url.DELETE_SUPPLIER+'/'+id)
export const searchSupplier = (config) => api.get(url.SEARCH_SUPPLIER+ '?page=' + config.page + '&size=' + config.size + '&term=' +config.term)

// Products
export const getProducts = (config) => api.get(config ? url.GET_ACTIVES_PRODUCTS+ '?page=' + config.page + '&size=' + config.size + (config.term ? '&term=' +config.term: '') : url.GET_ACTIVES_PRODUCTS)
export const getProductsAndSearch = (config) => api.get(url.GET_PRODUCTS_AND_SEARCH+(config ?'?term='+config : '' ))
// export const getProductsAndSearchStock = (config) => api.get(url.GET_PRODUCTS_AND_SEARCH_STOCK+'?term='+(config ? config : '') )
export const getProductsAndSearchStock = (config) => api.get(
    config ? url.GET_PRODUCTS_AND_SEARCH_STOCK
        +(config.term ? '?term='+config.term: '?term=')
        +(config.targetService ? '&targetService='+config.targetService: '')
    : url.GET_PRODUCTS_AND_SEARCH_STOCK+'?term='
)
export const getProduct = (id) => api.get(url.GET_PRODUCT+'/'+id)
export const createProduct = (config) => api.create(url.CREATE_PRODUCT, config)
export const updateProduct = (config) => api.put(url.UPDATE_PRODUCT+'/'+config.id, config.body)
export const searchProduct = (config) => api.get(url.SEARCH_PRODUCT+ '?page=' + config.page + '&size=' + config.size + '&term=' +config.term)
export const deleteProduct = (id) => api.delete(url.DELETE_PRODUCT+'/'+id)
export const importProducts = (formData) => api.create(url.IMPORT_PRODUCTS, formData)

// Lab Test Remark
export const getLabRemarks = (config) => api.get(
    config ? url.GET_LAB_TEST_REMARKS+ '?page='+config.page+ '&size='+config.size+ (config.term ?'&term='+config.term : '' )+ (config.status ?'&status='+config.status : '' ) + (config.fromDate ?'&fromDate='+config.fromDate+'&toDate='+config.toDate : '') :
    url.GET_LAB_TEST_REMARKS
)
export const getLabTestRemark = (id) => api.get(url.GET_LAB_TEST_REMARK+'/'+id)
export const createLabTestRemark = (config) => api.create(url.CREATE_LAB_TEST_REMARK, config)
export const updateLabTestRemark = (config) => api.put(url.UPDATE_LAB_TEST_REMARK+'/'+config.id, config.data)
export const deleteLabTestRemark = (config) => api.delete(url.DELETE_LAB_TEST_REMARK+'/'+config.id, config.body)

// Lab Samples
export const getLabSamples = (config) => api.get(
    config ? url.GET_LAB_SAMPLES+ '?page='+config.page+ '&size='+config.size+ (config.term ?'&term='+config.term : '' ) :
    url.GET_LAB_SAMPLES
)
export const getAllLabSamples = () => api.get(url.GET_ALL_LAB_SAMPLES)
export const getLabTestSample = (id) => api.get(url.GET_LAB_TEST_SAMPLE+'/'+id)
export const createLabTestSample = (config) => api.create(url.CREATE_LAB_SAMPLE, config)
export const updateLabTestSample = (config) => api.put(url.UPDATE_LAB_SAMPLE+'/'+config.id, config.data)
export const deleteLabTestSample = (config) => api.delete(url.DELETE_LAB_SAMPLE+'/'+config.id, config.body)

// Suppliers Orders
export const getSuppliersOrder = (config) => api.get(config ? url.GET_SUPPLIERS_ORDER+ '?page=' + config.page + '&size=' + config.size : url.GET_SUPPLIERS_ORDER)
export const getSupplierOrder = (id) => api.get(url.GET_SUPPLIER_ORDER+'/'+id)
export const addSupplierOrder = (config) => api.create(url.ADD_SUPPLIER_ORDER, config)
export const updateSupplierOrder = (config) => api.put(url.UPDATE_SUPPLIER_ORDER+'/'+config.id, config.data)
export const confirmSupplierOrder = (config) => api.put(url.UPDATE_SUPPLIER_ORDER+'/'+config.id+'/confirm', config.data)
export const deleteSupplierOrderItem = (id) => api.delete(url.DELETE_SUPPLIER_ORDER_ITEM+'/'+id)

// Warehouse Stock
export const getWarehouseStock = (config) => api.get(
    config ? url.WAREHOUSE_ORDER_STOCK+ '?page='+config.page+ '&size='+config.size + (config.term ?'&term='+config.term : '' ) + (config.category ?'&filterByCategory='+config.category : '' ):
    url.WAREHOUSE_ORDER_STOCK
)

// Product discount
export const getProductsDiscount = (config) => api.get(
    config ? url.GET_PRODUCTS_DISCOUNT+ '?page='+config.page+ '&size='+config.size+ (config.term ?'&term='+config.term : '' ):
    url.GET_PRODUCTS_DISCOUNT
)
export const getProductDiscount = (id) => api.get(url.GET_PRODUCT_DISCOUNT+'/'+id)
export const createProductDiscount = (config) => api.create(url.CREATE_PRODUCT_DISCOUNT, config)
export const updateProductDiscount = (config) => api.put(url.UPDATE_PRODUCT_DISCOUNT+'/'+config.id, config.data)
export const deleteProductDiscount = (config) => api.delete(url.DELETE_PRODUCT_DISCOUNT+'/'+config.id, config.data)

// Transfert Order
export const getTransfertOrders = (config) => api.get(
    config ? url.GET_TRANSFERT_ORDERS+ '?page='+config.page+ '&size='+config.size+ (config.term ?'&term='+config.term : '' ):
    url.GET_TRANSFERT_ORDERS
)
export const getTransfertOrder = (id) => api.get(url.GET_TRANSFERT_ORDER+'/'+id)
export const createTransfertOrder = (config) => api.create(url.CREATE_TRANSFERT_ORDER, config)
export const updateTransfertOrder = (config) => api.put(url.UPDATE_TRANSFERT_ORDER+'/'+config.id, config.data)
export const confirmTransfertOrder = (config) => api.put(url.CONFIRM_TRANSFERT_ORDER+'/'+config.id+'/confirm')
export const deleteTransfertOrderItem = (config) => api.delete(url.DELETE_TRANSFERT_ORDER_ITEM+'/'+config.id+'/items/'+config.itemId)

// Pharmacy Stock
export const getPharmacyStock = (config) => api.get(
    config ? url.PHARMACY_ORDER_STOCK+ '?page='+config.page+ '&size='+config.size + (config.term ?'&term='+config.term : '' ) + (config.category ?'&filterByCategory='+config.category : '' ):
    url.PHARMACY_ORDER_STOCK
)
export const getPharmacyProductsAndSearch = (config) => api.get(url.GET_PHARMACY_PRODUCTS_AND_SEARCH+(config && config.type ?'?patientType='+config.type : '' )+(config && config.term ?'&term='+config.term : '' ))

export const getPharmacyTransferts = (config) =>
    api.get(
        config ? url.GET_PHARMACY_TRANSFERS
            +(config.pageNumber ? '?page='+config.pageNumber: '?page=1')
            +(config.pageSize ? '&size='+config.pageSize: '&size=10')
            +(config.startDate ?'&startDate='+config.startDate : '' )
            +(config.endDate ?'&endDate='+config.endDate : '' )
            +(config.serviceUnit ?'&serviceUnit='+config.serviceUnit : '' )
        : url.GET_PHARMACY_TRANSFERS
    )
export const getPharmacyTransfert = (id) => api.get(url.GET_PHARMACY_TRANSFER+'/'+id)
export const savePharmacyTransfert = (config) => api.create(url.SAVE_PHARMACY_TRANSFER, config)
export const updatePharmacyTransfert = (config) => api.put(url.UPDATE_PHARMACY_TRANSFER+'/'+config.id, config.data)

// Sales
export const getSales = (config) => api.get(
    config ? url.GET_SALES+(config.pageNumber ? '?page='+config.pageNumber: '?page=1')+ (config.pageSize ? '&size='+config.pageSize: '&size=10')+ (config.term ?'&term='+config.term : '' ) 
    : url.GET_SALES
)
export const getSalesPostedToPatient = (config) => api.get(
    config ? url.GET_SALES_POSTED_TO_PATIENTS+(config.pageNumber ? '?page='+config.pageNumber: '?page=1')+ (config.pageSize ? '&size='+config.pageSize: '&size=10')+ (config.term ?'&term='+config.term : '' ) 
    : url.GET_SALES_POSTED_TO_PATIENTS
)

    export const createSale = (config) => api.create(url.CREATE_SALE, config)
export const getSale = (id) => api.get(url.GET_SALE+'/'+id)

// Associated service
export const getAssociatedService = () => api.get(url.GET_ASSOCIATED_SERVICES)
export const getInvoice = (config) => api.get(
    config ? url.GET_SALES_INVOICES+(config.pageNumber ? '?page='+config.pageNumber: '?page=1')+ (config.pageSize ? '&size='+config.pageSize: '&size=10')+ (config.term ?'&term='+config.term : '' )
    + (config.methods ?'&filterPaymentModes='+config.methods : '' )+ (config.users ?'&filterByUser='+config.users : '' )+ (config.startDate ?'&filterByStartDate='+config.startDate : '' )
    + (config.endDate ?'&filterByEndDate='+config.endDate : '' )+ (config.target ?'&targetServices='+config.target : '' ):
    url.GET_SALES_INVOICES
)
export const getPaymentMode = () => api.get(url.GET_PAYMENT_MODE)

// Lab record
export const getLabRecords = (config) => api.get(
    config ? url.GET_LAB_RECORDS+ '?page='+config.page+ '&size='+config.size+ (config.term ?'&term='+config.term : '' ):
    url.GET_LAB_RECORDS
)
export const getLabRecord = (id) => api.get(url.GET_LAB_RECORD+'/'+id)
export const getAllLabRemarks = () => api.get(url.GET_ALL_LAB_REMARKS)
export const createLabTests = (config) => api.put(url.CREATE_LAB_TESTS+'/'+config.id, config.body)

export const getPatientForSale = (term) => api.get(url.SEARCH_PATIENT_FOR_SALE+(term ?'&term='+term : '' ))

// Invoice
export const getInvoices = (config) => api.get(
    config ? url.GET_INVOICES+(config.pageNumber ? '?page='+config.pageNumber: '?page=1')+ (config.pageSize ? '&size='+config.pageSize: '&size=10')+ (config.term ?'&term='+config.term : '' )
    + (config.status ?'&filterByStatuses='+config.status : '' )+ (config.users ?'&filterByUser='+config.users : '' )+ (config.startDate ?'&filterByStartDate='+config.startDate : '' )
    + (config.endDate ?'&filterByEndDate='+config.endDate : '' )+ (config.target ?'&targetServices='+config.target : '' ):
    url.GET_INVOICES
)
export const getDailyBill = (config) => api.get(
    config ? url.GET_DAILY_BILLS+ '?page='+config.page+ '&size='+config.size+ (config.term ?'&term='+config.term : '' ):
    url.GET_DAILY_BILLS
)

export const getInvoiceStatus = (config) => api.get(url.GET_INVOICE_STATUS)

export const getMyReport = (config) => api.get(
    config ? url.GET_MY_REPORT+ '?page='+config.page+ '&size='+config.size+ (config.term ?'&term='+config.term : '' ):
    url.GET_MY_REPORT
)

export const getAnInvoice = (id) => api.get(url.GET_INVOICE+'/'+id)
export const getPatientInvoice = (id) => api.get(url.GET_PATIENT_INVOICES+'/'+id)
// Financial year
export const getFinancialYears = (config) => api.get(
    config ? url.GET_FINANCIAL_YEARS+ '?page='+config.page+ '&size='+config.size+ (config.term ?'&term='+config.term : '' ):
    url.GET_FINANCIAL_YEARS
)
export const getFinancialYearsList = () => api.get(url.GET_FINANCIAL_YEAR+'/list')
export const getFinancialYear = (id) => api.get(url.GET_FINANCIAL_YEAR+'/'+id)
export const createFinancialYear = (config) => api.create(url.CREATE_FINANCIAL_YEAR, config)
export const updateFinancialYear = (config) => api.put(url.GET_FINANCIAL_YEAR+'/'+config.id, config.data)
export const closeFinancialYear = (id) => api.put(url.CLOSE_FINANCIAL_YEAR+'/'+id+'/close')
export const openFinancialYear = (id) => api.put(url.OPEN_FINANCIAL_YEAR+'/'+id+'/open')

// Patient Account
export const getPatientAccounts = (config) => api.get(
    config ? url.GET_PATIENTS_ACCOUNTS+ '?page='+config.page+ '&size='+config.size+ (config.term ?'&term='+config.term : '' ):
    url.GET_PATIENTS_ACCOUNTS
)
export const getPatientAccount = (id) => api.get(url.GET_PATIENT_ACCOUNT+'/'+id+'/load')
export const createPatientAccount = (config) => api.create(url.CREATE_PATIENT_ACCOUNT, config)
export const makeDeposit = (config) => api.create(url.MAKE_DEPOSIT+'/'+config.id+'/make-deposit', config.body)
export const getPatientAccountHistory = (config) => api.get(
    url.GET_PATIENT_ACCOUNT_HISTORY+'/'+config.id +'/transactions'+(config.period ? '?period='+config.period: '?period=last_12_months')+(config.pageNumber ? '&page='+config.pageNumber: '&page=1')+ (config.pageSize ? '&size='+config.pageSize: '&size=10'))

// Payment
export const getPayments = (config) => api.get(
    config ? url.GET_PAYMENTS+(config.pageNumber ? '?page='+config.pageNumber: '?page=1')+ (config.pageSize ? '&size='+config.pageSize: '&size=10')+ (config.term ?'&term='+config.term : '' )
    + (config.methods ?'&filterPaymentModes='+config.methods : '' )+ (config.users ?'&filterByUser='+config.users : '' )+ (config.startDate ?'&filterByStartDate='+config.startDate : '' )
    + (config.endDate ?'&filterByEndDate='+config.endDate : '' )+ (config.confirm ?'&confirmedBy='+config.confirm : '' ) :
    url.GET_PAYMENTS
)
export const getPayment = (id) => api.get(url.GET_PAYMENT+'/'+id)
export const makePayment = (config) => api.create(url.MAKE_PAYMENT, config)
export const confirmPayment = (id) => api.put(url.CONFIRM_PAYMENT+'/'+id+'/confirm')

// Deposits
export const getDeposits = () => api.get(url.GET_DEPOSITS)

// CashDesk
export const createCashDesk = (config) => api.create(url.CREATE_CASH_DESK, config)
export const openCashDesk = (config) => api.create(url.OPEN_CASH_DESK+"/"+config.id+"/open", config.body)
export const closeCashDesk = (config) => api.create(url.CLOSE_CASH_DESK+"/"+config.id+"/close", config.body)
export const getCloseCashDesks = (config) => api.get(url.GET_CLOSE_CASH_DESKS, config)
export const getCashDesks = (config) => api.get(
    config ? url.GET_CASH_DESKS+ '?page='+config.page+ '&size='+config.size+ (config.term ?'&term='+config.term : '' ):
    url.GET_CASH_DESKS
)
export const checkOpenCashdesk = () => api.get(url.CHECK_OPEN_CASHDESK)
export const getAmountsCollectedGroupBySources = (id) => api.get(url.GET_CASH_DESKS+"/"+id+"/amount-collected")
export const updateCashDesk = (config) => api.put(url.UPDATE_CASHDESK+"/"+config.id, config.body)
export const getCashDeskJournal = () => api.get(url.GET_CASH_DESK_JOURNAL)

// Cash journal
export const getCashJournal = (config) => api.get(
    config ? url.GET_CASH_JOURNAL+"?group="+config.group+ '&filterBy='+config.filterBy+ '&filterValue='+config.filterValue :
    url.GET_CASH_JOURNAL+"?group="+config.group
)

// Bill discharge
export const getBillDischarge = (config) => api.get(
    config ? url.GET_BILL_DISCHARG+'?page='+config.page+ '&size='+config.size :
    url.GET_BILL_DISCHARG
)

// Bank account
export const createBankAccount = (config) => api.create(url.CREATE_BANK_ACCOUNT, config)
export const  updateBankAccount = (config) => api.put(url.UPDATE_BANK_ACCOUNT+"/"+config.id, config.body)
export const deleteBankAccount = (config) => api.delete(url.DELETE_BANK_ACCOUNT+"/"+config.id, config.data)
export const getBankAccounts = (config) => api.get(
    config ? url.GET_BANK_ACCOUNTS+ '?page='+config.page+ '&size='+config.size+ (config.term ?'&term='+config.term : '' ):
    url.GET_BANK_ACCOUNTS
)
export const getAllBankAccounts = (use) => api.get(url.GET_ALL_BANK_ACCOUNT+(use ? '?useInSales=true' : '?useInSales=false'))

export const getBankAccount = (id) => api.get(url.GET_BANK_ACCOUNTS+'/'+id)

// export const getBankingAccountsTransaction = 
//     (config) => api.get(
//         url.GET_BANK_ACCOUNTS_TRANSACTION+"/"+config.id +(config.page && '?page='+config.page)+(config.size && '&term='+config.size)
//         +(config.startDate && '&startDate='+config.startDate)+(config.endDate && '&term='+config.endDate)
//     )

export const getBankingAccountsTransaction = 
    (config) => api.get(
        url.GET_BANK_ACCOUNTS_TRANSACTION+"/"+config.id +'/transactions'+(config.page ?'?page='+config.page : '?page=1' )+(config.size ?'&size='+config.size : '' )
        +(config.startDate ?'&startDate='+config.startDate : '' )+(config.endDate ?'&endDate='+config.endDate : '' )
    )
// Requisition
export const getRequisitions = (config) => api.get(
    config ? url.GET_REQUISITIONS+ (config.term ?'?term='+config.term : '?term=' )+(config.status ?'&filterByStatus='+config.status : '' )+
     (config.page ? '&page='+config.page : '&page=1')+ (config.size ? '&size='+config.size : '&size=10'):
    url.GET_REQUISITIONS
)
export const createRequisition = (config) => api.create(url.CREATE_REQUISITION, config)
export const getRequisition = (id) => api.get(url.GET_REQUISITION+"/"+id)
export const approveRequisition = (id) => api.put(url.APPROVE_REQUISITION+"/"+id+"/approve")
export const rejectRequisition = (id) => api.put(url.REJECT_REQUISITION+"/"+id+"/reject")
export const payRequisition = (config) =>api.put(url.PAY_REQUISITION+"/"+config.id+"/pay?accountId="+config.bank_account_id)
export const cancelRequisition = (id) => api.delete(url.CANCEL_REQUISITION+"/"+id)
export const updateRequisition = (config) => api.put(url.UPDATE_REQUISITION+"/"+config.id, config.body)
export const getRequisitionStatus = () => api.get(url.GET_REQUISITION_STATUS)
// CashDrop
export const getCashDrops = (config) => api.get(
    config ? url.GET_CASHDROPS+(config.page ? '?page='+config.page : '?page=1')+ (config.size ? '&size='+config.size : '&size=10'):
    url.GET_CASHDROPS
)

export const createCashDrop = (config) => api.create(url.CREATE_CASHDROP, config)
// Revenues
export const getOtherRevenues = (config) => api.get(
    config ? url.GET_OTHER_REVENUES+(config.page ? '?page='+config.page : '?page=1')+ (config.size ? '&size='+config.size : '&size=10'):
    url.GET_OTHER_REVENUES
)
export const createOtherRevenue = (config) => api.create(url.CREATE_OTHER_REVENUE, config)

// Budgeting
export const getBudgeting = (config) => api.get(
    config ? url.GET_BUDGETING+(config.page ? '?page='+config.page : '?page=1')+ (config.size ? '&size='+config.size : '&size=10'):
    url.GET_BUDGETING
)
export const createBudgeting = (config) => api.create(url.CREATE_BUDGETING, config)

// Print Settings
export const getPrintSetting = () => api.get(url.GET_PRINT_SETTINGS)
export const savePrintSetting = (config) => api.create(url.SAVE_PRINT_SETTING, config)

// patients checkout

export const getPatientsCheckout = (config) => api.get( 
    config ? url.GET_PATIENTS_CHECKOUT+(config.page ? '?page='+config.page : '?page=1')+ (config.size ? '&size='+config.size : '&size=10')+(config.term ? '&term='+config.term : ''):
    url.GET_PATIENTS_CHECKOUT)

export const getGuarantors = async (params, callback) => {
    const response = await api.get(url.GET_GUARANTORS + buildQueryParams(params));
    callback(response?.data);
}

export const getAllGuarantors = async (callback, setSuccessCallback = null) => {
    try {
        const response = await api.get(url.GET_GUARANTORS + '/all');
        callback(response?.data);
        callSuccessCallback(setSuccessCallback, true);
    } catch(error) {
        callback(error?.message)
        callSuccessCallback(setSuccessCallback, false);
    }
}


export const addGuarantor = async (params, callback, setSuccessCallback) => {
    try {
        const response = await api.create(url.GET_GUARANTORS, params);
        callback(response?.message);
        setSuccessCallback(true);
    } catch(error) {
        callback(error?.message)
        setSuccessCallback(false);
    }
}

export const updateGuarantor = async (id, params, callback, setSuccessCallback) => {
    try {
        const response = await api.put(url.GET_GUARANTORS + '/' + id, params);
        callback(response?.message);
        setSuccessCallback(true);
    } catch(error) {
        callback(error?.message)
        setSuccessCallback(false);
    }
}

const buildQueryParams = (params) => {
    return '?page='
    + (params.page ? params.page : 1)
    + '&'
    + 'size=' + + (params.size ? params.size : 10)
}

const callSuccessCallback = (paramsCallback, value) => {
    if (paramsCallback) {
        paramsCallback(value);
    }
}


export const getAccountSummary = (config) => api.get(
    config ? url.GET_ACCOUNT_SUMMARY+(config.page ? '?page='+config.page : '?page=1')+ (config.size ? '&size='+config.size : '&size=10'):
    url.GET_ACCOUNT_SUMMARY
)

export const getAccountSummaryDetails = (id) => api.get(url.GET_ACCOUNT_SUMMARY_DETAILS+"/"+id+"/discharges")

export const getPatientAccountSummaryDetails = (id) => api.get(url.GET_PATIENT_ACCOUNT_SUMMARY+"/"+id+"/summary")

export const getPharmacyInventories = (config) => 
    api.get(
        config ? url.GET_PHARMACY_INVENTORIES
            +(config.pageNumber ? '?page='+config.pageNumber: '?page=1')
            +(config.pageSize ? '&size='+config.pageSize: '&size=10')
            +(config.startDate ?'&startDate='+config.startDate : '' )
            +(config.endDate ?'&endDate='+config.endDate : '' )
        : url.GET_PHARMACY_INVENTORIES
    )
export const getPharmacyInventory = (id) => api.get(url.GET_PHARMACY_INVENTORY+'/'+id)
export const savePharmacyInventory = (config) => api.create(url.SAVE_PHARMACY_INVENTORY, config)
export const updatePharmacyInventory = (config) => api.put(url.UPDATE_PHARMACY_INVENTORY+'/'+config.id, config.data)
export const getPharmacyStockProduct = qty => api.get(url.GET_PHARMACY_STOCK_PRODUCTS+(qty ? '?havingQty='+qty : ''))


export const getWarehouseInventories = (config) => 
    api.get(
        config ? url.GET_WAREHOUSE_INVENTORIES
            +(config.pageNumber ? '?page='+config.pageNumber: '?page=1')
            +(config.pageSize ? '&size='+config.pageSize: '&size=10')
            +(config.startDate ?'&startDate='+config.startDate : '' )
            +(config.endDate ?'&endDate='+config.endDate : '' )
        : url.GET_PHARMACY_INVENTORIES
    )
export const getWarehouseInventory = (id) => api.get(url.GET_WAREHOUSE_INVENTORY+'/'+id)
export const saveWarehouseInventory = (config) => api.create(url.SAVE_WAREHOUSE_INVENTORY, config)
export const updateWarehouseInventory = (config) => api.put(url.UPDATE_WAREHOUSE_INVENTORY+'/'+config.id, config.data)

export const getAllStockGoodsProducts = (config) => api.get(
    config ? url.GET_ALL_GOODS_PRODUCTS
        +(config.term ? '?term=' + config.term : '')
    : url.GET_ALL_GOODS_PRODUCTS
)

// Bed Service
export const getBedServices = () => api.get( url.GET_BED_SERVICES)

export const getIncomesReport = (config) => api.create(url.GET_INCOMES_STREAMS_REPORT, config)

// Payroll
export const getPayrolls = (config) => 
    api.get(url.CRUD_PAYROLS_SETTING+ '?settingType='+config.settingType
        +(config.pageNumber ? '&page='+config.pageNumber: '&page=1')
        +(config.pageSize ? '&size='+config.pageSize: '&size=10')
        +(config.term ?'&term='+config.term : '' )
)
export const getAllPayrollSettings = (config) => api.get(url.CRUD_PAYROLS_SETTING+ '/all?settingType='+config.settingType);
export const createPayrollSetting = (config) => api.create(url.CRUD_PAYROLS_SETTING, config);
export const updatePayrollSetting = (config) => api.put(url.CRUD_PAYROLS_SETTING+'/'+config.id, config.data);
export const deletePayrollSetting = id => api.delete(url.CRUD_PAYROLS_SETTING+'/'+id)
