import * as Yup from "yup"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../../components/Common/withRouter'
import BreadCrumb from '../../../../components/Common/BreadCrumb'
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import DeleteModal from "../../../../components/Global/DeleteModal"
import TableContainer from "../../../../components/Common/TableContainer"
import SinpleActionButton from '../../../../components/Global/SinpleActionButton'
import {
  getPayrolls as onGetPayrolls,
  deletePayrollSetting as onDeletePayrollSetting,
} from "../../../../slice/thunks"
import GlobalLoader from "../../../../components/Common/GlobalLoader"

const Departments = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const selectLayoutState = (state) => state.Settings;
  const SettingsProperties = createSelector(
    selectLayoutState,
    (setting) =>({
      payrollSettings: setting.payrollSettings,
      payrollSetting: setting.payrollSetting,
      createPayrollSetting: setting.createPayrollSetting,
      updatePayrollSetting: setting.updatePayrollSetting,
      deletePayrollSetting: setting.deletePayrollSetting,
      error: setting.error
    })
  )
  const { payrollSettings, payrollSetting, deletePayrollSetting, error } = useSelector(SettingsProperties)
  const [deleteModal, setDeleteModal] = useState(false)
  const [currentSetting, setCurrentSetting] = useState(null)
  const permission = JSON.parse(localStorage.getItem("perm"))

  const loadSettings = useCallback((pageNumber, pageSize) => {
    
    if (pageNumber && pageSize) {
      dispatch(onGetPayrolls({settingType: 'Department', page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetPayrolls({ settingType: 'Department' }))
    }
  })
  
  const searchSetting = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetPayrolls({settingType: 'Department', term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetPayrolls({settingType: 'Department', term: term, page: 1, size: 10}))
    }
  },[])

  useEffect(() =>{
    loadSettings()
  },[dispatch])

  useEffect(() => {
    if (payrollSettings && payrollSettings.page) {
      setIsLoading(false)
    }
  },[payrollSettings])

  useEffect(() => {
    if (deletePayrollSetting && !error) {
      setCurrentSetting(null)
      loadSettings()
    }
  },[deletePayrollSetting, error, payrollSetting])

  const onClickDelete = (row) => {
    setCurrentSetting(row);
    setDeleteModal(true);
  }

  function handleDeleteBeds() {
    if (currentSetting) {
      dispatch(onDeletePayrollSetting(currentSetting.id))
      loadSettings()
      setDeleteModal(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        header: props.t("no"),
        style: "button",
        enableColumnFilter: false,
        cell: (cellProps) => {
          return <span>
              {payrollSettings && payrollSettings.page === 1 ? 
                (cellProps.row.index + 1) 
                : (((payrollSettings && payrollSettings.page-1)*10)+cellProps.row.index + 1) }
          </span>;
        },
      },
      {
        header: props.t("label"),
        accessorKey: "label",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: props.t("action"),
        style: "text-end",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                <ul className="list-inline hstack mb-0">
                  <li className="list-inline-item edit" title="Edit">
                    <Link
                      to={`/payroll/setting/departments/create/${cellProps.row.original.id}/${cellProps.row.original.label}`}
                      className="text-primary d-inline-block edit-item-btn"
                    >
                      <i className="fa fa-pencil" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item" title="Remove">
                    <Link
                      to="#"
                      className="text-danger d-inline-block remove-item-btn"
                      onClick={() => { const rowData = cellProps.row.original; onClickDelete(rowData); }}
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </Link>
                  </li>
                </ul>
            </div>
          );
        },
      },
    ]
  );


  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <DeleteModal show={deleteModal} onDeleteClick={handleDeleteBeds} onCloseClick={() =>setDeleteModal(false)} />
          <div className="all-payrollSettings-setting px-3">
              <div className="patients-list py-4 px-2  ">
                  <div className="container-fluid">
                      <div className="row list-patients d-flex  justify-content-between">
                          <Link to="/payroll/setting" className="col-lg-5">
                            <BreadCrumb title={props.t("payroll_settings")} pageTitle={props.t("departments")} />
                          </Link>
                          <div className="col-sm-auto new-mode-btn">
                            <Link to="/payroll/setting/departments/create" className="btn btn-std mx-2" >
                              <i className="fas fa-user-md mx-1"></i>
                              {props.t("create_new")}
                            </Link>
                          </div>
                      </div>

                      <div className="payment-mode-lists">
                          <div className="container-fluid">
                              <div className="row justify-content-between">
                                <div className="card table-card">
                                  <TableContainer
                                    columns={columns}
                                    data={((payrollSettings && payrollSettings.items) || [])}
                                    isGlobalFilter={true}
                                    customPageSize={10}
                                    divClass="table-card-ward"
                                    tableClass="align-middle"
                                    theadClass="thead-light table-light"
                                    tableSize={ payrollSettings && payrollSettings.size }
                                    tablePage={ payrollSettings && payrollSettings.page }
                                    tableTotal= { payrollSettings && payrollSettings.total_items }
                                    tableTotalPage= { payrollSettings && payrollSettings.total_page }
                                    onNextPage= {loadSettings}
                                    onSearch= {searchSetting}
                                    SearchPlaceholder={props.t("search")}
                                  />
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Departments))