import React, { Fragment, useEffect, useState } from "react"
import { CardBody, Col, Row, Table } from "reactstrap"
import { t } from 'i18next'

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender
} from '@tanstack/react-table'

import { rankItem } from '@tanstack/match-sorter-utils';
import { withTranslation } from "react-i18next"
import withRouter from "./withRouter"


  // Column Filter
const Filter = ({
    column,
    // table
  }) => {
    const columnFilterValue = column.getFilterValue();
  
    return (
      <>
        <DebouncedInput
          type="text"
          value={(columnFilterValue ?? '')}
          onChange={(event) => column.setFilterValue(event.target.value)}
          placeholder="Search..."
          className="w-36 border shadow rounded"
          list={column.id + 'list'}
        />
        <div className="h-1" />
      </>
    );
};

// Global Filter
const DebouncedInput = ({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
  }) => {
    const [value, setValue] = useState(initialValue);
  
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value);
      }, debounce);
  
      return () => clearTimeout(timeout);
    }, [debounce, onChange, value]);
  
    return (
      <input
        {...props}
        value={value}
        id="search-bar-0"
        className="form-control search"
        onChange={(e) => setValue(e.target.value)}
      />
    );
};
const TableContainerBase = ({
columns,
data,
isGlobalFilter,
customPageSize,
tableClass,
theadClass,
trClass,
thClass,
divClass,
SearchPlaceholder,
total,
totalCol,
t = (key) => key,
}) => {
const [columnFilters, setColumnFilters] = useState ([]);
const [globalFilter, setGlobalFilter] = useState('');

const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
    itemRank
    });
    return itemRank.passed;
};

const table = useReactTable({
    columns,
    data,
    filterFns: {
    fuzzy: fuzzyFilter,
    },
    state: {
    columnFilters,
    globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel()
});

const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState
} = table;

useEffect(() => {
    (customPageSize) && setPageSize((customPageSize));
}, [customPageSize, setPageSize]);

return (
    <Fragment>
    {isGlobalFilter && <Row className="mb-3">
        <CardBody className="border border-dashed border-end-0 border-start-0">
        <form>
            <Row>
            <Col sm={5}>
                <div className="search-box me-2 mb-2 d-inline-block col-12">
                <DebouncedInput
                    value={globalFilter ?? ''}
                    onChange={value => setGlobalFilter((value))}
                    placeholder={SearchPlaceholder}
                />
                <i className="bx bx-search-alt search-icon"></i>
                </div>
            </Col>
            </Row>
        </form>
        </CardBody>
    </Row>}

    {data && data.length > 0 ?
        <div className={divClass}>
            <Table className={tableClass}>
            <thead className={theadClass}>
                {getHeaderGroups().map((headerGroup) => (
                <tr className={trClass} key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                    <th key={header.id} className={thClass}  {...{
                        onClick: header.column.getToggleSortingHandler(),
                    }}>
                        {header.isPlaceholder ? null : (
                        <React.Fragment>
                            {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                            )}
                            {{
                            asc: ' ',
                            desc: ' ',
                            }
                            [header.column.getIsSorted()] ?? null}
                            {header.column.getCanFilter() ? (
                            <div>
                                <Filter column={header.column} table={table} />
                            </div>
                            ) : null}
                        </React.Fragment>
                        )}
                    </th>
                    ))}
                </tr>
                ))}
            </thead>

            <tbody>
                {getRowModel().rows.map((row) => {
                return (
                    <tr className={trClass} key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                        return (
                        <td key={cell.id}>
                            {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                            )}
                        </td>
                        );
                    })}
                    </tr>
                );
                })}
                {total && <tr className={trClass}>
                    <td colSpan={totalCol}><span style={{ fontSize: "24px", fontWeight: "700" }}>{t("total")}</span></td>
                    <td><span style={{ fontWeight: "700" }}>{total}</span></td>
                </tr>
                }

            </tbody>
            </Table>
        </div>
        :
        <>
            <div className='d-flex justify-content-center p-3'>
                <div className='d-flex align-items-center flex-column p-3'>
                    <i className="fa-solid fa-triangle-exclamation global_empty"></i>
                    <h4> {t("no_result_found")}</h4>
                </div>
            </div>
        </>
    
    }
    </Fragment>
);
};

export default withRouter(withTranslation()(TableContainerBase))
