import React, { useCallback, useEffect, useMemo, useState } from 'react'
import withRouter from '../../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import {
  getPatientAccounts as onGetPatientAccounts,
  getAllBankAccounts as onGetAllBankAccounts,
  createPatientAccount as onCreatePatientAccount,
  getPatientForSale as onGetPatients,
  searchPatient as onSearchPatient,
  makeDeposit as onMakeDeposit,
} from '../../../../slice/thunks'
import { useSelector, useDispatch } from "react-redux"
import * as Yup from "yup"
import { useFormik } from 'formik'
import makeAnimated from "react-select/animated"
import { createSelector } from "reselect"
import Select from "react-select"
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import GlobalLoader from '../../../../components/Common/GlobalLoader'
import TableContainer from '../../../../components/Common/TableContainer'

const PatientAccount = (props) => {
  const dispatch = useDispatch()
  const animatedComponents = makeAnimated()
  const selectLayoutState = (state) => state.Settings;
  const selectLayoutStatePatient = (state) => state.Outputs;
  const SettingsProperties = createSelector(
      selectLayoutState,
      (setting) =>({
        patientAccounts: setting.patientAccounts,
        createPatientAccountSuccess: setting.createPatientAccountSuccess,
        makeDepositSuccess: setting.makeDepositSuccess,
        allBankingAccounts: setting.allBankingAccounts,
        error: setting.error,
      })
  )
  const OutputsProperties = createSelector(
    selectLayoutStatePatient,
    (outputs) =>({
        patients: outputs.patients,
        patient: outputs.patient,
    })
  )
  const { patientAccounts, allBankingAccounts, createPatientAccountSuccess, makeDepositSuccess, error } = useSelector(SettingsProperties)
  const { patients } = useSelector(OutputsProperties)
  const [isLoading, setIsLoading] = useState(true)
  const [currentAccount, setCurrentAccount] = useState(null)
  const [isDeposit, setIsDeposit] = useState(false)
  const [ bankOption, setBankOption ] = useState([])
  const [ patientOption, setPatientOption ]= useState([])
  const [searchInput, setSearchInput] = useState("");
  const [ selectedPatient, setSelectedPatient ] = useState(null)
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [ selectedBank, setSelectedBank ] = useState(null)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    if (props.isActive) {
      loadPatients()
      loadBakingAccount()
      loadPatientsAccounts()
    }
  },[props.isActive])

  useEffect(() => {
    if (patientAccounts && patientAccounts.page > 0) {
      setIsLoading(false)
    }
  },[patientAccounts])

  useEffect(() => {
    if (patients && patients.items && patients.items.length > 0) {
        const patient_list = patients.items.filter(target => target.patientRecord).map( target => {
          return {
                label: target.firstName+' '+target.lastName,
                code: target.patientRef,
                phone: target.phone,
                value: target.id,
                type: target.patientType.typeRef
            }
        })
        setPatientOption(patient_list)
    } else {
      setPatientOption([])
    }
  },[patients])

  useEffect(() => {
    if (searchInput === "") {
      loadPatients()
    }
  },[searchInput])

  useEffect(() => {
    if (allBankingAccounts && allBankingAccounts.length > 0) {
        const bank_lists = allBankingAccounts.map(bank => ({
            label: bank.bankName,
            value: bank.id
        }));
        setBankOption(bank_lists);
    }
  }, [allBankingAccounts]);

  useEffect(() => {
    if(createPatientAccountSuccess && !error) {
      loadPatientsAccounts()
      toggle()
    }
  },[createPatientAccountSuccess])

  useEffect(() => {
    if(makeDepositSuccess && !error) {
      loadPatientsAccounts()
      toggle()
    }
  },[makeDepositSuccess])

  const loadPatientsAccounts = useCallback((pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetPatientAccounts({page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetPatientAccounts())
    }
  })

  const loadBakingAccount = useCallback(() => {
    dispatch(onGetAllBankAccounts(true))
  })

  const loadPatients = useCallback((term) => {
    if (term) {
        dispatch(onSearchPatient({term: term, page: 1, size: 10}))
    } else {
        dispatch(onSearchPatient({term: '', page: 1, size: 10}))
    }
  })

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false)
      validation.resetForm()
      setCurrentAccount(null)
      setSelectedPatient(null)
      setSelectedBank(null)
      setIsDeposit(false)
    } else {
      setModal(true);
    }
  }, [modal])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: (currentAccount && currentAccount.id) || null,
      bankAccountId: (currentAccount && currentAccount.bankAccountId) || '',
      amount: (currentAccount && currentAccount.amount) || '',
      reason: (currentAccount && currentAccount.reason) || '',
    },
    validationSchema: Yup.object({
      amount: Yup.string().matches(/^[0-9.]+$/, { message: props.t("provide_valid_amount") }).required(props.t("provide_amount")),
    }),
    onSubmit: (values) => {

      if (isDeposit) {
        const newForm = {
          bankAccountId: selectedBank && selectedBank.value,
          amount: values.amount,
          reason: values.reason
        }
        dispatch(onMakeDeposit({id: (currentAccount && currentAccount.id), body: newForm}))
      } else {
        const newForm = {
          patientId: (selectedPatient && selectedPatient.value) || null,
          transaction: {
            bankAccountId: selectedBank && selectedBank.value,
            amount: values.amount,
            reason: values.reason
          }
        }
        dispatch(onCreatePatientAccount(newForm))
      }
    },
  })

  const handleOpen = () => {
    toggle()
    setIsDeposit(false)
  }

  const handleOpenDeposit = (row) => {
    setIsDeposit(true)
    setCurrentAccount(row)
    toggle()
  }

  const searchAccount = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetPatientAccounts({term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetPatientAccounts({term: term, page: 1, size: 10}))
    }
  },[props.isActive])

  const handleSelectPatient =(selectedP) => {
    if (selectedP) {
        setSelectedPatient(selectedP)
        loadPatients(selectedP)
    } else {
        // setSelectedPatient(null)
        loadPatients()
    }
    // setMenuIsOpen(false)
  }

  function capitalizeName(name) {
    if (!name) return '';
    return name
        .split(' ') 
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
        .join(' '); 
  }

  const columns = useMemo(
    () => [
      {
        header: props.t("no"),
        style: "cat",
        enableColumnFilter: false,
        cell: (cellProps) => {
          return <span>
              {patientAccounts.page == 1 ? (cellProps.row.index + 1) : (((patientAccounts.page-1)*10)+cellProps.row.index + 1) }
          </span>;
        },
      },
      {
        header: props.t("ref"),
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.accountRef}</span>
        }
      },
      {
        header: props.t("patient_name"),
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{capitalizeName(cell.row.original.patientName)}</span>
        }
      },
      {
        header: props.t("balance"),
        style: "sale",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.balance}</span>
        }
      },
      {
        header: props.t("action"),
        style: "action",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-start">
                <ul className="list-inline hstack mb-0">
                    <li className="list-inline-item edit" title={props.t("transaction_history")}>
                      <Link
                        to={`/cashier/all-sales/patientAccount/${cellProps.row.original.id}/${cellProps.row.original.accountRef}/${cellProps.row.original.balance}`}
                        className="text-dark d-inline-block edit-item-btn" style={{fontSize: "15px" }}
                        onClick={() => handleOpenDeposit(cellProps.row.original)}
                      >
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item edit" title={props.t("make_deposit")}>
                      <Link
                        to="#"
                        className="text-dark d-inline-block edit-item-btn" style={{fontSize: "15px" }}
                        onClick={() => handleOpenDeposit(cellProps.row.original)}
                      >
                        <i className="fa fa-credit-card" aria-hidden="true"></i>
                      </Link>
                    </li>
                </ul>
            </div>
          );
        },
      },
    ],
    [patientAccounts, handleOpenDeposit]
  );

  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
      :
        <>
          <div className="patients-list-content">
            <div className="patients-list px-2 pb-5 ">
              <div className="container-fluid ">
                <div className="heading-parent-sec ">
                    <div className="heading-child-one-sec pt-4">
                    </div>
                    <div className="heading-child-two-sec pt-4">
                        <div className="create-new-patient-btn my-2 ps-2"
                            data-bs-target="#exampleModalSec" onClick={() =>{handleOpen()}}>
                            <div className="create-new-patient-btn-child-one">
                                <img src="../assets/images/new-patient/img-one.png" className="img-fluid" />
                            </div>
                            <div className="create-new-patient-btn-child-two">
                                <button className="btn" >{props.t("create_new_account")}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card border-0 row p-2">
                  <div className="row">
                    <TableContainer
                      columns={columns}
                      data={((patientAccounts && patientAccounts.items) || [])}
                      isGlobalFilter={true}
                      customPageSize={10}
                      divClass="card table-card table-warehouse table-responsive"
                      tableClass="table"
                      theadClass="thead-sec table-light"
                      tdClass="td"
                      tableSize={ patientAccounts && patientAccounts.size }
                      tablePage={ patientAccounts && patientAccounts.page }
                      tableTotal= { patientAccounts && patientAccounts.total_items }
                      tableTotalPage= { patientAccounts && patientAccounts.total_page }
                      onNextPage= {loadPatientsAccounts}
                      onSearch= {searchAccount}
                      SearchPlaceholder={props.t("search_patient_account")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal isOpen={modal || false} toggle={toggle} className="modal-sec"  tabIndex="-1" aria-labelledby="exampleModalLabel" style={{ backdropFilter: "blur(5px)" }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title mb-0">{isDeposit ? props.t("make_deposit") : props.t("create_new_account")}</h5>
                    <button type="button" className="btn-close" onClick={() => { toggle()}} ></button>
                </div>
                <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                }}>
                  <ModalBody className="modal-body">
                    <Row>
                      {!isDeposit ?
                        <Col xl={12} sm={12}>
                          <div className='mb-2'>
                              <Label htmlFor="patient-field" className="form-label">{props.t("patient")}<span className='text-danger'>*</span></Label><br/>
                              
                              <Select
                                name="patient"
                                id="patient-field"
                                value={selectedPatient}
                                isMulti={false}
                                isClearable={true}
                                onChange={(e) => {
                                  setSelectedPatient(e);
                                }}
                                onInputChange={(inputValue, { action }) => {
                                  if (action === "input-change") {
                                    setSearchInput(inputValue);
                                    if (inputValue.length > 2) {
                                      loadPatients(inputValue);
                                    }
                                  }
                                }}
                                inputValue={searchInput} 
                                options={patientOption}
                                closeMenuOnSelect={true}
                                menuIsOpen={menuIsOpen}
                                onMenuOpen={() => setMenuIsOpen(true)}
                                onMenuClose={() => setMenuIsOpen(false)}
                                onBlur={() => setMenuIsOpen(false)}
                                components={animatedComponents}
                                filterOption={(option, inputValue) => {
                                  const { label, code, phone } = option.data;
                                  const searchText = inputValue.toLowerCase();

                                  return (
                                    label.toLowerCase().includes(searchText) || 
                                    (code && code.toLowerCase().includes(searchText)) || 
                                    (phone && phone.toLowerCase().includes(searchText)) 
                                  );
                                }}
                                isDisabled= {props.sale && props.sale.id ? true : false }
                              />
                          </div>
                        </Col>
                        :
                        <></>
                      }
                      <Col xl={12} sm={12}>
                        <div className='mb-2'>
                            <Label htmlFor="bank-field" className="form-label">{props.t("banking_account")}<span className='text-danger'>*</span></Label><br/>
                            <Select
                                name='bank'
                                id='bank-field'
                                value={selectedBank}
                                isMulti={false}
                                isClearable={false}
                                onChange={(e) => {
                                    setSelectedBank(e);
                                }}
                                options={bankOption}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                            />
                        </div>
                      </Col>
                      <Col xl={12} sm={12}>
                        <div className='mb-2'>
                          <p className="mb-0">{props.t("amount")}<span className='text-danger'>*</span></p>
                          <Input
                              name='amount'
                              type="number"
                              id="amount-field"
                              placeholder={props.t("amount")}
                              className="form-control"
                              validate={{
                                  required: { value: true },
                              }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.amount || ""}
                              invalid={
                                  validation.touched.amount && validation.errors.amount ? true : false
                              }
                          />
                          {validation.touched.amount && validation.errors.amount ? (
                              <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xl={12} sm={12}>
                        <div className='mb-2'>
                          <p className="mb-0">{props.t("reason")}</p>
                          <Input
                              name='reason'
                              type="text"
                              id="reason-field"
                              placeholder={props.t("reason")}
                              className="form-control"
                              validate={{
                                  required: { value: true },
                              }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.reason || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                  </ModalBody>

                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => { toggle()}}>{props.t("cancel")}</button>
                    <button type="submit" className="btn btn-success" disabled={ selectedBank && selectedBank.value && validation.values.amount ? false: true} >{ props.t("save")}</button>
                </div>
                </Form>
            </div>
          </Modal>
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PatientAccount))
