import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import {
    getAccountSummary as onGetAccountSummary,
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"
import TableContainerWarehouse from "../../../components/Common/TableContainerWarehouse"

const AccountSummary = (props) => {
    const dispatch = useDispatch();
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
          accountSummary: setting.accountSummary,
        })
    );
    const { accountSummary } = useSelector(SettingsProperties);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadSummary()
    },[dispatch])

    useEffect(() => {
        if (accountSummary && accountSummary.page >= 0) {
            setIsLoading(false)
        }
    },[accountSummary])

    const loadSummary = useCallback((pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
            dispatch(onGetAccountSummary({page: pageNumber, size: pageSize}))
        } else {
            dispatch(onGetAccountSummary({ page: 1, size: 10 }))
        }
    })

    function capitalizeName(name) {
        if (!name) return '';
        return name
            .split(' ') 
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
            .join(' '); 
    }

    const columns = useMemo(
        () => [
          {
            header: props.t("no"),
            style: "cat",
            enableColumnFilter: false,
            cell: (cellProps) => {
              return <span>
                  {accountSummary.page == 1 ? (cellProps.row.index + 1) : (((accountSummary.page-1)*10)+cellProps.row.index + 1) }
              </span>;
            },
          },
          {
            header: props.t("full_name"),
            accessorKey: "fullName",
            style: "cat",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{capitalizeName(cell.row.original.fullName)}</span>
            }
          },
          {
            header: props.t("phone"),
            accessorKey: "phone",
            style: "cat",
            enableColumnFilter: false,
          },
          {
            header: props.t("amount"),
            style: "type",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.amount}</span>
            }
          },
          {
            header: props.t("action"),
            style: "action",
            cell: (cellProps) => {
            return (
                <div className="d-flex">
                    <ul className="list-inline hstack mb-0">
                    <li className="list-inline-item edit" title="Edit">
                        <>
                            <Link 
                                to={`/cashier/account-summary/details/${cellProps.row.original.id}`}
                                className="btn save-del btn-primary " style={{fontSize: "11px" }}
                            >
                            {props.t("view_discharge") }
                            </Link>
                        </>
                    </li>
                    </ul>
                </div>
            );
            },
        },
        ],
        [accountSummary]
    );

  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="patients-list-content">
                    <div className="patients-list px-2 pb-5 ">
                        <div className="container-fluid ">
                            <div className="heading-parent-sec ">
                                <div className="heading-child-one-sec pt-4">
                                    <h6>{props.t("staff_account_summary")}</h6>
                                </div>
                                <div className="heading-child-two-sec pt-4">
                                </div>
                            </div>
                            <div className="card border-0 row p-2">
                                <div className="row">
                                        <TableContainerWarehouse
                                            columns={columns}
                                            data={((accountSummary && accountSummary.items) || [])}
                                            isGlobalFilter={false}
                                            customPageSize={10}
                                            divClass="card table-card table-warehouse table-responsive"
                                            tableClass="table"
                                            theadClass="thead-sec table-light"
                                            tdClass="td"
                                            tableSize={ accountSummary && accountSummary.size }
                                            tablePage={ accountSummary && accountSummary.page }
                                            tableTotal= { accountSummary && accountSummary.total_items }
                                            tableTotalPage= { accountSummary && accountSummary.total_page }
                                            onNextPage= {loadSummary}
                                            onSearch= {loadSummary}
                                            SearchPlaceholder={props.t("search_cash_rop")}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(AccountSummary))
