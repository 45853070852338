import React, { useEffect, useState } from 'react'
import withRouter from '../../Common/withRouter'
import { withTranslation } from 'react-i18next'
import { Input } from 'reactstrap'

const InventoryRow = (props) => {

    const [quantity, setQuantity] = useState(props.row.quantity)


    const handleBlur = (e, setValue) => {
        if (e.target.value === '' || e.target.value === '0' || e.target.value <= 1) {
            setValue(1);
        }
    };

    useEffect(() =>{
        if (quantity > 0) {
            if (props.isValidate) {
                let object = {
                    productId: props.row.productId,
                    name: props.row.name,
                }
                props.updateRow(object)
            } else {
                let object = {
                    productId: props.row.productId,
                    quantity: quantity,
                }
                props.updateRow(object)
            }
        }
    },[quantity])

  return (
    < >
        <td className="text-center">{props.index+1}</td>
        <td>{props.row.name}</td>
        <td>{props.row.ExpQuantity}</td>
        <td className="text-center">
            <Input
                type="number"
                className="form-control m-0"
                value={quantity}
                onChange={(e) => setQuantity(Number(e.target.value))} 
                onBlur={(e) => handleBlur(e, setQuantity)} 
                aria-label="Text input with checkbox" 
                disabled = {props.isWatch ? true : false} 
            />
        </td>
        {/* {!props.isWatch ?
            <td className="text-center" onClick={() => props.deleteRow(props.row) } >
                <i className="fa-solid fa-trash-can delete-action-btn"></i>
            </td>
            :
            <></>
        } */}
    </>
  )
}

export default withRouter(withTranslation()(InventoryRow))
