import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import { useSelector, useDispatch } from "react-redux"
import * as Yup from "yup"
import Select from "react-select"
import { useFormik } from 'formik'
import { Link, useNavigate, useParams } from "react-router-dom"
import { createSelector } from "reselect"
import makeAnimated from "react-select/animated"
import {
  saveWarehouseInventory as onSaveWarehouseInventory,
  updateWarehouseInventory as onUpdateWarehouseInventory,
  getWarehouseInventory as onGeWarehouseInventory,
  getAllStockGoodsProducts as onGetProductsAndSearchStock,
  deleteTransfertOrderItem as onDeleteInventoryRowItem,
} from "../../../slice/thunks"
import { Input, Label } from 'reactstrap'
import InventoryRow from '../../../components/App/Warehouse/InventoryRow'
import DeleteModal from '../../../components/Global/DeleteModal'
import GlobalLoader from '../../../components/Common/GlobalLoader'

const NewInventory = (props) => {
    const { id, action } = useParams()
    const dispatch = useDispatch()
    const history = useNavigate()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            allStockGoodsProducts: setting.allStockGoodsProducts,
            warehouseInventory: setting.warehouseInventory,
            createWarehouseInventorySuccess: setting.createWarehouseInventorySuccess,
            updateWarehouseInventorySuccess: setting.updateWarehouseInventorySuccess,
            confirmWarehouseInventorySuccess: setting.confirmWarehouseInventorySuccess,
            deleteWarehouseInventorySuccess: setting.deleteWarehouseInventorySuccess,

            error: setting.error
        })
    )
    const { 
      allStockGoodsProducts, 
      warehouseInventory, 
      createWarehouseInventorySuccess, 
      updateWarehouseInventorySuccess, 
      confirmWarehouseInventorySuccess, 
      deleteWarehouseInventorySuccess, 
      error 
    } = useSelector(SettingsProperties)
    const [productsOption, setProducstOption] = useState(null)
    const [selectedProduct, setSellectedProduct] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [inventoryItems, setInventoryItems] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [approveModal, setApproveModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isValidate, setIsValidate] = useState(false)
    const [isWatch, setIsWatch] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const loadCurrentPharmacyInventory = useCallback((currentId) => {
      if (currentId) {
        dispatch(onGeWarehouseInventory(currentId))
      }
    })

    const loadAllProduct = useCallback((term) => {
      if (term && term.length > 0) {
        dispatch(onGetProductsAndSearchStock({term: term, targetService: "Warehouse"}))
      } else {
        dispatch(onGetProductsAndSearchStock({targetService: "Warehouse"}))
      }
    })


    useEffect(() => {
      if (action && action === 'edit') {
        setIsEdit(true)
      } else if (action && action === 'validate') {
        setIsEdit(true)
        setIsValidate(true)
      } else if (action && action === 'see') {
        setIsWatch(true)
        setIsValidate(true)
      }
    },[action])

    useEffect(() => {
      loadAllProduct();
      if ((action === 'edit') || (action === 'validate') || (action === 'see')) {
        loadCurrentPharmacyInventory(id)
      }
    },[dispatch])

    useEffect(() =>{
      setIsLoading(false)

      if (!action && allStockGoodsProducts && allStockGoodsProducts.length > 0) {
        // const products_list = allStockGoodsProducts.map( prod => {
        //   return { 
        //     label: prod.name, 
        //     value: prod.id,
        //   }
        // })
        // setProducstOption(products_list)

        const products_list = allStockGoodsProducts.map( prod => {
          return { 
            productId: prod.id,
            name: prod.name,
            ExpQuantity: prod.qty,
            quantity: 0
          }
        })
        setInventoryItems(products_list); 

      }
    },[allStockGoodsProducts])

    useEffect(() => {
      if (warehouseInventory && warehouseInventory.id) {
        setSelectedDate(warehouseInventory.inventoryDate)
        let Objproducts = warehouseInventory.products.map(item => {
          return {
            id: item.id,
            productId: item.id,
            quantity: item.qty,
            ExpQuantity: item.expectedQty,
            name: item.name,
          };
        });

        setInventoryItems(Objproducts)
      }
    }, [warehouseInventory])

    useEffect(() => {
      if (createWarehouseInventorySuccess && !error) {
        setIsEdit(false)
        setSelectedDate(null)
        setSellectedProduct(null)
        setInventoryItems([])
        history("/warehouse/inventory")
      }
    },[createWarehouseInventorySuccess, error, warehouseInventory])

    useEffect(() => {
      if (updateWarehouseInventorySuccess && !error) {
        history("/warehouse/inventory")
      }
    },[updateWarehouseInventorySuccess, error, warehouseInventory])

    useEffect(() => {
      if (confirmWarehouseInventorySuccess && !error) {
        history("/warehouse/inventory")
      }
    },[confirmWarehouseInventorySuccess, error, warehouseInventory])

    useEffect(() => {
      if (deleteWarehouseInventorySuccess && !error) {
        let current_products = [...inventoryItems]; 
        current_products = current_products.filter((prod) => prod.productId !== selectedProduct.productId )
        setInventoryItems(current_products);
      }
    },[deleteWarehouseInventorySuccess, error, warehouseInventory])

    useEffect(() => {
      if (selectedProduct !== null) {
          let current_products = [...inventoryItems]; 
          if (!current_products.some((current) => current.productId === selectedProduct.productId)) {
            const new_products = [...current_products, selectedProduct]; 
            setInventoryItems(new_products); 
          }
      }
    }, [selectedProduct]);

    const handleSelectProduct = (sup) => {
      if(sup) {
        let row = {
          productId: sup.value,
          name: sup.label,
          quantity: 1
        }
        setSellectedProduct(row)
      }
    }

    const handleSearchProduct = (value) => {
      loadAllProduct(value)
    }

    const handleUpdateRow = (row) => {
      let current_products = [...inventoryItems]; 
      const index = current_products.findIndex(objet => objet.productId === row.productId);
      if (index !== -1) {
        current_products[index] = { ...current_products[index], ...row };
        setInventoryItems(current_products); 
      } 
    } 

    const handleChangeDate = (val) => {
      setSelectedDate(val)
    }

    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    const onClickDelete = (row) => {
      setSellectedProduct(row)
      setDeleteModal(true);
    }

    const handleDelete = () => {
      if (selectedProduct) {
        // if (selectedProduct.id) {
        //   dispatch(onDeleteInventoryRowItem(selectedProduct.id))
        // } else {
          let current_products = [...inventoryItems]; 
          current_products = current_products.filter((prod) => prod.productId !== selectedProduct.productId )
          setInventoryItems(current_products); 
        // }
        setDeleteModal(false)
      }
    }

    const saveOrder = () => {
      let products = []

      if (isEdit) {
        products =  inventoryItems.map(item => {
          return {
            id: item.productId,
            qty: item.quantity
          };
        });

        let updateData = {
          inventoryDate: selectedDate,
          serviceUnit: "Warehouse",
          products: products
        }
        dispatch(onUpdateWarehouseInventory({ id:id, data:updateData }))
      } else {
        products =  inventoryItems.map(item => {
          return {
            id: item.productId,
            qty: item.quantity
          };
        });

        let transferData = {
          inventoryDate: selectedDate,
          serviceUnit: "Warehouse",
          products: products
        }

        dispatch(onSaveWarehouseInventory(transferData))
      }
    }

    const onClear = () => {
      setIsEdit(false)
      setSelectedDate(null)
      setSellectedProduct(null)
      setInventoryItems([])
    }

  return (
    <React.Fragment>
      {isLoading ?
          <GlobalLoader />
        :
          <>
            <div className="record-Purchase-contant pb-5">
                <div className="record-list py-4 px-4">
                    <h1>{ props.t("inventory")}</h1>
                    
                    <div className="row Purchase-order">
                        <h6>
                            <Link to="/warehouse/inventory">
                                <i className="fa-solid fa-chevron-left"></i> {isWatch ? props.t("inventory") : isEdit ? props.t("edit_inventory") : props.t("add_inventory")}
                            </Link>
                        </h6>
                    </div>

                    <div className="row py-3 Purchase-list">
                        <div className="col-lg-3 second-select pt-3">
                            <Label  htmlFor="supplier-field" className="form-label">
                                {props.t("date")}<span className='text-danger'>*</span>
                            </Label>
                            <Input 
                                name="dob" id='dob-field' className="form-control" type="date"
                                value={selectedDate}
                                validate={{ required: { value: true } }} 
                                max={getTodayDate()} 
                                onChange={(e) => handleChangeDate(e.target.value)}
                                autoComplete="off"
                                disabled = {isValidate ? true : false}
                            />
                        </div>

                        {/* <div className="col-lg-3 second-select pt-3">
                            <Label  htmlFor="product-field" className="form-label">
                                {props.t("product")}<span className='text-danger'>*</span>
                            </Label>
                            <Select
                                name='product'
                                id='product-field'
                                value=""
                                isMulti={false}
                                isClearable={false}
                                autoComplete="off"
                                onChange={(e) => {
                                  handleSelectProduct(e);
                                }}
                                onInputChange={(inputValue, { action }) => {
                                  if (action === 'input-change') {
                                    if (inputValue.length > 0) {
                                      handleSearchProduct(inputValue)
                                    } else {
                                      loadAllProduct()
                                    }
                                  }
                                }}
                                onMenuOpen={() => {
                                  loadAllProduct()
                                }}
                                options={productsOption}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                isDisabled = {isValidate ? true : false}
                            />
                        </div> */}
                    </div>

                    <div className="Purchase-all-list ">
                        <div className="table table-responsive">
                            <table className="table table-bordered ">
                                <thead>
                                    <tr className="first-head">
                                        <th scope="col" style={{ width: "7%", textAlign: "center", padding:"17px" }}>{props.t("s_no")}</th>
                                        <th scope="col" style={{ width: "55%", padding:"17px" }}>{props.t("item")}</th>
                                        <th scope="col" style={{ width: "15%", textAlign: "center", padding:"17px" }}>{props.t("expected_qty")}</th>
                                        <th scope="col" style={{ width: "15%", textAlign: "center", padding:"17px" }}>{props.t("qtyToTransfert")}</th>
                                        {/* <th scope="col" style={{ width: "5%", textAlign: "center", padding: "17px" }}>{props.t("action")}</th> */}
                                    </tr>
                                </thead>
                                <tbody>

                                  {inventoryItems.map((row, index) => (
                                    <tr key={row.productId}>
                                      <InventoryRow 
                                        row={row} 
                                        index={index} 
                                        isEdit={id ? true : false } 
                                        isWatch={isWatch} 
                                        isValidate= {isValidate} 
                                        updateRow= {handleUpdateRow} 
                                        deleteRow={onClickDelete}
                                      />
                                    </tr>
                                  ))}
                                </tbody>
                            </table>
                            <div className="bttns">
                                <Link to="/warehouse/inventory" onClick={() => onClear()}>
                                  <button className="btn-secondary" style={{ padding: "6px 35px 6px 35px", fontSize: "18px", margin: "8px", textAlign: "center", borderRadius: "8px" }} type="button">{props.t("cancel")}</button>
                                </Link>
                                {!isWatch ?
                                  <button 
                                    className="btn-two" 
                                    onClick={() => saveOrder()}
                                    disabled={ selectedDate && (inventoryItems.length > 0) ? false : true }
                                  >
                                    {isValidate ? props.t("validate") : isEdit ? props.t("update") :  props.t("save")}
                                  </button>
                                  :
                                  <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() =>setDeleteModal(false)} />
            {/* <ApproveModal show={approveModal} onConfirmClick={saveOrder} message={props.t("confirm_ph_stock_transfer")} onCloseClick={() =>setApproveModal(false)} /> */}

          </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(NewInventory))
