import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import { useSelector, useDispatch } from "react-redux"
import * as Yup from "yup"
import Select from "react-select"
import { useFormik } from 'formik'
import { Link, useNavigate } from "react-router-dom"
import { createSelector } from "reselect"
import makeAnimated from "react-select/animated"
import moment from 'moment'
import {
    getTransfertOrders as onGetTransfertOrders,
  } from "../../../slice/thunks"
import TableContainerWarehouse from '../../../components/Common/TableContainerWarehouse'
import GlobalLoader from '../../../components/Common/GlobalLoader'

const PhTransfertOrder = (props) => {
    const dispatch = useDispatch()
    const history = useNavigate()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            transfertOrders: setting.transfertOrders,
            error: setting.error
        })
    )
    const { transfertOrders, error } = useSelector(SettingsProperties)
    const [isLoading, setIsLoading] = useState(true)

    const loadTransfertOrder = useCallback((pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
          dispatch(onGetTransfertOrders({page: pageNumber, size: pageSize}))
        } else {
          dispatch(onGetTransfertOrders({ page: 1, size: 10 }))
        }
    })

    const searchTransfertOrder = useCallback((term, pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
            dispatch(loadTransfertOrder({term: term, page: pageNumber, size: pageSize}))
        } else {
            dispatch(loadTransfertOrder({term: term, page: 1, size: 10}))
        }
    })

    useEffect(() => {
        loadTransfertOrder()
    },[dispatch])

    useEffect(() => {
        if (transfertOrders.page > 0) {
            setIsLoading(false)
        }
    },[transfertOrders])


    const columns = useMemo(
        () => [
          {
            header: props.t("date"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <div className='d-flex align-items-center'>
                          <span>
                            {moment(cell.row.original.orderDate).format('DD / MM/ YYYY')}
                          </span>
                  </div>
            }
          },
          {
            header: props.t("transfertNo"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{cell.row.original.orderRef}</span>
            }
          },
          {
            header: props.t("sector"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{cell.row.original.transferTo}</span>
            }
          },
          {
            header: props.t("doneBy"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{cell.row.original.doneBy}</span>
            }
          },
          {
            header: props.t("status"),
            accessorKey: "status",
            style: "main",
            enableColumnFilter: false,
          },
          {
            header: props.t("action"),
            style: "action",
            cell: (cellProps) => {
              return (
                <div className="d-flex justify-content-center">
                    <ul className="list-inline hstack mb-0">
                        <li className="list-inline-item edit" title={props.t("edit")}>
                            <Link
                                to={`/pharmacy/transfert-order-new/${cellProps.row.original.id}/see`}
                                className="text-primary d-inline-block edit-item-btn"
                            >
                                <i className="fa fa-eye" aria-hidden="true"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
              );
            },
          },
        ],
        []
    );


  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="patients-list-content">
                    <div className="patients-list  px-2 pt-4 pb-5 ">
                        <div className="container-fluid">
                            {/* <!-- Title-area  --> */}

                            <div className="heading-parent-sec">
                                <div className="heading-child-one-sec">
                                    <h6>{props.t("stock_recieved")}</h6>
                                </div>
                                <div className="heading-child-two-sec d-flex justify-content-end align-items-center">
                                    {/* <div className="create-new-patient-btn my-2 ps-2">
                                        <img src="../assets/images/new-patient/img-one.png" className="img-fluid" />
                                        <div className="create-new-patient-btn-child-two">
                                            <Link to="/warehouse/transfert-order-new">
                                                <button className="btn">{props.t("new_transfert_order")}</button>
                                            </Link>

                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            <div className="row pb-2">
                                {/* <!-- TAB 5 : See Doctor's Patient --> */}
                                <div className="see-doctor-patient ">
                                    <div className="row">
                                        <TableContainerWarehouse
                                            columns={columns}
                                            data={((transfertOrders && transfertOrders.items) || [])}
                                            isGlobalFilter={false}
                                            customPageSize={10}
                                            divClass="card table-card table-warehouse"
                                            tableClass="table"
                                            theadClass="thead-sec table-light"
                                            tdClass="td"
                                            tableSize={ transfertOrders && transfertOrders.size }
                                            tablePage={ transfertOrders && transfertOrders.page }
                                            tableTotal= { transfertOrders && transfertOrders.total_items }
                                            tableTotalPage= { transfertOrders && transfertOrders.total_page }
                                            onNextPage= {loadTransfertOrder}
                                            onSearch= {searchTransfertOrder}
                                            SearchPlaceholder={props.t("search_transfert_order")}
                                        />
                                        {/* <div className="card table-card table-responsive">
                                            
                                            <table className="table ">
                                                <thead className="thead-sec table-light ">
                                                    <tr>
                                                        <th style={{ width: "19%" }} className="ps-3">Date</th>
                                                        <th style={{ width: "18%" }}>Bill Payment No.</th>
                                                        <th style={{ width: "16%" }}>Contact/Account</th>
                                                        <th style={{ width: "17%" }}>Amount</th>
                                                        <th style={{ width: "16%" }}>Balance</th>
                                                        <th style={{ width: "8%" }}>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody className="output-sno">
                                                    <tr>
                                                        <td className="ps-3">10/10/2020</td>
                                                        <td>PMT01</td>
                                                        <td>Ajeet Pharmacy</td>
                                                        <td>Fr.1000</td>
                                                        <td>
                                                            Fr.1000
                                                        </td>
                                                        
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="#"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="#"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="ps-3">10/12/2020</td>
                                                        <td>PMT02</td>
                                                        <td>JK Pharmacy</td>
                                                        <td>Fr.2000</td>
                                                        <td>
                                                            Fr.2000
                                                        </td>
                                                        
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="#"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="#"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="ps-3">10/13/2020</td>
                                                        <td>PMT03</td>
                                                        <td>John Pharmacy</td>
                                                        <td>Fr.1000</td>
                                                        <td>
                                                            Fr.1000
                                                        </td>
                                                    
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="#"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="#"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="ps-3">10/14/2020</td>
                                                        <td>PMT04</td>
                                                        <td>Robert Pharmacy</td>
                                                        <td>Fr.2000</td>
                                                        <td>
                                                            Fr.2000
                                                        </td>
                                                        
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="#"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="#"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="ps-3">10/15/2020</td>
                                                        <td>PMT05</td>
                                                        <td>Ajeet Pharmacy</td>
                                                        <td>Fr.1000</td>
                                                        <td>
                                                            Fr.1000
                                                        </td>
                                                        
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="#"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="#"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="ps-3">10/16/2020</td>
                                                        <td>PMT06</td>
                                                        <td>JKL Pharmacy</td>
                                                        <td>Fr.2000</td>
                                                        <td>
                                                            Fr.2000
                                                        </td>
                                                        
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="#"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="#"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="ps-3">10/17/2020</td>
                                                        <td>PMT07</td>
                                                        <td>John Pharmacy</td>
                                                        <td>Fr.1000</td>
                                                        <td>
                                                            Fr.1000
                                                        </td>
                                                        
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="#"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="#"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="ps-3">10/18/2020</td>
                                                        <td>PMT08</td>
                                                        <td>Robert Pharmacy</td>
                                                        <td>Fr.2000</td>
                                                        <td>
                                                            Fr.2000
                                                        </td>
                                                        
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="#"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="#"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="ps-3">10/19/2020</td>
                                                        <td>PMT09</td>
                                                        <td>Ajeet Pharmacy</td>
                                                        <td>Fr.1000</td>
                                                        <td>
                                                            Fr.1000
                                                        </td>
                                                        
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="#"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="#"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="ps-3">10/20/2020</td>
                                                        <td>PMT10</td>
                                                        <td>John Pharmacy</td>
                                                        <td>Fr.2000</td>
                                                        <td>
                                                            Fr.2000
                                                        </td>
                                                        
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="#"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="#"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination ps-3">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Previous">
                                                            <img src="../assets/images/left.png" alt="" />
                                                        </a>
                                                    </li>
                                                    <li className="page-item active" aria-current="page">
                                                        <span className="page-link">1</span>
                                                    </li>
                                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Next">
                                                            <img src="../assets/images/right.png" alt="" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PhTransfertOrder))
