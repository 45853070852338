import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom"
import { withTranslation } from 'react-i18next'
import * as Yup from "yup"
import { useFormik } from 'formik'
import moment from 'moment'
import withRouter from '../../../../../components/Common/withRouter'
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader, CardBody } from 'reactstrap'
import {
  getRecordByPatient as onGetRecordByPatient,
  releasePatient as onReleasePatient
} from "../../../../../slice/thunks"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import TableContainerBase from '../../../../../components/Common/TableContainerBase'

const HistoryDetails = (props) => {
    let { id } = useParams();
    const dispatch = useDispatch()
    const selectLayoutState = (state) => state.Outputs;
    const OutputsProps = createSelector(
        selectLayoutState,
        (outputs) =>({
            patientRecordHistory: outputs.patientRecordHistory,
            release_patient_success: outputs.release_patient_success,
            error: outputs.error,
        })
    )
    const { patientRecordHistory, release_patient_success} = useSelector(OutputsProps)
    const [patient, setPatient] = useState(null)
    const [patientVitals, setPatientVitals] = useState(null)
    const [doctor, setDoctor] = useState(null)
    const [beds, setBeds] = useState(null)
    const [products, setProducts] = useState(null)
    const [modal, setModal] = useState(false)


    const loadPatientHistory = useCallback((rowId) => {
        dispatch(onGetRecordByPatient({id: rowId}))
    }, [dispatch])

    const validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,

      initialValues: {
          releaseDate: (patientRecordHistory && patientRecordHistory.releaseDate) || '',
          description: (patientRecordHistory && patientRecordHistory.description) || '',
      },
      validationSchema: Yup.object({
          releaseDate: Yup.string().required(props.t("you_need_provide_date")),
          description: Yup.string().required(props.t("you_need_provide_description")),
      }),
      onSubmit: (values) => {
          const leaveForm = {
            description: values.description,
            releaseDate: values.releaseDate,
          }
          const id = patientRecordHistory.id
          dispatch(onReleasePatient({id: id, data: leaveForm}))
      },
    })

    const toggle = useCallback(() => {
      if (modal) {
          setModal(false);
          validation.resetForm()
      } else {
          setModal(true);
      }
    }, [modal, validation])

    const handleSelectPatient = useCallback(() => {
      toggle()
    },[toggle])

    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }


    useEffect(() => {
      if (id) {
        loadPatientHistory(id)
      }
  },[id, loadPatientHistory])

  useEffect(() => {
    if (patientRecordHistory && patientRecordHistory.id) {
      if (patientRecordHistory.patient != null) {
        setPatient(patientRecordHistory.patient)
      }
      if (patientRecordHistory.patientVitals != null) {
        setPatientVitals(patientRecordHistory.patientVitals)
      }
      if (patientRecordHistory.doctors != null) {
        setDoctor(patientRecordHistory.doctors)
      }
      if (patientRecordHistory.bedRecords != null) {
        setBeds(patientRecordHistory.bedRecords)
      }
      if (patientRecordHistory.products != null) {
        setProducts(patientRecordHistory.products)
      }
    }
  }, [patientRecordHistory])

  useEffect(() => {
    if (release_patient_success) {
      if (id) {
        loadPatientHistory(id)
        toggle()
      }
    }
  },[release_patient_success, id, loadPatientHistory, toggle])

    const vitalColumns = useMemo(
      () => [
        {
          header: props.t("no"),
          accessorKey: "index",
          style: "button",
          enableColumnFilter: false,
          cell: (cellProps) => {
              return <span>{cellProps.row.index + 1}</span>;
          },
        },
        {
          header: props.t("bp"),
          accessorKey: "bp",
          style: "text-start",
          enableColumnFilter: false,
        },
        {
          header: props.t("height"),
          accessorKey: "height",
          style: "text-start",
          enableColumnFilter: false,
        },
        {
          header: props.t("weight"),
          accessorKey: "weight",
          style: "text-start",
          enableColumnFilter: false,
        },
        {
          header: props.t("temperature"),
          accessorKey: "temperature",
          style: "text-start",
          enableColumnFilter: false,
        },
        {
          header: props.t("spo"),
          accessorKey: "spo",
          style: "text-start",
          enableColumnFilter: false,
        },
      ],
      [props]
    );

    const doctorColumns = useMemo(
      () => [
        {
          header: props.t("s_no"),
          accessorKey: "index",
          style: "button",
          enableColumnFilter: false,
          cell: (cellProps) => {
              return <span>{cellProps.row.index + 1}</span>;
          },
        },
        {
          header: props.t("fullName"),
          accessorKey: "fullName",
          style: "text-start",
          enableColumnFilter: false,
        },
        {
          header: props.t("title"),
          accessorKey: "title",
          style: "text-start",
          enableColumnFilter: false,
        },
        {
          header: props.t("email"),
          accessorKey: "email",
          style: "text-start",
          enableColumnFilter: false,
        },
        {
          header: props.t("phone"),
          accessorKey: "phone",
          style: "text-start",
          enableColumnFilter: false,
        },
      ],
      [props]
    );

    const bedColumns = useMemo(
      () => [
        {
          header: props.t("no"),
          accessorKey: "index",
          style: "button",
          enableColumnFilter: false,
          cell: (cellProps) => {
              return <span>{cellProps.row.index + 1}</span>;
          },
        },
        {
          header: props.t("bed_id"),
          accessorKey: "bedId",
          style: "text-start",
          enableColumnFilter: false,
          cell: (cell) => {
            return (
                <span>
                    { cell.row.original.bed.bedNumber }
                </span>
                )
          }
        },
        {
          header: props.t("ward"),
          accessorKey: "discount",
          style: "text-start",
          enableColumnFilter: false,
          cell: (cell) => {
            return (
                <span>
                    { cell.row.original.bed.wards.wardsName }
                </span>
                )
          }
        },
        {
          header: props.t("charges"),
          accessorKey: "effectiveCharge",
          style: "text-start",
          enableColumnFilter: false,
        },
        {
          header: props.t("entry_date"),
          accessorKey: "entryDate",
          style: "text-start",
          enableColumnFilter: false,
          cell: (cell) => {
            return (
                <span>
                    { moment(cell.row.original.entryDate).format('DD / MM/ YYYY') }
                </span>
                )
          }
        },
        {
          header: props.t("released_date"),
          accessorKey: "realeaseDate",
          style: "text-start",
          enableColumnFilter: false,
          cell: (cell) => {
            return (
                <span>
                    { cell.row.original.releaseDate ? moment(cell.row.original.releaseDate).format('DD / MM/ YYYY') : '' }
                </span>
                )
          }
        },
      ],
      [props]
    );

    const productColumns = useMemo(
      () => [
        {
          header: props.t("no"),
          accessorKey: "index",
          style: "button",
          enableColumnFilter: false,
          cell: (cell) => {
              return <span>{cell.row.index + 1}</span>;
          },
        },
        {
          header: props.t("ref_id"),
          accessorKey: "reference",
          style: "text-start",
          enableColumnFilter: false,
          cell: (cell) => {
            return (
                <span>
                    { cell.row.original.reference }
                </span>
            )
          }
        },
        {
          header: props.t("description"),
          accessorKey: "description",
          style: "text-start",
          enableColumnFilter: false,
          cell: (cell) => {
            const truncateText = (text, maxLength) => {
                if (text.length > maxLength) {
                    return text.substring(0, maxLength) + '...';
                }
                return text;
            };

            return <span>{truncateText(cell.row.original.description, 15)}</span>;
          }
        },
        {
          header: props.t("quantity"),
          accessorKey: "qty",
          style: "text-start",
          enableColumnFilter: false,
        },
        {
          header: props.t("price_unit"),
          accessorKey: "unitPrice",
          style: "text-start",
          enableColumnFilter: false,
        },
        {
          header: props.t("amount"),
          accessorKey: "amount",
          style: "text-start",
          enableColumnFilter: false,
        },
        {
          header: props.t("collector"),
          accessorKey: "collector",
          style: "text-start",
          enableColumnFilter: false,
        },
        {
          header: props.t("entry_date"),
          accessorKey: "entryDate",
          style: "text-start",
          enableColumnFilter: false,
          cell: (cell) => {
            return (
                <span>
                    { moment(cell.row.original.createdAt).format('DD / MM/ YYYY') }
                </span>
                )
          }
        },
      ],
      [props]
    );

  return (
    <div className="patients-list-content">
        <div className="patients-list py-4 px-2">
          <div className="container">
            <div className="row list-patients d-flex align-items-center justify-content-between">
              <Row className='mb-2'>
                <div className='col-xl mb-0'>
                    <h6>{props.t("patient_visit_visit_date")} : { patientRecordHistory && patientRecordHistory.entryDate ? moment(patientRecordHistory.entryDate).format('DD/MM/YYYY') : '' } { patientRecordHistory && patientRecordHistory.releaseDate ? " - "+moment(patientRecordHistory.releaseDate).format('DD / MM/ YYYY') : '' }</h6>
                </div>
                {/* <div className='col-xl-auto'>
                { patientRecordHistory && patientRecordHistory.releaseDate ?
                    <Link
                        to="#"
                        className="btn btn-one"
                        onClick={() => handleSelectPatient() }
                    >
                      { props.t("edit") }
                    </Link>
                    :
                    <Link
                        to="/patients/bill_discharge/history_details/"
                        className="btn save-del btn-primary px-4"
                        // onClick={() => handleSelectPatient() }
                    >
                      { props.t("patient_bill_discharge") }
                    </Link>
                  }
                </div> */}
              </Row>
              <Col xl={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl={4} sm={12}>
                          <div className='mb-4'>
                            <Label className="form-label"><b> {props.t("firstName")}:</b></Label><br/>
                            <Label className="form-label"> { patient && patient.firstName } </Label>
                          </div>
                        </Col>
                        <Col xl={4} sm={12}>
                          <div className='mb-4'>
                            <Label className="form-label"><b> {props.t("lastName")}: </b></Label><br/>
                            <Label className="form-label"> {patient && patient.lastName} </Label>
                          </div>
                        </Col>
                        <Col xl={4} sm={12}>
                          <div className='mb-4'>
                            <Label className="form-label"><b> {props.t("patientId")}: </b></Label><br/>
                            <Label className="form-label"> #{patient && patient.patientRef} </Label>
                          </div>
                        </Col>

                        <Col xl={4} sm={12}>
                          <div className='mb-4'>
                            <Label className="form-label"><b> {props.t("gender")}: </b></Label><br/>
                            <Label className="form-label"> {patient && patient.gender === "M" ? props.t("male") : patient && patient.gender === "F" ? props.t("female") : props.t("other") } </Label>
                          </div>
                        </Col>
                        <Col xl={4} sm={12}>
                          <div className='mb-4'>
                            <Label className="form-label"><b> {props.t("dob")}: </b></Label><br/>
                            <Label className="form-label"> { patient && moment(patient.dob).format('DD / MM/ YYYY') } </Label>
                          </div>
                        </Col>
                        <Col xl={4} sm={12}>
                          <div className='mb-4'>
                            <Label className="form-label"><b> {props.t("patient_type")}: </b></Label><br/>
                            <Label className="form-label"> {patient && patient.patientType.typeName} </Label>
                          </div>
                        </Col>

                        <Col xl={4} sm={12}>
                          <div className='mb-4'>
                            <Label className="form-label"><b> {props.t("address")}: </b></Label><br/>
                            <Label className="form-label"> {patient && patient.address.address} </Label>
                          </div>
                        </Col>
                        <Col xl={4} sm={12}>
                          <div className='mb-4'>
                            <Label className="form-label"><b> {props.t("city")}: </b></Label><br/>
                            <Label className="form-label"> {patient && patient.address.city} </Label>
                          </div>
                        </Col>
                        <Col xl={4} sm={12}>
                          <div className='mb-4'>
                            <Label className="form-label"><b> {props.t("region")}: </b></Label><br/>
                            <Label className="form-label"> {patient && patient.address.region} </Label>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
              </Col>
            </div>

            <div className="row list-patients d-flex align-items-center justify-content-between mt-5">
              <Col xl={12}>
                  <h6>{props.t("patient_vitals")}</h6>
              </Col>
              <Col xl={12}>
                  <Card>
                    <CardBody>
                      <Row>
                          <TableContainerBase
                            columns={vitalColumns}
                            data={((patientVitals && patientVitals) || [])}
                            isGlobalFilter={false}
                            isAddUserList={false}
                            customPageSize={10}
                            divClass="card table-card table-responsive p-0"
                            tableClass="table align-middle"
                            theadClass="table-light text-muted"
                            handlePlanClick={loadPatientHistory}
                            isCustomerFilter={false}
                            SearchPlaceholder={props.t("search_patient")}
                          />
                      </Row>
                    </CardBody>
                  </Card>
              </Col>
            </div>

            <div className="row list-patients d-flex align-items-center justify-content-between mt-5">
              <Col xl={12}>
                  <h6>{props.t("doctors")}</h6>
              </Col>
              <Col xl={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <TableContainerBase
                          columns={doctorColumns}
                          data={((doctor && doctor) || [])}
                          isGlobalFilter={false}
                          isAddUserList={false}
                          customPageSize={10}
                          divClass="card table-card table-responsive p-0"
                          tableClass="table align-middle"
                          theadClass="table-light text-muted"
                          handlePlanClick={loadPatientHistory}
                          isCustomerFilter={false}
                          SearchPlaceholder={props.t("search_patient")}
                        />
                      </Row>
                    </CardBody>
                  </Card>
              </Col>
            </div>

            <div className="row list-patients d-flex align-items-center justify-content-between mt-5">
              <Col xl={12}>
                  <h6>{props.t("beds")}</h6>
              </Col>
              <Col xl={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <TableContainerBase
                            columns={bedColumns}
                            data={((beds && beds) || [])}
                            isGlobalFilter={false}
                            isAddUserList={false}
                            customPageSize={10}
                            divClass="card table-card table-responsive p-0"
                            tableClass="table align-middle"
                            theadClass="table-light text-muted"
                            handlePlanClick={loadPatientHistory}
                            isCustomerFilter={false}
                            SearchPlaceholder={props.t("search_patient")}
                          />
                      </Row>
                    </CardBody>
                  </Card>
              </Col>
            </div>

            <div className="row list-patients d-flex align-items-center justify-content-between mt-5">
              <Col xl={12}>
                  <h6>{props.t("products")}</h6>
              </Col>
              <Col xl={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <TableContainerBase
                            columns={productColumns}
                            data={((products && products) || [])}
                            isGlobalFilter={false}
                            isAddUserList={false}
                            customPageSize={10}
                            divClass="card table-card table-responsive p-0"
                            tableClass="table align-middle"
                            theadClass="table-light text-muted"
                            handlePlanClick={loadPatientHistory}
                            isCustomerFilter={false}
                            SearchPlaceholder={props.t("search_patient")}
                          />
                      </Row>
                    </CardBody>
                  </Card>
              </Col>
            </div>

            {/* <div className="row list-patients d-flex align-items-center justify-content-between">
              <Col xl={12}>
                  <h6>{props.t("invoice_details")}</h6>
              </Col>
              <Col xl={12}>
                  <Card>
                    <CardBody>
                      <Row>
                      </Row>
                    </CardBody>
                  </Card>
              </Col>
            </div> */}
          </div>
          <Modal id="showModal" size='md' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
            <ModalHeader className='bg-light p-3' toggle={toggle}>
                {props.t("release_patients")}
            </ModalHeader>
            <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}>
                <ModalBody>
                    <Row>
                        <Col xl={12} sm={12}>
                            <div className='mb-2 mt-1'>
                                <Label htmlFor="releaseDate-field" className="form-label">
                                    {props.t("release_date")}<span className='text-danger'>*</span>
                                </Label><br/>
                                <Input
                                    name="releaseDate" id='releaseDate-field' className="form-control" type="date"
                                    validate={{ required: { value: true } }} max={getTodayDate()} onChange={validation.handleChange}
                                    onBlur={validation.handleBlur} value={validation.values.releaseDate || ""}
                                    autoComplete="off"releaseDate
                                    invalid={
                                        validation.touched.releaseDate && validation.errors.releaseDate ? true : false
                                    }
                                />
                                {validation.touched.releaseDate && validation.errors.releaseDate ? (
                                    <FormFeedback type="invalid">{validation.errors.releaseDate}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col xl={12} sm={12}>
                            <div>
                                <Label htmlFor="description-field" className="form-label">
                                    {props.t("description")}
                                    <span className='text-danger'>*</span>
                                </Label>
                                <textarea
                                    name="description"
                                    className="form-control"
                                    id="description-field"
                                    placeholder={props.t("enter_description")}
                                    rows={3}
                                    validate={{
                                        required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.description || ""}
                                    invalid={
                                        validation.touched.description && validation.errors.description ? true : false
                                    }
                                >
                                </textarea>
                                {validation.touched.description && validation.errors.description ? (
                                    <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <div className='p-3'>
                    {/* <Row className="align-items-center">
                    <div className="col-sm">
                        <span className='text-danger align-middle'>* : {props.t("required_fields")}</span>
                    </div> */}
                    <div className="col-sm-auto">
                        <div className="hstack gap-2 justify-content-end">
                        <button type="button" className="btn btn-secondary" onClick={() => { toggle() }}> {props.t("close")} </button>
                        <button type="submit" className="btn btn-success"> {props.t("save")}</button>
                        </div>
                    </div>
                    {/* </Row> */}
                </div>
            </Form>
        </Modal>
        </div>
    </div>
  )
}

export default withRouter(withTranslation()(HistoryDetails))