import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import SalesCahier from './SalesCahier'
import withRouter from '../../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import * as Yup from "yup"
import { useFormik } from 'formik'
import { createSelector } from "reselect"
import {
  getInvoices as onGetInvoice,
  getAllBankAccounts as onGetAllBankAccounts,
  getAssociatedService as onGetAssociatedService,
  getInvoiceStatus as onGetInvoiceStatus,
  makePayment as onMakePayment,
} from '../../../../slice/thunks'
import { useSelector, useDispatch } from "react-redux"
import Flatpickr from "react-flatpickr"
import { useReactToPrint } from 'react-to-print';
import "flatpickr/dist/themes/material_blue.css"
import SinpleActionButton from '../../../../components/Global/SinpleActionButton'
import GlobalLoader from '../../../../components/Common/GlobalLoader'
import makeAnimated from "react-select/animated"
import Select from "react-select"
import { Link } from 'react-router-dom'
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import TableContainer from '../../../../components/Common/TableContainer'

import GeneralCashierContent from '../../../../components/Global/PrintOutputs/Invoices/Content/Cashier/GeneralCashierContent'
import MatrixCashierContent from '../../../../components/Global/PrintOutputs/Invoices/Content/Cashier/MatrixCashierContent'
import ThermalCashierContent from '../../../../components/Global/PrintOutputs/Invoices/Content/Cashier/ThermalCashierContent'

const COMPONENT_MAP = {
    General: GeneralCashierContent,
    DotMatrix: MatrixCashierContent,
    Thermal: ThermalCashierContent,
}; 


const HomeSalesList = (props) => {
  const dispatch = useDispatch()
  const viewRef = useRef(null);
  const payRef = useRef(null);
    
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            saleInvoices: setting.saleInvoices,
            associatedService: setting.associatedService,
            invoiceStatus: setting.invoiceStatus,
            allBankingAccounts: setting.allBankingAccounts,
            payment: setting.payment,
            users: setting.users,
            makePayment : setting.makePayment,
            makePaymentSuccess: setting.makePaymentSuccess,
            error: setting.error
        })
    )
    const { saleInvoices, invoiceStatus,  makePaymentSuccess, allBankingAccounts, payment, error } = useSelector(SettingsProperties)
    const [iscreate, setIsCreate] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [searchUserInput, setSearchUserInput] = useState('')
    const [searchInput, setSearchInput] = useState('')
    const [ paymentOption, setPaymentOption ] = useState([])
    const [ statusOption, setStatusOption] = useState([])
    const [ selectedTarget, setSelectedTarget] = useState(null)
    const [ selectedStatus, setSelectedStatus ] = useState(null)
    const fp = useRef(null);
    const [periods, setPeriods] = useState([]);
    const [filterStatus, setFilterStatus] = useState(null)
    const [filterTarget, setFilterTarget] = useState(null)
    const [currentInvoice, setCurrentInvoice] = useState(null)
    const [ selectedPaymentMPay, setSelectedPaymentMPay ] = useState(null)
    const [modal, setModal] = useState(false)
    const [modalView, setModalView] = useState(false)

    useEffect(()=> {
      
      if (props.isActive) {
        setIsCreate(false)
        loadTarget()
        loadPaymentMode()
        loadStatus()
        loadSales()
      }
    },[props.isActive])

    useEffect(() => {
      if (saleInvoices && saleInvoices.page > 0) {
        setIsLoading(false)
      }
    },[saleInvoices])

    useEffect(() => {
      if (allBankingAccounts && allBankingAccounts.length > 0) {
          const payment_lists = allBankingAccounts.map(mode => ({
              label: mode.bankName,
              value: mode.id
          }));
          setPaymentOption(payment_lists);
      }
    }, [allBankingAccounts]);

    useEffect(() => {
      if (invoiceStatus && invoiceStatus.length > 0) {
            const payment_lists = invoiceStatus.map(mode => ({
              label: mode.title,
              value: mode.value
          }));
          setStatusOption(payment_lists);
      }
    }, [invoiceStatus]);

    useEffect(() => {
      if ((makePaymentSuccess && !error && (payment && payment.id))) {        
        handlePrint()
        loadSales()
        setSelectedPaymentMPay(null)
        setCurrentInvoice(null)
        setModal(false)
      }
    },[makePaymentSuccess, payment, error])

    useEffect(() => {
        if (selectedStatus && selectedStatus.length > 0) {
          let filter_status = null
          selectedStatus.forEach(row => {
            filter_status = filter_status === null ? row.value : filter_status+","+row.value
          });
          setFilterStatus(filter_status)
        }else {
          setFilterStatus(null)
        }
        if (selectedTarget && selectedTarget.length > 0) {
          let filter_target = null
          selectedTarget.forEach(row => {
            filter_target = filter_target === null ? row.value : filter_target+","+row.value
          });
          setFilterTarget(filter_target)
        }else {
          setFilterTarget(null)
        }
    },[selectedTarget, selectedStatus])

    useEffect(() => {
      if (filterStatus || searchUserInput.length > 0 || periods.length > 0 || searchInput.length > 0) {
        loadSales()
      }
    },[filterStatus, searchUserInput, periods, searchInput])


    const loadSales = useCallback((pageNumber, pageSize) => {
        let reqObject = {}
        if ((pageNumber !== null) && (pageNumber !== undefined)) reqObject.pageNumber = pageNumber;
        if ((pageSize !== null) && (pageSize !== undefined)) reqObject.pageSize = pageSize;
        if (filterStatus !== null) reqObject.status = filterStatus;
        if (searchUserInput !== null) reqObject.users = searchUserInput;
        if (periods.length > 0) reqObject.startDate = moment(periods[0]).format("YYYY-MM-DD");
        if (periods.length > 0) reqObject.endDate = moment(periods[1]).format("YYYY-MM-DD");
        if (filterTarget !== null) reqObject.target = filterTarget;
        if (searchInput !== null) reqObject.term = searchInput;
        dispatch(onGetInvoice(reqObject))
    })

    const validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,

      initialValues: {
          id: (setCurrentInvoice && setCurrentInvoice.id) || null,
          bankAccountId: (setCurrentInvoice && setCurrentInvoice.paymentMode) || '',
          amountReceived: (setCurrentInvoice && setCurrentInvoice.amountReceived) || '',
      },
      validationSchema: Yup.object({
        // amountReceived: Yup.string().matches(/^[0-9.]+$/, { message: props.t("provide_valid_amount") }).required(props.t("provide_amount")),
      }),
      onSubmit: (values) => {
          const newVitalForm = {
              bankAccountId: selectedPaymentMPay && selectedPaymentMPay.value,
              amountReceived: values.amountReceived,
              invoiceId: currentInvoice.id
          }
          dispatch(onMakePayment(newVitalForm))
      },
    })

    const toggle = useCallback(() => {
      if (modal) {
        setModal(false);
        validation.resetForm();
        setCurrentInvoice(null);
        setSelectedStatus(null)
        setSelectedPaymentMPay(null)
      } else {
        setModal(true);
      }
    }, [modal, validation, setCurrentInvoice, setSelectedStatus, setSelectedPaymentMPay])

    const toggleView = useCallback(() => {
      if (modalView) {
        setModalView(false);
        validation.resetForm();
        setCurrentInvoice(null);
        setSelectedStatus(null)
        setSelectedPaymentMPay(null)
      } else {
        setModalView(true);
      }
    }, [modalView, validation])

    const loadTarget = useCallback(() => {
      dispatch(onGetAssociatedService())
    })

    const loadPaymentMode = useCallback(() => {

        dispatch(onGetAllBankAccounts(true))
    })

    const loadStatus = useCallback(() => {
      dispatch(onGetInvoiceStatus())
    })

    const printFn = useReactToPrint({
      content: () => payment && payment.id ? payRef.current :  viewRef.current,
      documentTitle: 'Veronica-Invoice',
      copyStyles: true,
      onAfterPrint: () => {
          setModalView(false);
      },
    });

    const handlePrint = useCallback(() => {
      printFn();
    }, [printFn]);

    const handleSelectInvoice = useCallback((invoice) => {
      setCurrentInvoice(invoice)
      toggle()
    }, [toggle])

    const handleOpenInvoice = useCallback((invoice) => {
      setCurrentInvoice(invoice)
      toggleView()
    }, [toggleView])

    const handleOpen = () => {
        setIsCreate(!iscreate)
    }
  
    const renderContent = () => {

      if (!(props.printSettings && props.printSettings.printConfigs)) return null;

      const receiptType = props.printSettings.printConfigs["cashierReceipt"];
      const ContentComponent = COMPONENT_MAP[receiptType] || null;
      const content = payment && payment.invoice || currentInvoice;
  
      return ContentComponent ? 
          <ContentComponent 
            items={content && content.saleOrder && content.saleOrder.items} 
            sale={content && content.saleOrder}
            section="sale"
            totalAmount={content && content.invoiceAmount} 
            paymentDate= {payment && payment.paymentDate}
            amountRecieved={payment && payment.amountPaid || (content && content.invoiceAmount) - (content && content.balance)} 
          /> 
        : null;
    };

    const columns = useMemo(
        () => [
          {
            header: props.t("no"),
            style: "nbLines",
            enableColumnFilter: false,
            cell: (cellProps) => {
              return <span>
                  {saleInvoices.page === 1 ? (cellProps.row.index + 1) : (((saleInvoices.page-1)*10)+cellProps.row.index + 1) }
              </span>;
            },
          },
          {
            header: props.t("date"),
            style: "cat",
            enableColumnFilter: false,
            cell: (cell) => {
              return <div className='d-flex align-items-center'>
                        <span>
                          {moment(cell.row.original.createdAt).format('DD / MM/ YYYY HH:mm')}
                        </span>
                </div>
            }
          },
          {
            header: props.t("ref"),
            style: "type",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.invoiceRef}</span>
            }
          },
          {
            header: props.t("amount"),
            style: "sale",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.invoiceAmount}</span>
            }
          },
          {
            header: props.t("balance"),
            style: "sale",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.balance}</span>
            }
          },
          {
            header: props.t("status"),
            style: "purchase",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.invoiceStatus}</span>
            }
          },
          {
            header: props.t("customer"),
            style: "purchase",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.saleOrder.patient.firstName +" "+cell.row.original.saleOrder.patient.lastName}</span>
            }
          },
          {
            header: props.t("done_by"),
            style: "purchase",
            enableColumnFilter: true,
            cell: (cell) => {
              return <div className='d-flex align-items-center'>
                        <span>
                          {cell.row.original.createdBy}
                        </span>
                </div>
            }
          },
          {
            header: props.t("action"),
            style: "purchase",
            cell: (cellProps) => {
              return (
                <div className="d-flex justify-content-start">
                    <ul className="list-inline hstack mb-0">
                      <li className="list-inline-item edit" title={props.t("make_payment")}>
                          <Link
                            to="#"
                            className="text-dark d-inline-block edit-item-btn" style={{fontSize: "15px" }}
                            onClick={() => handleOpenInvoice(cellProps.row.original)}
                          >
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </Link>
                      </li>
                      <li className="list-inline-item edit" title={props.t("make_payment")}>
                          {/* <Link
                            to="#"
                            className="text-dark d-inline-block edit-item-btn" style={{fontSize: "15px" }}
                            onClick={() => {handlePrintInvoice(cellProps.row.original)}}
                          >
                            <i className="fa fa-print" aria-hidden="true"></i>
                          </Link> */}
                      </li>
                      {cellProps.row.original.invoiceStatus !== 'Paid' ?
                        <li className="list-inline-item edit" title={props.t("make_payment")}>
                          <Link
                            to="#"
                            className="text-dark d-inline-block edit-item-btn" style={{fontSize: "15px" }}
                            onClick={() => handleSelectInvoice(cellProps.row.original)}
                          >
                            <i className="fa fa-credit-card" aria-hidden="true"></i>
                          </Link>
                        </li>
                        :
                        <></>
                      }
                    </ul>
                </div>
              );
            },
          },
        ],
        [saleInvoices, handleSelectInvoice, handleOpenInvoice, props]
    );

  return (
    <>
        {!iscreate ?
            <>
              {isLoading ?
                  <GlobalLoader />
                :
                  <>
                    <div className='px-3'>
                        <div className=" px-3 d-flex justify-content-between">
                            <div className="pt-4">
                                <h5>{props.t("bills")}</h5>
                            </div>
                            <div className="pt-4">
                                <div className="create-new-patient-btn-child-two">
                                    <SinpleActionButton title={props.t("new_sales")} img="../assets/images/user.png" action={handleOpen} />
                                </div>
                            </div>
                        </div>
                        <div>
                          <Row>
                            <Col xl={2} sm={4} className="">
                                <div className='mb-2'>
                                    <label className="form-label" htmlFor="inputGroupSelect01">{props.t("select_date_range")}</label>
                                    <div className='d-flex align-items-center'>
                                        <Flatpickr
                                            className='flatpick-custom'
                                            id="inputGroupSelect01"
                                            ref={fp}
                                            placeholder={props.t("select_date_range")}
                                            defaultValue={periods}
                                            value={periods.length > 0 ? periods : null}
                                            options={{
                                                altInput: true,
                                                altFormat: "d / m/ Y",
                                                maxDate: new Date(),
                                                mode: "range",
                                            }}
                                            onChange={(e) => {
                                                if (e.length > 1) {
                                                    setPeriods(e)
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={2} sm={4}>
                              <div className='mb-2'>
                                  <Label htmlFor="user-field" className="form-label">{props.t("done_by")}</Label><br/>
                                  <div className="search-box me-2 mb-1 col-10">
                                      <Input
                                          className="custom-border form-control"
                                          value={searchUserInput}
                                          onChange={(e) => setSearchUserInput(e.target.value)}
                                      />
                                  </div>
                              </div>
                            </Col>
                            <Col xl={2} sm={4}>
                              <div className='mb-2'>
                                  <Label htmlFor="user-field" className="form-label">{props.t("status")}</Label><br/>
                                  <Select
                                      name='user'
                                      id='user-field'
                                      value={selectedStatus}
                                      isMulti={true}
                                      isClearable={false}
                                      onChange={(e) => {
                                          setSelectedStatus(e);
                                      }}
                                      options={statusOption}
                                      closeMenuOnSelect={false}
                                      components={animatedComponents}
                                  />
                              </div>
                            </Col>
                            <Col xl={3} sm={4}>
                                <div className='mb-2'>
                                    <Label htmlFor="status-field" className="form-label">{props.t("search")}</Label><br/>
                                    <div className="d-flex align-items-center">
                                        <div className="search-box me-2 mb-1 col-10">
                                            <Input
                                                className="custom-border form-control"
                                                value={searchInput}
                                                onChange={(e) => setSearchInput(e.target.value)}
                                            />
                                        </div>
                                        <button
                                          type="button"
                                          className="btn btn-warning mx-1"
                                          onClick={() => {
                                            if (!fp?.current?.flatpickr) return;
                                            fp.current.flatpickr.clear();
                                            setPeriods([]);
                                            setSearchInput('')
                                            setFilterStatus(null)
                                            setFilterTarget(null)
                                            setSelectedTarget(null)
                                            setSearchUserInput('')
                                            setSelectedStatus(null)
                                            loadSales()
                                          }}
                                        >
                                            {props.t("clear")}
                                        </button>
                                    </div>
                                </div>
                            </Col>
                          </Row>
                          <TableContainer
                              columns={columns}
                              data={((saleInvoices && saleInvoices.items) || [])}
                              isGlobalFilter={false}
                              customPageSize={10}
                              divClass="card table-card table-warehouse table-responsive"
                              tableClass="table"
                              theadClass="thead-sec table-light"
                              tdClass="td"
                              tableSize={ saleInvoices && saleInvoices.size }
                              tablePage={ saleInvoices && saleInvoices.page }
                              tableTotal= { saleInvoices && saleInvoices.total_items }
                              tableTotalPage= { saleInvoices && saleInvoices.total_page }
                              onNextPage= {loadSales}
                              onSearch= {loadSales}
                              SearchPlaceholder={props.t("search_product")}
                          />
                        </div>
                    </div>

                    <Modal id='showModal' size='md' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                      <ModalHeader className="bg-light p-3" toggle={toggle}>
                          {props.t("make_payment")}
                      </ModalHeader>
                      <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                      }}>
                          <ModalBody>
                              <Row>
                                  <Col xl={12} sm={12}>
                                    <div className='mb-2'>
                                        <Label htmlFor="paymentMode-field" className="form-label">{props.t("bank_acc")}</Label><br/>
                                        <Select
                                            name='paymentMode'
                                            id='paymentMode-field'
                                            isMulti={false}
                                            isClearable={false}
                                            onBlur={validation.handleBlur}
                                            value={selectedPaymentMPay}
                                            onChange={(e)=> setSelectedPaymentMPay(e)}
                                            options={paymentOption}
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                        />
                                    </div>
                                  </Col>
                                  <Col xl={12} sm={12}>
                                      <div className="mb-3">
                                          <Label
                                              htmlFor="amountReceived-field"
                                              className="form-label"
                                          >
                                              {props.t("Amount")}
                                              {selectedPaymentMPay && selectedPaymentMPay.value == 'PATIENT_CREDIT' ?
                                                <></>
                                                :
                                                <span className='text-danger'>* ({ currentInvoice && currentInvoice.balance })</span>
                                              }
                                          </Label>
                                          <Input
                                              name="amountReceived"
                                              id="amountReceived-field"
                                              className="form-control"
                                              autoComplete="off"
                                              placeholder=".."
                                              type="number"
                                              validate={{
                                                  required: { value: true },
                                              }}
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.amountReceived || ""}
                                              invalid={
                                                  validation.touched.amountReceived && validation.errors.amountReceived ? true : false
                                              }
                                              disabled={selectedPaymentMPay && selectedPaymentMPay.value === 'PATIENT_CREDIT' ? true : false}
                                          />
                                          {validation.touched.amountReceived && validation.errors.amountReceived ? (
                                              <FormFeedback type="invalid">{validation.errors.amountReceived}</FormFeedback>
                                          ) : null}
                                      </div>
                                  </Col>
                              </Row>
                              <div  ref={payRef} className="hiden-print p-5">
                                {payment && payment.id ? 
                                  renderContent()
                                  : null
                                }
                              </div>
                              
                          </ModalBody>

                          <div className='p-3'>
                              <Row className="align-items-center">
                              <div className="col-sm">
                                  <span className='text-danger align-middle'>* : {props.t("required_fields")}</span>
                              </div>
                              <div className="col-sm-auto">
                                  <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm(); setSelectedPaymentMPay(null) }}> {props.t("close")} </button>
                                    <button type="submit" className="btn btn-success" disabled={selectedPaymentMPay && selectedPaymentMPay.value ? false : true} > {props.t("save")} </button>
                                  </div>
                              </div>
                              </Row>
                          </div>
                      </Form>
                  </Modal>


                  <Modal id='paymentReceiptModal' size='md' isOpen={modalView || false} toggle={toggleView} backdrop={'static'} centered>
                    <ModalBody id='invoice-print-div'>
                      <div className='payment-reciept-modal-heading-sec'>
                        <Row className='row g-0'>
                            <div className='col-xl-2 col-lg-2 col-md-2 col-2 pe-0'>
                              <div className='header-logo-sec'>
                                <img src='/assets/images/LOGO.png' alt='veronica logo' />
                              </div>
                            </div>
                            <div className='col-xl-9 col-lg-9 col-md-9 col-9'>
                              <div class="header-heading-sec">
                                <div class="heading-one">
                                  <h2 class="mb-0">{props.t("hospital_name")}</h2>
                                </div>
                                <div class="heading-two">
                                  <h3 class="mb-0">{props.t("hospital_location")}</h3>
                                </div>
                                <div class="heading-three">
                                  <h4 class="mb-0">Tel: +237 6 75 55 38 64</h4>
                                </div>
                              </div>
                            </div>
                        </Row>
                      </div>
                      <Row>
                      </Row>
                        <div  ref={viewRef} className="hiden-print p-1" style={{ width: " 835px", height: "417px" }}>
                          {currentInvoice && currentInvoice.id ?
                            renderContent()
                            : null
                          }
                        </div>
                      <div className="table-area show mt-3" id="home-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{ width: "10%" }}>{props.t("s_no")}</th>
                                        <th style={{ width: "20%" }}>{props.t("item_name")}</th>
                                        <th style={{ width: "10%" }}>{props.t("price_unit")}</th>
                                        <th style={{ width: "10%" }}>{props.t("discount")}</th>
                                        <th style={{ width: "10%" }} colSpan="2">{props.t("total")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { currentInvoice && currentInvoice.saleOrder && currentInvoice.saleOrder.items &&
                                  currentInvoice.saleOrder.items.map((row, index) => (
                                    <tr key={index}>
                                      <td style={{ width: "10%" }}>{index+1}</td>
                                      <td style={{ width: "20%" }}>{row.product && row.product.name}</td>
                                      <td style={{ width: "10%" }}>{row.unitPrice}</td>
                                      <td style={{ width: "10%" }}>{row.discount}</td>
                                      <td style={{ width: "10%" }}>{row.finalPrice}</td>
                                    </tr>
                                  ))}

                                </tbody>
                            </table>
                        </div>


                    </ModalBody >
                        <div className='p-3'>
                              <Row className="align-items-center">
                                <div className="col-sm">
                                </div>
                                <div className="col-sm-auto">
                                    <div className="hstack gap-2 justify-content-end">
                                      <button type="button" className="btn btn-secondary btn-sm" onClick={() => { setModalView(false); setSelectedPaymentMPay(null) }}> {props.t("close")} </button>
                                      <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => {handlePrint()}}>{props.t("print")}</button>
                                    </div>
                                </div>
                              </Row>
                        </div>
                  </Modal>
                  </>
              }
            </>
            : 
            <>
                <SalesCahier 
                  setIsCreate={setIsCreate} 
                  reloadTableData={loadSales} 
                  printSettings={props.printSettings && props.printSettings.printConfigs ? props.printSettings.printConfigs.cashierReceipt: null } 
                />
            </>
        }

        <iframe title='invoice-to-print' id="ifmcontentstoprint" style={{height: 0, width: 0, position: 'absolute'}}></iframe>
    </>
  )
}

export default withRouter(withTranslation()(HomeSalesList))
