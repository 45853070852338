import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import Flatpickr from "react-flatpickr"
import { createSelector } from "reselect"
import moment from 'moment'
import makeAnimated from "react-select/animated"
import Select from "react-select"
import { useReactToPrint } from 'react-to-print';
import TableContainerWarehouse from '../../../components/Common/TableContainerWarehouse'
import logo from "../../../assets/images/HMS-Logo.png"
import { Row, Col, Label, Card, CardBody, CardHeader, CardFooter } from 'reactstrap'
import {
    getBankingAccountsTransaction as onGetBankingAccountsTransaction,
    getBankAccount as onGetBankAccount
} from "../../../slice/thunks"

const BankAccHistory = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const PatientComponentRef = useRef();
    const animatedComponents = makeAnimated()
    const fp = useRef(null);


    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            allBankAccTransactions: setting.allBankAccTransactions,
            bankaccount: setting.bankaccount
        })
    )
    const { allBankAccTransactions, bankaccount } = useSelector(SettingsProperties)
    const [periods, setPeriods] = useState([]);
    

    const loadBankAccount = useCallback(() => {
        dispatch(onGetBankAccount(id))
    })

    const loadBankAccountTransfert = useCallback((pageNumber, pageSize) => {
        let reqObject = { id: id }
        if ((pageNumber !== null) && (pageNumber !== undefined)) reqObject.pageNumber = pageNumber;
        if ((pageSize !== null) && (pageSize !== undefined)) reqObject.pageSize = pageSize;
        if (periods.length > 0) reqObject.startDate = moment(periods[0]).format("YYYY-MM-DD");
        if (periods.length > 0) reqObject.endDate = moment(periods[1]).format("YYYY-MM-DD");
        console.log(reqObject);
        
        dispatch(onGetBankingAccountsTransaction(reqObject))
    })

    useEffect(() => {
        loadBankAccount()
        loadBankAccountTransfert()
    },[dispatch])

    useEffect(() => {
        if (periods.length > 1) {
            loadBankAccountTransfert()
        }
    },[periods])


    const handlePrint = useReactToPrint({
        content: () => PatientComponentRef.current,
        documentTitle: 'Patient Account summary',
        copyStyles: true,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                resolve();
            });
        },
    });


    const columns = useMemo(
        () => [
          {
            header: props.t("date"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <div className='d-flex align-items-center'>
                          <span>
                            {moment(cell.row.original.transactionDate).format('DD / MM/ YYYY')}
                          </span>
                  </div>
            }
          },
          {
            header: props.t("ref"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{cell.row.original.transactionRef}</span>
            }
          },
          {
            header: props.t("description"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{cell.row.original.description}</span>
            }
          },
          {
            header: props.t("deposit"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{cell.row.original.deposit}</span>
            }
          },
          {
            header: props.t("withdraw"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{cell.row.original.withdraw}</span>
            }
          },
          {
            header: props.t("balance"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{cell.row.original.balance}</span>
            }
          },
        ],
        []
    );

  return (
    <div className="patients-list-content">
            <div className="patients-list  px-2 pt-4 pb-5 ">
                <div className="container-fluid">
                    <div className="heading-parent-sec">
                        <div className="heading-child-one-sec">
                            <h6>{props.t("bank_acc_title")}</h6>
                        </div>
                    </div>

                    <div  className="row pb-2">
                        <div ref={PatientComponentRef} className="see-doctor-patient m-2 p-4">
                            <div className='border-bottom pb-2'>
                                <div className='show-print'>
                                    <Row >
                                        <Col xl={3} sm={4}>
                                            <label className="form-label">{props.t("account_name")}: {bankaccount && bankaccount.accountName}</label>
                                        </Col>
                                        <Col xl={3} sm={4}>
                                            <label className="form-label">{props.t("bank_name")}: {bankaccount && bankaccount.bankName}</label>
                                        </Col>
                                        <Col xl={3} sm={4}>
                                            <label className="form-label">
                                                {props.t("balance")}: 
                                                {bankaccount && bankaccount.balance > 0 ? 
                                                    <span className="text-success"> {bankaccount && bankaccount.balance }</span> : 
                                                    <span> {bankaccount && bankaccount.balance }</span>                                                }
                                            </label>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='hiden-print'>
                                    <Row className='d-flex justify-content-between m-5 mt-2 mb-0'>
                                        <Col className ='d-flex align-items-center'>
                                            <img src={logo} className="img-fluid" style={{ height: "150px" }} alt="" />
                                        </Col>
                                        <Col className ='d-flex justify-content-end'>
                                            <div className="row">
                                                <h5 className="text-center text-primary" style={{ color: "#d13e6a", fontWeight:"700", margin: "0", padding: "0" }}><b><u>{props.t("hospital_name")}</u></b></h5>
                                                <p className='text-center'>
                                                    <b>{props.t("hospital_po")}</b><br />
                                                    <b>{props.t("hospital_contact")}</b><br />
                                                    <u className='text-primary'>{props.t("hospital_email")}</u><br />
                                                    <u>{props.t("hospital_website")}</u><br />
                                                    <i>{props.t("founder")+": "+props.t("hospital_founder")}</i>
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="d-flex justify-content-center align-items-center text-center">
                                        <div className='border-dark border-bottom w-80 mb-2'></div>
                                        <h1 className="display-6">{allBankAccTransactions && allBankAccTransactions.patientFullName}</h1>
                                        <div className='border-danger border-bottom w-75'></div>
                                        <p className='pt-2' >{props.t("patient_account_summary_title")}</p>
                                    </Row>
                                    <Row className='p-1'>
                                        <Col xl={3} sm={4}>
                                            <label>{props.t("admission_ref")}: {allBankAccTransactions && allBankAccTransactions.patientRef}</label>
                                        </Col>
                                    </Row>
                                    <Row className='p-1'>
                                        <Col xl={3} sm={4}>
                                            <label>
                                                {props.t("admission_date")}: 
                                                {allBankAccTransactions && allBankAccTransactions.admissionDate ? moment(allBankAccTransactions.admissionDate).format(' DD / MM/ YYYY') : ""}
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className='p-1'>
                                        <Col xl={6} sm={6}>
                                            <label>
                                                {props.t("discharge_date")}: 
                                                {allBankAccTransactions && allBankAccTransactions.dischargeDate ? moment(allBankAccTransactions.dischargeDate).format(' DD / MM/ YYYY') : ""}
                                            </label>
                                        </Col>
                                        <Col xl={6} sm={6}>
                                            {/* <Row className="d-flex justify-content-end align-items-center text-end">
                                                <Col xl={6} sm={6}>
                                                    <label>{props.t("balance")} </label>
                                                </Col>
                                                <Col xl={4} sm={6}>
                                                    <div className='border-primary border p-3'> <h6 className='text-primary'>0 FCFA</h6></div>
                                                </Col>
                                            </Row> */}
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <Card>
                                <CardBody className="row d-flex align-items-center justify-content-between mt-2">
                                    <Row className="second-select pt-1">
                                        <Col xl={3} sm={4} >
                                            <div className='mb-2'>
                                                <label className="form-label" htmlFor="date-range">{props.t("select_date_range")}</label>
                                                <div className='d-flex align-items-center'>
                                                    <Flatpickr
                                                        className='flatpick-custom'
                                                        id="date-range"
                                                        ref={fp}
                                                        placeholder={props.t("select_date_range")}
                                                        defaultValue={periods}
                                                        value={periods.length > 0 ? periods : null}
                                                        options={{
                                                            altInput: true,
                                                            altFormat: "d / m/ Y",
                                                            maxDate: new Date(),
                                                            mode: "range",
                                                        }}
                                                        onChange={(e) => {
                                                            if (e.length > 1) {
                                                                setPeriods(e)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <TableContainerWarehouse
                                        columns={columns}
                                        data={((allBankAccTransactions && allBankAccTransactions.items) || [])}
                                        isGlobalFilter={false}
                                        customPageSize={10}
                                        divClass="card table-card table-warehouse table-responsive"
                                        tableClass="table"
                                        theadClass="thead-sec table-light"
                                        tdClass="td"
                                        tableSize={ allBankAccTransactions && allBankAccTransactions.size }
                                        tablePage={ allBankAccTransactions && allBankAccTransactions.page }
                                        tableTotal= { allBankAccTransactions && allBankAccTransactions.total_items }
                                        tableTotalPage= { allBankAccTransactions && allBankAccTransactions.total_page }
                                        onNextPage= {loadBankAccountTransfert}
                                        onSearch= {loadBankAccountTransfert}
                                        SearchPlaceholder={props.t("search_account")}
                                    />
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default withRouter(withTranslation()(BankAccHistory))
