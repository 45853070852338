
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom"
import { withTranslation } from 'react-i18next'
import * as Yup from "yup"
import { useFormik } from 'formik'
import Select from "react-select"
import makeAnimated from "react-select/animated"
import moment from 'moment'
import avatar from "../../../../assets/images/fa-user.png"
import withRouter from '../../../../components/Common/withRouter'
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import { 
    loadWards as onLoadWards,
    getPatient as onGetPatient,
    getPatientTypes as onGetPatientTypes,
    getBedsByWard as onGetBedsByWard,
    assignBedPatient as onAssignBedPatient,
    admitPatient as onAdmitPatient
} from "../../../../slice/thunks"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import GlobalLoader from '../../../../components/Common/GlobalLoader'

const PatientDetail = (props) => {
    let { id, param } = useParams();
    const dispatch = useDispatch()
    const history = useNavigate()
    const animatedComponents = makeAnimated()
    const selectSettingState = (state) => state.Settings
    const selectLayoutState = (state) => state.Outputs
    const SettingsProperties = createSelector(
        selectSettingState,
        (setting) =>({
          allwards: setting.allwards,
        })
    )
    const OutputsProperties = createSelector(
        selectLayoutState,
        (output) =>({
            patientTypes: output.patientTypes,
            patient: output.patient,
            wardBed: output.wardBed,
            success: output.success,
            assign_bed_patient: output.assign_bed_patient,
            assign_bed_patient_success: output.assign_bed_patient_success,
        })
    )
    const { allwards } = useSelector(SettingsProperties)
    const { patientTypes, patient, wardBed, assign_bed_patient, assign_bed_patient_success, success } = useSelector(OutputsProperties)

    const [currentPatient, setCurrentPatient] = useState(null)
    const [typeOptions, setTypeOptions] = useState([])
    const [saveExit, setSaveExit] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [currentAssignment, setCurrentAssignement] = useState(null)
    const [isSaved, setIsSaved] = useState(false)
    const [wardsOptions, setWardsOptions] = useState([])
    const [selectedWards, setselectedWards] = useState(null)
    const [bedsOptions, setbedsOptions] = useState([])
    const [selectedBed, setselectedBed] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const toggle = useCallback(() => {
        if (modal) {
          setModal(false);
          validation.resetForm();
          setCurrentAssignement(null);
          setselectedWards(null)
          setselectedBed(null)
        } else {
          setModal(true);
        }
    }, [modal])

    const loadWards = useCallback(() => {
        dispatch(onLoadWards())
    })

    const loadWardBeds = useCallback((wardId) => {
        dispatch(onGetBedsByWard({id: wardId, status: 'Free'}))
    })

    const admitPatients = useCallback(() => {
        dispatch(onAdmitPatient({id: currentPatient.id, data: currentPatient}))
    },[dispatch])

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            bedId: (currentAssignment && currentAssignment.bedId) || '',
            discount: (currentAssignment && currentAssignment.discount) || null,
            entryDate: (currentAssignment && currentAssignment.entryDate) || '',
            description: (currentAssignment && currentAssignment.description) || '',
        },
        validationSchema: Yup.object({
            entryDate: Yup.string().required(props.t("you_need_provide_date")),
            // description: Yup.string().required(props.t("you_need_provide_description")),
        }),
        onSubmit: (values) => {
            const assignForm = {
                bedId: selectedBed && selectedBed.value,
                discount: values.discount,
                entryDate: values.entryDate,
                description: values.description,
            }
            const id = patient.patientRecord.id
            dispatch(onAssignBedPatient({id: id, data: assignForm}))
            setIsSaved(true)
        },
    })

    useEffect(() => {
        dispatch(onGetPatient({id: id}))
        dispatch(onGetPatientTypes())
        loadWards()
    },[dispatch])

    useEffect(() =>{
        if (allwards && allwards.length > 0) {
          const wards_list = allwards.map( ward => {
            return { label: ward.wardsName, value: ward.id }
          })
          setWardsOptions(wards_list)
        }
    },[allwards])

    useEffect(() =>{
        if (wardBed && wardBed.length > 0) {
            const wards_bed_list = wardBed.map( bed => {
              return { label: bed.bedNumber+" ("+bed.bedCharge+" F)", value: bed.id }
            })
          setbedsOptions(wards_bed_list)
        }
        else{
            setbedsOptions([{ label: props.t("no_bed_available"), value: 0 }])
            setselectedBed(null)
        }
    },[wardBed])

    useEffect(() => {
        if (selectedWards && selectedWards.value) {
            loadWardBeds(selectedWards.value)
        }
    },[selectedWards])

    useEffect(() =>{
        if (patientTypes && patientTypes.length > 0) {
            const types_list = patientTypes.map( type => {
            return { label: type.typeName, value: type.id }
            })
            setTypeOptions(types_list)
        }
    },[patientTypes])

    useEffect(() => {
        if (patient && patient.id) {
            setCurrentPatient(patient)
            setIsLoading(false)
        }
    },[patient])

    useEffect(() => {
        if (assign_bed_patient_success && isSaved) {
            toggle()
            history(`/admit-patient`)
            setIsSaved(false)
        }
    },[assign_bed_patient, assign_bed_patient_success])

    useEffect(() => {
        if ( saveExit && success) {
            history("/patients")
            setSaveExit(false)
        }
    },[saveExit, success])

    function handleSelectWard(selectedWards) {
        setselectedWards(selectedWards)
    }

    function handleSelectBed(selectedBed) {
        setselectedBed(selectedBed)
    }

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="dashboard">
                        {/* <!-- Start send-Patient-to-Doctor --> */}
                        <div className="patient-details-sec">
                            <div className="container">
                                <div className="heading-sec">
                                    <h6>{props.t("patient_details")}</h6>
                                </div>
                                <div className="patient-details-main-sec pb-3">
                                    <div className="d-flex justify-content-space-between patient-details-child-one">
                                        <Link to={param == 'patient' ? "/patients" : param == 'doctor' ? "/doctor/doctor-panel-see-patient" : ""} >
                                            <div className="patient-details-heading-sec b-0">
                                                <div className="left-arrow">
                                                    <i className="fa-solid fa-chevron-left"></i>
                                                </div>
                                                <div className="heading align-middle">
                                                    <p className="mb-0">{props.t("patient_list")}</p>
                                                </div>
                                            </div>
                                        </Link>
                                        {param == 'patient' ?
                                                <div className="patient-details-btnn-sec">
                                                    <div className="btnn-one">
                                                        <a href="patient-details-print.html">
                                                            <button className="btn">{props.t("print")}&nbsp;<i className="fa-solid fa-print"></i></button>
                                                        </a>
                                                    </div>
                                                    {currentPatient && currentPatient.patientRecord && !currentPatient.patientRecord.bedRecords ?
                                                        <div className="btnn-two">
                                                            <>
                                                                <button className="btn" style={{ width: "auto" }} onClick={() => toggle()}>
                                                                    {props.t("assign_bed")}&nbsp;<i className="fas fa-bed"></i>
                                                                </button>
                                                            </>
                                                        </div>
                                                        :<div className="btnn-two">
                                                            <>
                                                                <button className="btn" style={{ width: "auto" }} onClick={() => admitPatients()}>
                                                                    {props.t("admit_patients")}
                                                                </button>
                                                            </>
                                                        </div>
                                                    }
                                                    <div className="btnn-two">
                                                        <>
                                                            <Link to={"/patients/create/"+(currentPatient !== null ? currentPatient.id : "")+"/patient_registration-content/update"} className="btn">{props.t("edit")}&nbsp;
                                                            <i className="fa-solid fa-pen-to-square"></i></Link>
                                                        </>
                                                    </div>
                                                </div>
                                            :<></>
                                        }
                                    </div>

                                    <div className="patient-details-child-two">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="upload-pic-sec py-3">
                                                            <div className="profile-pic">
                                                                <img src={avatar} id="output" width="200"></img>
                                                            </div>
                                                        </div>
                                                        {/* <div className="img-format-sec text-center py-3">
                                                            <p className="mb-0 para-one">{props.t("allow_format")}</p>
                                                            <p className="mb-0 para-two">{props.t("jpg-png")}</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                                <div className="patient-details-child-two-two">
                                                    <div className="para-patient-id mb-3">
                                                        <p className="para-one mb-0">{props.t("patient_id")} - 
                                                        <span className=""> {currentPatient && currentPatient.patientRef}</span></p>
                                                    </div>
                                                    <div className="para-status mb-3">
                                                        <p className="para-two mb-0">{props.t("patient_status")}</p>
                                                        <p className="para-three mb-0">{ currentPatient && currentPatient.patientType && currentPatient.patientType.typeName }</p>
                                                    </div>
                                                    <div className="para-profession mb-3">
                                                        <p className="para-two mb-0">{props.t("professional")}</p>
                                                        <p className="para-four mb-0">{ currentPatient && currentPatient.profession }</p>
                                                    </div>
                                                    <div className="para-gender mb-3">
                                                        <p className="para-two mb-0">{props.t("gender")}</p>
                                                        <p className="para-four mb-0">
                                                            {currentPatient !== null ?  (currentPatient && currentPatient.gender === "M" ? props.t("male") : currentPatient.gender === "F" ?  props.t("female") : props.t("other")) : "" }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                                <div className="patient-details-child-two-two">
                                                    <div className="para-patient-id mb-3">
                                                        <p className="para-one mb-0">{props.t("patient_name")} - 
                                                        <span className=""> { currentPatient && currentPatient.firstName } { currentPatient && currentPatient.lastName }</span></p>
                                                    </div>
                                                    <div className="para-status mb-3">
                                                        <p className="para-two mb-0">{props.t("dob")}</p>
                                                        <p className="para-four mb-0">{ currentPatient && moment(currentPatient.dob).format('DD / MM/ YYYY')}</p>
                                                    </div>
                                                    <div className="para-profession mb-3">
                                                        <p className="para-two mb-0">{props.t("phone_number")}</p>
                                                        <p className="para-four mb-0">{ currentPatient && currentPatient.phone }</p>
                                                    </div>
                                                    <div className="para-gender mb-3">
                                                        <p className="para-two mb-0">{props.t("address")}</p>
                                                        <p className="para-four mb-0">
                                                            { currentPatient && currentPatient.address && currentPatient.address.address ? currentPatient.address.address+ 
                                                            (currentPatient && currentPatient.address && currentPatient.address.city ? ", "+currentPatient.address.city : "" )+
                                                            (currentPatient && currentPatient.address && currentPatient.address.region ? ", "+currentPatient.address.region : "" ) : ""}.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="patient-details-child-three">
                                        <div className="patient-vital-heading">
                                            {currentPatient && currentPatient.patientVitals && currentPatient.patientVitals.length > 0 ?
                                                <p>{props.t("patient_vitals")}&nbsp;&nbsp;
                                                    ({moment(currentPatient.patientVitals[0].createdAt).format('DD / MM/ YYYY')})
                                                </p>
                                                : ""
                                            }
                                        </div>
                                        {currentPatient && currentPatient.patientVitals && currentPatient.patientVitals.length > 0 ?
                                            <>
                                                <div className="row">
                                                    <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                        <div className="patient-vital-para-sec">
                                                            <p className="patient-vital-para-one">{props.t("height")}</p>
                                                            <p className="patient-vital-para-two">{currentPatient.patientVitals[0].height}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                        <div className="patient-vital-para-sec">
                                                            <p className="patient-vital-para-one">{props.t("weight")}</p>
                                                            <p className="patient-vital-para-two">{currentPatient.patientVitals[0].weight}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                        <div className="patient-vital-para-sec">
                                                            <p className="patient-vital-para-one">{props.t("bp")}</p>
                                                            <p className="patient-vital-para-two">{currentPatient.patientVitals[0].bp}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                        <div className="patient-vital-para-sec">
                                                            <p className="patient-vital-para-one">{props.t("temparature")}</p>
                                                            <p className="patient-vital-para-two">{currentPatient.patientVitals[0].temperature}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                                        <div className="patient-vital-para-sec">
                                                            <p className="patient-vital-para-one">{props.t("spo")} (%SPO²)</p>
                                                            <p className="patient-vital-para-two">{currentPatient.patientVitals[0].temperature}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :<>
                                                <h4></h4>
                                            </>
                                        }
                                    </div>

                                    <div className="patient-details-child-three">
                                        <div className="patient-vital-heading">
                                            <p>{props.t("personToContact")}</p>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="patient-vital-para-sec">
                                                    <p className="patient-vital-para-one">{props.t("fullName")}</p>
                                                    <p className="patient-vital-para-two">{currentPatient.contactPerson.fullName}</p>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="patient-vital-para-sec">
                                                    <p className="patient-vital-para-one">{props.t("email")}</p>
                                                    <p className="patient-vital-para-two">{currentPatient.contactPerson.email}</p>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="patient-vital-para-sec">
                                                    <p className="patient-vital-para-one">{props.t("relationship")}</p>
                                                    <p className="patient-vital-para-two">{currentPatient.contactPerson.relationship}</p>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="patient-vital-para-sec">
                                                    <p className="patient-vital-para-one">{props.t("phoneNumber")}</p>
                                                    <p className="patient-vital-para-two">{currentPatient.contactPerson.phoneNumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Modal id="showModal" size='lg' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                                <ModalHeader className='bg-light p-3' toggle={toggle}>
                                    {props.t("assign_bed_to_patient")}
                                </ModalHeader>
                                <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}>
                                    <ModalBody>
                                        <Row>
                                            <Col xl={12} sm={12}>
                                                <div className='mb-3'>
                                                    <Label
                                                        htmlFor="wardId-field"
                                                        className="form-label"
                                                    >
                                                        {props.t("wardsName")}<span className='text-danger'>*</span>
                                                    </Label>
                                                    <Select
                                                        name='wardId'
                                                        id='wardId-field'
                                                        value={selectedWards}
                                                        isMulti={false}
                                                        isClearable={false}
                                                        autoComplete="off" 
                                                        onBlur={validation.handleBlur}
                                                        onChange={(e) => {
                                                            handleSelectWard(e);
                                                        }}
                                                        options={wardsOptions}
                                                        closeMenuOnSelect={true}
                                                        components={animatedComponents}
                                                    />
                                                </div>
                                            </Col>
                                            {selectedWards && selectedWards.value ?
                                                <Col xl={12} sm={12}>
                                                    <div className='mb-3'>
                                                        <Label
                                                            htmlFor="bedId-field"
                                                            className="form-label"
                                                        >
                                                            {props.t("available_beds")}<span className='text-danger'>*</span>
                                                        </Label>
                                                        <Select
                                                            name='bedId'
                                                            id='bedId-field'
                                                            value={selectedBed}
                                                            isMulti={false}
                                                            isClearable={false}
                                                            autoComplete="off" 
                                                            onBlur={validation.handleBlur}
                                                            onChange={(e) => {
                                                                handleSelectBed(e);
                                                            }}
                                                            options={bedsOptions}
                                                            closeMenuOnSelect={true}
                                                            components={animatedComponents}
                                                        />
                                                    </div>
                                                </Col>
                                                :<></>
                                            }
                                            {selectedBed && selectedBed.value ?
                                                <>
                                                    <Col xl={6} sm={12}>
                                                        <div className="mb-3">
                                                        <Label
                                                            htmlFor="discount-field"
                                                            className="form-label"
                                                        >
                                                            {props.t("discount")} (%)
                                                        </Label>
                                                        <Input
                                                            name="discount"
                                                            id="discount-field"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            placeholder={props.t("discount")}
                                                            type="number"
                                                            validate={{
                                                            required: { value: true },
                                                            }}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.discount || ""}
                                                            invalid={
                                                            validation.touched.discount && validation.errors.discount ? true : false
                                                            }
                                                        />
                                                        {validation.touched.discount && validation.errors.discount ? (
                                                            <FormFeedback type="invalid">{validation.errors.discount}</FormFeedback>
                                                        ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col xl={6} sm={12}>
                                                        <div className='mb-2 mt-1'>
                                                            <Label htmlFor="entryDate-field" className="form-label">
                                                                {props.t("entry_date")}<span className='text-danger'>*</span>
                                                            </Label><br/>
                                                            <Input 
                                                                name="entryDate" id='entryDate-field' className="form-control" type="date"
                                                                validate={{ required: { value: true } }} max={getTodayDate()} onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur} value={validation.values.entryDate || ""}
                                                                autoComplete="off"
                                                                invalid={
                                                                    validation.touched.entryDate && validation.errors.entryDate ? true : false
                                                                } 
                                                            />
                                                            {validation.touched.entryDate && validation.errors.entryDate ? (
                                                                <FormFeedback type="invalid">{validation.errors.entryDate}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </>
                                                :<></>
                                            }
                                            {selectedBed && selectedBed.value ?
                                                <Col xl={12} sm={12}>
                                                    <div>
                                                        <Label htmlFor="description-field" className="form-label">
                                                            {props.t("description")}
                                                        {validation.values.discount >0 ? <span className='text-danger'>*</span> : <></>}
                                                        </Label>
                                                        <textarea 
                                                            name="description"
                                                            className="form-control" 
                                                            id="description-field" 
                                                            placeholder={props.t("enter_description")}
                                                            rows={3}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.description || ""}
                                                            invalid={
                                                                validation.touched.description && validation.errors.description ? true : false
                                                            }
                                                        >
                                                        </textarea>
                                                        {validation.touched.description && validation.errors.description ? (
                                                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                :<></>
                                            }
                                        </Row>
                                    </ModalBody>
                                    <div className='p-3'>
                                        <Row className="align-items-center">
                                            <div className="col-sm">
                                            <span className='text-danger align-middle'>* : {props.t("required_fields")}</span>
                                            </div>
                                            <div className="col-sm-auto">
                                            <div className="hstack gap-2 justify-content-end">
                                                <button type="button" className="btn btn-secondary" onClick={() => { toggle() }}> {props.t("close")} </button>
                                                <button type="submit" className="btn btn-success" disabled= {selectedBed !== null && selectedWards !== null && ((validation.values.discount >0 && validation.values.description !== '') || validation.values.discount < 1) ? false: true}> {!!isEdit ? props.t("update") : props.t("save")} </button>
                                            </div>
                                            </div>
                                        </Row>
                                    </div>

                                </Form>

                            </Modal>

                        </div>
                        {/* <!-- End send-Patient-to-Doctor --> */}
                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PatientDetail))