import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import {
    getCashDeskJournal as onGetCashDeskJournal,
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"
import TableContainerWarehouse from "../../../components/Common/TableContainerWarehouse"

const CashDeskJournal = (props) => {
    const dispatch = useDispatch();
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
          cashDeskJournal: setting.cashDeskJournal,
        })
    );
    const { cashDeskJournal } = useSelector(SettingsProperties);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadJournal()
    },[dispatch])

    useEffect(() => {
        if (cashDeskJournal && cashDeskJournal.page >= 0) {
            setIsLoading(false)
        }
    },[cashDeskJournal])

    const loadJournal = useCallback((pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
            dispatch(onGetCashDeskJournal({page: pageNumber, size: pageSize}))
        } else {
            dispatch(onGetCashDeskJournal({ page: 1, size: 10 }))
        }
    })

    function capitalizeName(name) {
        if (!name) return '';
        return name
            .split(' ') 
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
            .join(' '); 
    }

    const columns = useMemo(
        () => [
          {
            header: props.t("cash_desk"),
            accessorKey: "caskDeskName",
            style: "cat",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.caskDeskName}</span>
            }
          },
          {
            header: props.t("opened_at"),
            style: "cat",
            enableColumnFilter: false,
            cell: (cell) => {
                return <div className='d-flex align-items-center'>
                        <span>
                            {moment(cell.row.original.openedAt).format('DD / MM/ YYYY')}
                        </span>
                    </div>
            }
          },
          {
            header: props.t("start_amount"),
            style: "type",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.startAmount}</span>
            }
          },
          {
            header: props.t("closed_at"),
            style: "type",
            enableColumnFilter: false,
            cell: (cell) => {
                return <div className='d-flex align-items-center'>
                        <span>
                            {moment(cell.row.original.closedAt).format('DD / MM/ YYYY')}
                        </span>
                    </div>
            }
          },
          {
            header: props.t("end_amount"),
            style: "type",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.endAmount}</span>
            }
          },
        ],
        [cashDeskJournal]
    );

  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="patients-list-content">
                    <div className="patients-list px-2 pb-5 ">
                        <div className="container-fluid ">
                            <div className="heading-parent-sec ">
                                <div className="heading-child-one-sec pt-4">
                                    <h6>{props.t("cashdesk_journal")}</h6>
                                </div>
                                <div className="heading-child-two-sec pt-4">
                                </div>
                            </div>
                            <div className="card border-0 row p-2">
                                <div className="row">
                                        <TableContainerWarehouse
                                            columns={columns}
                                            data={((cashDeskJournal && cashDeskJournal.items) || [])}
                                            isGlobalFilter={false}
                                            customPageSize={10}
                                            divClass="card table-card table-warehouse table-responsive"
                                            tableClass="table"
                                            theadClass="thead-sec table-light"
                                            tdClass="td"
                                            tableSize={ cashDeskJournal && cashDeskJournal.size }
                                            tablePage={ cashDeskJournal && cashDeskJournal.page }
                                            tableTotal= { cashDeskJournal && cashDeskJournal.total_items }
                                            tableTotalPage= { cashDeskJournal && cashDeskJournal.total_page }
                                            onNextPage= {loadJournal}
                                            onSearch= {loadJournal}
                                            SearchPlaceholder={props.t("search_cash_rop")}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(CashDeskJournal))
