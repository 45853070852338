import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../components/Common/withRouter'
import AllPatients from './AllPatients'
import { useNavigate, useParams } from "react-router-dom"
import MainAdmitPatient from './MainAdmitPatient'
import MainConsultList from './MainConsultList'
import MainAppointment from './MainAppointment'

const Patients = (props) => {

    const history = useNavigate()
    const { param } = useParams()
    const permission = JSON.parse(localStorage.getItem("perm"))
    const [activeTab, setActiveTab] = useState('all_patients-content')
    const handleTabChange = (target) => {
        setActiveTab(target);
    }

    useEffect(() => {
        if(param) {
            handleTabChange(param)
        }
    },[param])

  return (
    <div className="patients-list-content">
        {/* <!-- Output patients Start --> */}
        <div className="patients-list py-4 px-2">
            <div className="container-fluide px-5">
                <div className="row list-patients d-flex align-items-center justify-content-between">
                    <div className="col-lg-4">
                        <h6>{props.t("patient_list")}</h6>
                    </div>

                    <div className="col-lg-3 text-align-center">
                        {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("ADD_PATIENTS")) ?
                            <a onClick={() => history("/patients/create")}>
                                <button className="btn btn-one" type="button"> <i className="fas fa-user-md me-1"></i>{props.t("create_new_patient")}</button>
                            </a>
                            :<></>
                        }
                    </div>
                </div>
                {/* <!-- Patient Tabs and tables --> */}
                <div className="row py-2">
                    {/* <!-- tabs links --> */}
                    <ul className="nav nav-tabs nav-underline ps-2  pe-2 " id="myTab" role="tablist">
                        <li className="nav-item" role="presentation" >
                            <button className={ activeTab === "all_patients-content" ? "nav-link active" : "nav-link" } id="home-tab" data-bs-toggle="tab"
                                data-bs-target="#all_patients" type="button" role="tab" aria-controls="home"
                                aria-selected="true" onClick={() => {handleTabChange("all_patients-content")}}
                            >
                                <i className="fa fa-user ms-1 me-2" aria-hidden="true"></i>
                                {props.t("all_patients")}
                            </button>
                        </li>
                        <li className="nav-item px-2" role="presentation">
                            <button className={activeTab === "admit_patient-content" ? "nav-link active" : "nav-link"} id="admit-patient-tab" data-bs-toggle="tab"
                                data-bs-target="#admit-patient" type="button" role="tab"
                                aria-controls="admit-patient" aria-selected="false" onClick={() => {handleTabChange("admit_patient-content")}}>
                                <i className="fa fa-user me-2" aria-hidden="true"></i>
                                {props.t("admited_patients")}
                            </button>
                        </li>
                        <li className="nav-item px-2" role="presentation">
                            <button className={activeTab === "consultation_list-content" ? "nav-link active" : "nav-link"} id="consultation-list-tab" data-bs-toggle="tab"
                                data-bs-target="#consultation-list" type="button" role="tab"
                                aria-controls="consultation-list" aria-selected="false" onClick={() => {handleTabChange("consultation_list-content")}}>
                                <i className="fa fa-user me-2" aria-hidden="true"></i>
                                {props.t("consultation_list")}
                            </button>
                        </li>
                        <li className="nav-item px-2" role="presentation">
                            <button className={activeTab === "patient_appointment-content" ? "nav-link active" : "nav-link"} id="patient-appointment-tab" data-bs-toggle="tab"
                                data-bs-target="#patient-appointment" type="button" role="tab"
                                aria-controls="patient-appointment" aria-selected="false" onClick={() => {handleTabChange("patient_appointment-content")}}>
                                <i className="fa fa-user me-2" aria-hidden="true"></i>
                                {props.t("patient_appointment")}
                            </button>
                        </li>
                    </ul>

                    {/* <!-- TABS Content --> */}
                    <div className="tab-content" id="myTabContent">

                        {/* <!-- TABLE 1 : All patients--> */}
                        <div className={activeTab === "all_patients-content" ? "tab-pane fade show active card border-0": "tab-pane fade"} id="all_patients" role="tabpanel" aria-labelledby="home-tab">
                            <div className='card-header'>
                                <AllPatients className="px-0"  isActive={activeTab === 'all_patients-content'}/>
                            </div>
                        </div>

                        {/* TABLE: ADMIT Patient */}
                        <div className={activeTab === "admit_patient-content" ? "tab-pane fade show active card border-0": "tab-pane fade"} id="admit-patient" role="tabpanel" aria-labelledby="admit-patient-tab">
                            <div className="admit-patient p-0 m-0 " id="table5">
                                <div className="container-fluide">
                                    <div className="row ">
                                        <div className="table-responsive">
                                            <MainAdmitPatient isActive={activeTab === 'admit_patient-content'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* TABLE 6 : Consultation list */}
                        <div className={activeTab === "consultation_list-content" ? "tab-pane fade show active card border-0": "tab-pane fade"} id="consultation-list" role="tabpanel" aria-labelledby="consultation-list-tab">
                            <div className="consultation-list p-0 m-0 " id="table5">
                                <div className="container-fluide">
                                    <div className="row ">
                                        <div className="table-responsive">
                                            <MainConsultList isActive={activeTab === 'consultation_list-content'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                            {/* TABLE 5 : Patient Appointment */}
                        <div className={activeTab === "patient_appointment-content" ? "tab-pane fade show active card border-0": "tab-pane fade"} id="patient-appointment" role="tabpanel" aria-labelledby="patient-appointment-tab">
                            {/* TABLE 5 : Patient Appointment */}
                            <div className="patient-vitals p-0 m-0 " id="table4">
                                <div className="container-fluide">
                                    <div className="row ">
                                        <div className="card table-card table-responsive">
                                            <MainAppointment isActive={activeTab === 'patient_appointment-content'}/>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Record Appointment --> */}
                        <div className="tab-pane fade" id="record_appointments" role="tabpanel" aria-labelledby="record-appointment-tab">
                            {/* <!--  record appointment patients--> */}
                            <div className="record-appointment-tab-content show" id="record_appointments-content">
                                <div className="container-fluide">
                                    <div className="row ">
                                        <div className="card table-card table-responsive">
                                            <table className="table ">

                                                <thead>
                                                    <tr>
                                                        <th>S.No. </th>
                                                        <th>Patients Name</th>

                                                        <th>Patients Code</th>
                                                        {/* <!-- <th>Mobile No.</th> --> */}
                                                        <th>Doctor Consultant</th>
                                                        <th>Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>01</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/user-avatar.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Leslie Alexander
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        {/* <!-- <td>+918800737373</td> --> */}
                                                        <td className="doctor-conunltant-btn">
                                                            Dr. Jacob Jones
                                                            <i className="fa-solid fa-chevron-down"></i>
                                                        </td>
                                                        <td>10/10/2020</td>
                                                        <td className="action">
                                                            <a href="opd-patient-details.html">
                                                                <i className="fa fa-eye"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>02</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Ronald Richards
                                                            </div>

                                                        </td>

                                                        <td>#025833</td>
                                                        {/* <!-- <td>+918800737373</td> --> */}
                                                        <td className="doctor-conunltant-btn">
                                                            <a>
                                                                Dr. Theresa Web
                                                                <i className="fa-solid fa-chevron-down"></i>
                                                            </a>
                                                        </td>
                                                        <td>10/12/2020</td>
                                                        <td className="action">
                                                            <a href="opd-patient-details.html">
                                                                <i className="fa fa-eye"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>03</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Jane Cooper
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        {/* <!-- <td>+918800737373</td> --> */}
                                                        <td className="doctor-conunltant-btn">
                                                            <a>
                                                                Dr. Jacob Jones
                                                                <i className="fa-solid fa-chevron-down"></i>
                                                            </a>
                                                        </td>
                                                        <td>10/13/2020</td>
                                                        <td className="action">
                                                            <a href="opd-patient-details.html">
                                                                <i className="fa fa-eye"></i>

                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>04</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Robert Fox
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        {/* <!-- <td>+918800737373</td> --> */}
                                                        <td className="doctor-conunltant-btn">
                                                            <a>
                                                                Dr. Arlene McCo
                                                                <i className="fa-solid fa-chevron-down"></i>
                                                            </a>
                                                        </td>
                                                        <td>10/14/2020</td>
                                                        <td className="action">
                                                            <a href="opd-patient-details.html">
                                                                <i className="fa fa-eye"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>05</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center ">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2 " />
                                                                Jenny Wilson
                                                            </div>
                                                        </td>
                                                        <td>#098835</td>
                                                        {/* <!-- <td>+918800737373</td> --> */}
                                                        <td className="doctor-conunltant-btn">
                                                            <a>
                                                                Dr. Esther
                                                                <i className="fa-solid fa-chevron-down"></i>
                                                            </a>
                                                        </td>
                                                        <td>10/15/2020</td>
                                                        <td className="action">
                                                            <a href="opd-patient-details.html">
                                                                <i className="fa fa-eye"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>06</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/user-avatar.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Marshall Cook
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        {/* <!-- <td>+918800737373</td> --> */}
                                                        <td className="doctor-conunltant-btn">
                                                            Dr. Jacob Jones
                                                            <i className="fa-solid fa-chevron-down"></i>
                                                        </td>
                                                        <td>10/16/2020</td>
                                                        <td className="action">
                                                            <a href="opd-patient-details.html">
                                                                <i className="fa fa-eye"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>07</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/user-avatar.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Stephanie Cook
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        {/* <!-- <td>+918800737373</td> --> */}
                                                        <td className="doctor-conunltant-btn">
                                                            Dr. Theresa Web
                                                            <i className="fa-solid fa-chevron-down"></i>
                                                        </td>
                                                        <td>10/17/2020</td>
                                                        <td className="action">
                                                            <a href="opd-patient-details.html">
                                                                <i className="fa fa-eye"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>08</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/user-avatar.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Marion James
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        {/* <!-- <td>+918800737373</td> --> */}
                                                        <td className="doctor-conunltant-btn">
                                                            Dr. Esther How
                                                            <i className="fa-solid fa-chevron-down"></i>
                                                        </td>
                                                        <td>10/18/2020</td>
                                                        <td className="action">
                                                            <a href="opd-patient-details.html">
                                                                <i className="fa fa-eye"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>09</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/user-avatar.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Teresa Holland
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        {/* <!-- <td>+918800737373</td> --> */}
                                                        <td className="doctor-conunltant-btn">
                                                            Dr. Arlene Mc
                                                            <i className="fa-solid fa-chevron-down"></i>
                                                        </td>
                                                        <td>10/19/2020</td>
                                                        <td className="action">
                                                            <a href="opd-patient-details.html">
                                                                <i className="fa fa-eye"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>10</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/user-avatar.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Zachary Marshall
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        {/* <!-- <td>+918800737373</td> --> */}
                                                        <td className="doctor-conunltant-btn">
                                                            Dr. Arlene Coy
                                                            <i className="fa-solid fa-chevron-down"></i>
                                                        </td>
                                                        <td>10/20/2020</td>
                                                        <td className="action">
                                                            <a href="opd-patient-details.html">
                                                                <i className="fa fa-eye"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <nav aria-label="Page navigation">
                                                <ul className="pagination ms-3">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true"><i
                                                                    className="fa-solid fa-angle-left"></i></span>
                                                        </a>
                                                    </li>
                                                    <li className="page-item active"><a className="page-link" href="#">1</a>
                                                    </li>
                                                    <li className="page-item "><a className="page-link" href="#">2</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                    <li className="page-item">
                                                        <a className="page-link " href="#" aria-label="Next">
                                                            <span aria-hidden="true"><i
                                                                    className="fa-solid fa-angle-right"></i></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!--  See Doctors section --> */}
                            <div className="see-doctor-sec hide" id="change-doctor-section">

                                {/* <!-- main section --> */}
                                <div className="see-new ">
                                    <div className="container-fluide">
                                        <div className="name ">
                                            <div className="patient-name">
                                                <h6 className="mb-3" id="change-doctor-btn">
                                                    <i className="fa-solid fa-chevron-left"></i>
                                                    Change Doctor
                                                </h6>
                                                <div className="d-flex align-items-center flex-wrap mb-4">
                                                    <h6 className="me-4">Patient ID <span>- 25865</span></h6>
                                                    <h6 className="me-4">Patient Name <span>- John Deo</span></h6>
                                                    <h6>
                                                        Appointment Date
                                                        <span id="appointment-date">
                                                            - 22/04/24 &nbsp;
                                                            <i className="fa-solid fa-calendar-days"
                                                                id="calendar-icon"></i>
                                                        </span>
                                                    </h6>
                                                    <div className="search-icon ms-auto me-2">
                                                        <i className="fa-solid fa-magnifying-glass"></i>
                                                        <input type="text" className="search px-2 mb-2"
                                                            placeholder="Search doctors"
                                                            style={{ fontSize: "13px", color:"rgba(160, 164, 168, 1)" }} />
                                                    </div>
                                                </div>

                                                <input type="text" id="datepicker" style={{ display:"none" }} />
                                                <div className="doctors mb-4">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor.png"
                                                                                        alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head-1 d-flex justify-content-between">
                                                                                    <h6>Dr. David Patel </h6>
                                                                                    <input type="radio"
                                                                                        name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Cardiologist</h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>150</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor.png"
                                                                                        alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head-1 d-flex justify-content-between">
                                                                                    <h6>Dr. David Patel </h6><input
                                                                                        type="radio" name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Cardiologist</h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>120</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor.png"
                                                                                        alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head-1 d-flex justify-content-between">
                                                                                    <h6>Dr. David Patel </h6> <input
                                                                                        type="radio" name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Cardiologist</h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>140</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="doctors mb-4">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor-one.png"
                                                                                        alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head-2 d-flex justify-content-between">
                                                                                    <h6>Dr. Jessica Turner </h6>
                                                                                    <input type="radio"
                                                                                        name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Gynecologist</h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>160</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor-one.png"
                                                                                        alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head-2 d-flex justify-content-between">
                                                                                    <h6>Dr. Jessica Turner </h6>
                                                                                    <input type="radio"
                                                                                        name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Gynecologist</h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>140</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor-one.png"
                                                                                        alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head-2 d-flex justify-content-between">
                                                                                    <h6>Dr. Jessica Turner </h6>
                                                                                    <input type="radio"
                                                                                        name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Gynecologist</h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>130</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="doctors mb-5">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor-two.png"
                                                                                        alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head d-flex justify-content-between">
                                                                                    <h6>Dr. Michael Johnson </h6>
                                                                                    <input type="radio"
                                                                                        name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Orthopedic Surgery
                                                                                </h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>50</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor-two.png"
                                                                                        alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head d-flex justify-content-between">
                                                                                    <h6>Dr. Michael Johnson </h6>
                                                                                    <input type="radio"
                                                                                        name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Orthopedic Surgery
                                                                                </h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>60</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor-two.png"
                                                                                        alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head d-flex justify-content-between">
                                                                                    <h6>Dr. Michael Johnson </h6>
                                                                                    <input type="radio"
                                                                                        name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Orthopedic Surgery
                                                                                </h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>70</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-grid gap-2 d-md-block mx-auto">

                                                    <button className="btn-one " id="save-appt-doctor-btn"
                                                        type="button">Save Appointment</button>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                        {/* TABLE 3 : Patient Vitals  */}
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            {/* TABLE 3  : Patient Vitals  */}
                            <div className="patient-vitals p-0 m-0 " id="table2">
                                <div className="container-fluide">
                                    <div className="row ">
                                        <div className="card table-card table-responsive">
                                            <table className="table ">
                                                {/*  <h4 className="py-3">Today  Activity</h4> */}
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Patients Name</th>
                                                        <th>Patients Code</th>
                                                        <th>Mobile No.</th>
                                                        <th>Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="output-sno">
                                                    <tr>
                                                        <td>01</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/user-avatar.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Leslie Alexander
                                                            </div>
                                                        </td>
                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td>10/10/2020</td>
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="opd-patient-details.html"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="opd-take-vitals.html"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>02</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Ronald Richards
                                                            </div>
                                                        </td>
                                                        <td>#025833</td>
                                                        <td>+251-1234568</td>
                                                        <td>10/12/2020</td>
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="opd-patient-details.html"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="opd-take-vitals.html"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>03</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Jane Cooper
                                                            </div>
                                                        </td>
                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td>10/13/2020</td>
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="opd-patient-details.html"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="opd-take-vitals.html"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>04</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Robert Fox
                                                            </div>
                                                        </td>
                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td>10/14/2020</td>
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="opd-patient-details.html"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="opd-take-vitals.html"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>05</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Jenny Wilson
                                                            </div>
                                                        </td>
                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td>10/15/2020</td>
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="opd-patient-details.html"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="opd-take-vitals.html"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>06</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Marshall Cook
                                                            </div>
                                                        </td>
                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td>10/17/2020</td>
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="opd-patient-details.html"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="opd-take-vitals.html"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>07</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Stephanie Cook
                                                            </div>
                                                        </td>
                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td>10/17/2020</td>
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="opd-patient-details.html"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="opd-take-vitals.html"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>08</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Marion James
                                                            </div>
                                                        </td>
                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td>10/18/2020</td>
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="opd-patient-details.html"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="opd-take-vitals.html"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>09</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Teresa Holland
                                                            </div>
                                                        </td>
                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td>10/19/2020</td>
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="opd-patient-details.html"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="opd-take-vitals.html"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>10</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Zachary Marshall
                                                            </div>
                                                        </td>
                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td>10/20/2020</td>
                                                        <td>
                                                            <div className="eye-pen">
                                                                <a href="opd-patient-details.html"><i
                                                                        className="fa-solid fa-eye"></i></a>
                                                                <a href="opd-take-vitals.html"><i
                                                                        className="fa-solid fa-pencil"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {/* Add more rows with the same structure htmlFor each patient */}
                                                </tbody>
                                            </table>

                                            <nav aria-label="Page navigation">
                                                <ul className="pagination ms-3">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true"><i
                                                                    className="fa-solid fa-angle-left"></i></span>
                                                        </a>
                                                    </li>
                                                    <li className="page-item active"><a className="page-link" href="#">1</a>
                                                    </li>
                                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                    <li className="page-item">
                                                        <a className="page-link " href="#" aria-label="Next">
                                                            <span aria-hidden="true"><i
                                                                    className="fa-solid fa-angle-right"></i></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* TABLE 4 : Send to Doctor */}
                        <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            {/* TABLE 4 : Send to Doctor */}
                            <div className="send-a-doctor show p-0 m-0" id="send-to-doctor-table">
                                <div className="container-fluide">
                                    <div className="row ">
                                        <div className="card table-card table-responsive">
                                            <table className="table ">
                                                <thead className="thead-sec">
                                                    <tr>
                                                        <th>S.No. </th>
                                                        <th>Patients Name</th>

                                                        <th>Patients Code</th>
                                                        <th>Mobile No.</th>

                                                        <th>Sent Type</th>

                                                    </tr>
                                                </thead>
                                                <tbody className="output-sno">
                                                    <tr>
                                                        <td>01</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/user-avatar.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Leslie Alexander
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td className="selects d-flex">
                                                            {/* <!-- <div className="dropdown">
                                                                <button className="btn btn-secondary" type="button"
                                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                                    Select Type<span><i
                                                                            className="fa-solid fa-angle-down"></i></span>
                                                                </button>
                                                                
                                                                <ul className="dropdown-menu">
                                                                    <li><a className="dropdown-item"
                                                                            href="#">Consultations</a></li>
                                                                    <li><a className="dropdown-item" href="#">After Lab
                                                                            Test</a></li>
                                                                </ul>
                                                            </div> --> */}
                                                            <div className="btn-2">
                                                                <select className="form-select"
                                                                    aria-label="Default select example">
                                                                    <option selected>Select Type</option>
                                                                    <option value="1">Consultations</option>
                                                                    <option value="2">After Lab Test</option>
                                                                </select>
                                                            </div>
                                                            <div className="send-btn">
                                                                <button><span><i className="fa-solid fa-user-doctor"></i></span>Send</button>
                                                                {/* </a> */}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>02</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Ronald Richards
                                                            </div>

                                                        </td>

                                                        <td>#025833</td>
                                                        <td>+251-1234568</td>
                                                        <td className="selects d-flex">
                                                            {/* <!-- <div className="dropdown">
                                                                <button className="btn btn-secondary" type="button"
                                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                                    Select Type<span><i
                                                                            className="fa-solid fa-angle-down"></i></span>
                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                    <li><a className="dropdown-item"
                                                                            href="#">Consultations</a></li>
                                                                    <li><a className="dropdown-item" href="#">After Lab
                                                                            Test</a></li>
                                                                </ul>
                                                            </div> --> */}
                                                            <div className="btn-2">
                                                                <select className="form-select"
                                                                    aria-label="Default select example">
                                                                    <option selected>Select Type</option>
                                                                    <option value="1">Consultations</option>
                                                                    <option value="2">After Lab Test</option>
                                                                </select>
                                                            </div>

                                                            <div className="send-btn">
                                                                <button><span><i
                                                                            className="fa-solid fa-user-doctor"></i></span>Send</button>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>03</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Jane Cooper
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td className="selects d-flex">
                                                            {/* <!-- <div className="dropdown">
                                                                <button className="btn btn-secondary" Type="button"
                                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                                    Select Type<span><i
                                                                            className="fa-solid fa-angle-down"></i></span>
                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                    <li><a className="dropdown-item"
                                                                            href="#">Consultations</a></li>
                                                                    <li><a className="dropdown-item" href="#">After Lab
                                                                            Test</a></li>
                                                                </ul>
                                                            </div> --> */}
                                                            <div className="btn-2">
                                                                <select className="form-select"
                                                                    aria-label="Default select example">
                                                                    <option selected>Select Type</option>
                                                                    <option value="1">Consultations</option>
                                                                    <option value="2">After Lab Test</option>
                                                                </select>
                                                            </div>

                                                            <div className="send-btn">
                                                                <button><span><i
                                                                            className="fa-solid fa-user-doctor"></i></span>Send</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>04</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2" />
                                                                Robert Fox
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td className="selects d-flex">
                                                            {/* <!-- <div className="dropdown">
                                                                <button className="btn btn-secondary" Type="button"
                                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                                    Select Type<span><i
                                                                            className="fa-solid fa-angle-down"></i></span>
                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                    <li><a className="dropdown-item"
                                                                            href="#">Consultations</a></li>
                                                                    <li><a className="dropdown-item" href="#">After Lab
                                                                            Test</a></li>
                                                                </ul>
                                                            </div> --> */}
                                                            <div className="btn-2">
                                                                <select className="form-select"
                                                                    aria-label="Default select example">
                                                                    <option selected>Select Type</option>
                                                                    <option value="1">Consultations</option>
                                                                    <option value="2">After Lab Test</option>
                                                                </select>
                                                            </div>

                                                            <div className="send-btn">
                                                                <button><span><i
                                                                            className="fa-solid fa-user-doctor"></i></span>Send</button>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>05</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center ">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2 " />
                                                                Jenny Wilson
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td className="selects d-flex">

                                                            <div className="btn-2">
                                                                <select className="form-select"
                                                                    aria-label="Default select example">
                                                                    <option selected>Select Type</option>
                                                                    <option value="1">Consultations</option>
                                                                    <option value="2">After Lab Test</option>
                                                                </select>
                                                            </div>

                                                            <div className="send-btn">
                                                                <button><span><i
                                                                            className="fa-solid fa-user-doctor"></i></span>Send</button>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>06</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center ">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2 " />
                                                                Marshall Cook
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td className="selects d-flex">

                                                            <div className="btn-2">
                                                                <select className="form-select"
                                                                    aria-label="Default select example">
                                                                    <option selected>Select Type</option>
                                                                    <option value="1">Consultations</option>
                                                                    <option value="2">After Lab Test</option>
                                                                </select>
                                                            </div>

                                                            <div className="send-btn">
                                                                <button><span><i
                                                                            className="fa-solid fa-user-doctor"></i></span>Send</button>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>07</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center ">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2 " />
                                                                Stephanie Cook
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td className="selects d-flex">

                                                            <div className="btn-2">
                                                                <select className="form-select"
                                                                    aria-label="Default select example">
                                                                    <option selected>Select Type</option>
                                                                    <option value="1">Consultations</option>
                                                                    <option value="2">After Lab Test</option>
                                                                </select>
                                                            </div>

                                                            <div className="send-btn">
                                                                <button><span><i
                                                                            className="fa-solid fa-user-doctor"></i></span>Send</button>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>08</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center ">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2 " />
                                                                Marion James
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td className="selects d-flex">

                                                            <div className="btn-2">
                                                                <select className="form-select"
                                                                    aria-label="Default select example">
                                                                    <option selected>Select Type</option>
                                                                    <option value="1">Consultations</option>
                                                                    <option value="2">After Lab Test</option>
                                                                </select>
                                                            </div>

                                                            <div className="send-btn">
                                                                <button><span><i
                                                                            className="fa-solid fa-user-doctor"></i></span>Send</button>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>09</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center ">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2 " />
                                                                Teresa Holland
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td className="selects d-flex">

                                                            <div className="btn-2">
                                                                <select className="form-select"
                                                                    aria-label="Default select example">
                                                                    <option selected>Select Type</option>
                                                                    <option value="1">Consultations</option>
                                                                    <option value="2">After Lab Test</option>
                                                                </select>
                                                            </div>

                                                            <div className="send-btn">
                                                                <button><span><i
                                                                            className="fa-solid fa-user-doctor"></i></span>Send</button>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>10</td>
                                                        <td className="tbl-content">
                                                            <div className="img d-flex align-items-center ">
                                                                <img src="./assets/images/16.png" alt=""
                                                                    className="img-fluid  me-2 " />
                                                                Zachary Marshall
                                                            </div>

                                                        </td>

                                                        <td>#098835</td>
                                                        <td>+251-1234568</td>
                                                        <td className="selects d-flex">

                                                            <div className="btn-2">
                                                                <select className="form-select"
                                                                    aria-label="Default select example">
                                                                    <option selected>Select Type</option>
                                                                    <option value="1">Consultations</option>
                                                                    <option value="2">After Lab Test</option>
                                                                </select>
                                                            </div>

                                                            <div className="send-btn">
                                                                <button><span><i
                                                                            className="fa-solid fa-user-doctor"></i></span>Send</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <nav aria-label="Page navigation">
                                                <ul className="pagination ms-3">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true"><i
                                                                    className="fa-solid fa-angle-left"></i></span>
                                                        </a>
                                                    </li>
                                                    <li className="page-item active"><a className="page-link" href="#">1</a>
                                                    </li>
                                                    <li className="page-item "><a className="page-link" href="#">2</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                    <li className="page-item">
                                                        <a className="page-link " href="#" aria-label="Next">
                                                            <span aria-hidden="true"><i
                                                                    className="fa-solid fa-angle-right"></i></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            {/* See Doctor */}
                            <div className="see-doctor-sec hide" id="send-doctor-section">

                                {/* main section */}
                                <div className="see-new ">
                                    <div className="container-fluide">
                                        <div className="name ">
                                            <div className="patient-name">
                                                <h6 className="mb-3" id="change-doctor-btn-2">
                                                    <i className="fa-solid fa-chevron-left"></i>
                                                    Change Doctor
                                                </h6>
                                                <div className="d-flex align-items-center flex-wrap mb-4">
                                                    <h6 className="me-4">Patient ID <span>- 25865</span></h6>
                                                    <h6 className="me-4">Patient Name <span>- john Deo</span></h6>

                                                    <div className="search-icon ms-auto me-2">
                                                        <i className="fa-solid fa-magnifying-glass"></i>
                                                        <input type="text" className="search px-2 mb-2"
                                                            placeholder="Search doctors"
                                                            style={{ fontSize: "13px", color:"rgba(160, 164, 168, 1)" }} />
                                                    </div>
                                                </div>
                                                {/* Select Doctors */}

                                                <div className="doctors mb-4">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor.png" alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head-1 d-flex justify-content-between">
                                                                                    <h6>Dr. David Patel </h6>
                                                                                    <input type="radio"
                                                                                        name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Cardiologist</h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>150</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor.png" alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head-1 d-flex justify-content-between">
                                                                                    <h6>Dr. David Patel </h6><input
                                                                                        type="radio" name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Cardiologist</h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>120</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor.png" alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head-1 d-flex justify-content-between">
                                                                                    <h6>Dr. David Patel </h6> <input
                                                                                        type="radio" name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Cardiologist</h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>140</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="doctors mb-4">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor-one.png" alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head-2 d-flex justify-content-between">
                                                                                    <h6>Dr. Jessica Turner </h6>
                                                                                    <input type="radio"
                                                                                        name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Gynecologist</h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>160</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor-one.png"
                                                                                        alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head-2 d-flex justify-content-between">
                                                                                    <h6>Dr. Jessica Turner </h6>
                                                                                    <input type="radio"
                                                                                        name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Gynecologist</h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>140</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor-one.png"
                                                                                        alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head-2 d-flex justify-content-between">
                                                                                    <h6>Dr. Jessica Turner </h6>
                                                                                    <input type="radio"
                                                                                        name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Gynecologist</h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>130</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="doctors mb-5">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor-two.png"
                                                                                        alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head d-flex justify-content-between">
                                                                                    <h6>Dr. Michael Johnson </h6>
                                                                                    <input type="radio"
                                                                                        name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Orthopedic Surgery
                                                                                </h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>50</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor-two.png"
                                                                                        alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head d-flex justify-content-between">
                                                                                    <h6>Dr. Michael Johnson </h6>
                                                                                    <input type="radio"
                                                                                        name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Orthopedic Surgery
                                                                                </h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>60</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="main-box">
                                                                    <div className="sub-box">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="img-box">
                                                                                    <img src="./assets/images/see-doctor-two.png"
                                                                                        alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 text-sec">
                                                                                <div
                                                                                    className="head d-flex justify-content-between">
                                                                                    <h6>Dr. Michael Johnson </h6>
                                                                                    <input type="radio"
                                                                                        name="doctor"
                                                                                        className="form-check-input"
                                                                                        id="radio-toggle" />
                                                                                </div>
                                                                                <hr className="mt-0 mb-2" />
                                                                                <h6 className="mb-1">Orthopedic Surgery
                                                                                </h6>
                                                                                <h6>Consultant Patients :
                                                                                    <span>70</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-grid gap-2 d-md-block mx-auto">
                                                    <button className="btn-one " id="save-appt-doctor-btn-2" type="button">
                                                        Save Appointment
                                                    </button>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        {/* Output Patients En */}
    </div>
  )
}

export default withRouter(withTranslation()(Patients))