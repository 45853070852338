import React, { useCallback, useEffect, useState } from 'react'
import withRouter from '../../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { createSelector } from "reselect"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
    getPharmacyProductsAndSearch as onGetPharmacyProductsAndSearch,
    getAllBankAccounts as onGetAllBankAccounts,
    getSale as onGetSale,
    getPatientAccount as onGetPatientAccount,
    searchPatient as onSearchPatient,
    createSale as onCreateSale,
} from '../../../../slice/thunks'
import { Col, Input, Label, Row } from 'reactstrap'
import SaleRow from '../../../../components/App/Sales/SaleRow'
import DeleteModal from '../../../../components/Global/DeleteModal'
import PrintInvoiceComponent from '../../../../components/Global/PrintOutputs/Invoices/PrintInvoiceComponent'



const SalesCahier = (props) => {
    const username = localStorage.getItem('username') || ""
    const dispatch = useDispatch()
    const history = useNavigate()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const selectLayoutStatePatient = (state) => state.Outputs;

    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            pharmacyProduct: setting.pharmacyProduct,
            patientAccount: setting.patientAccount,
            allBankingAccounts: setting.allBankingAccounts,
            createSaleSuccess: setting.createSaleSuccess,
            sale: setting.sale,
            error: setting.error
        })
    )
    const OutputsProperties = createSelector(
        selectLayoutStatePatient,
        (outputs) =>({
            patients: outputs.patients,
            patient: outputs.patient,
        })
    )

    const { pharmacyProduct, patientAccount, createSaleSuccess, sale, allBankingAccounts, error } = useSelector(SettingsProperties)
    const { patients } = useSelector(OutputsProperties)
    const [searchInput, setSearchInput] = useState("");
    const [ patientOption, setPatientOption ]= useState([])
    const [ banksOption, setBanksOption ] = useState([])
    const [ productsOption, setProductsOption] = useState([])
    const [ selectedPatient, setSelectedPatient ] = useState(null)
    const [ selectedTarget, setSelectedTarget] = useState(null)
    const [ selectedBank, setSelectedBank ] = useState(null)
    const [ selectedProduct, setSelectedProduit] = useState(null)
    const [ currentPatientAccount, setCurrentPatientAccount] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [ items, setItems ] = useState([])
    const [ onHold, setOnHold] = useState(false)
    const [ onPosted, setOnPosted] = useState(false)
    const [ onPosted1, setOnPosted1] = useState(false)
    const [ onKeepBalance, setOnKeepBalance] = useState(false)
    const [ totalAmount, setTotalAmount ] = useState(0)
    const [amountRecieved, setAmountRecieved ] = useState(null)
    const [amountReturn, setAmountReturn ] = useState(0)
    const [ onHold1, setOnHold1 ] = useState(false)
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [modalCashier, setModalCashier] = useState(false)
    const [isAuthor, setIsAuthor] = useState(false)

    useEffect(() => {
        if (patients && patients.items && patients.items.length > 0) {
            const patient_list = patients.items.filter(target => target.patientRecord).map( target => {
                return {
                    label: target.firstName+' '+target.lastName,
                    code: target.patientRef,
                    phone: target.phone,
                    value: target.patientRecord ? target.patientRecord.id : null,
                    type: target.patientType.typeRef,
                    id: target.id,
                    patientRecord: target.patientRecord,
                    patientAccBalance: target.patientAccBalance,
                    admissionStatus: target.admissionStatus
                }
            })
            setPatientOption(patient_list)
        }
    },[patients])

    useEffect(() =>{
        if (!props.sale) {
            setItems([])
        }
    },[selectedPatient])

    useEffect(() => {
        if (props.sale && props.sale.id) {
            loadSale(props.sale.id)
            setSelectedPatient({
                label: props.sale.patient.firstName+' '+props.sale.patient.lastName,
                value: props.sale.patientRecord ? props.sale.patientRecord.id : null,
                type: props.sale.patient.patientType.typeRef,
                id: props.sale.patient ? props.sale.patient.id : null,
                patientRecord: props.sale.patient && props.sale.patient.patientRecord,
                patientAccBalance:  props.sale.patient && props.sale.patient.patientAccBalance ? props.sale.patient.patientAccBalance : 0,
                admissionStatus: props.sale.patient && props.sale.patient.admissionStatus
            })

            let array_items = []

            props.sale.items.forEach(element => {
                let row = {
                    label: element.product.name,
                    value: element.product.id,
                    qtyStock: element.product.quantity,
                    type: element.product.productType,
                    rowTotal: element.finalPrice,
                    unitPrice: element.unitPrice,
                    discount: element.discount,
                    quantity: element.quantity
                }
                array_items.push(row)
            });

            setItems(array_items)
        }

    }, [props.sale])

    useEffect(() => {
        console.log((sale && sale.createdBy) == username, sale);

        // { (!(props.sale && props.sale.id) && selectedPatient && selectedPatient.value) ? false :
        //     (props.sale && props.sale.id)  && (isAuthor === true) ? false :
        //     true
        // }

        if((sale && sale.createdBy) == username){
            setIsAuthor(true)
        }
    },[sale])

    useEffect(() => {
        if (allBankingAccounts && allBankingAccounts.length > 0) {
            const bank_lists = allBankingAccounts.map( bank => {
              return { label: bank.bankName, value: bank.id }
            })
            setBanksOption(bank_lists)
        }
    },[allBankingAccounts])

    useEffect(() => {
        if (selectedBank && selectedBank.value == 'PATIENT_CREDIT') {
            loadPatientAccount()
        } else {
            setCurrentPatientAccount(null)
        }
    },[selectedBank, selectedPatient])

    useEffect(() => {
        if (patientAccount && patientAccount.balance) {
            setCurrentPatientAccount(patientAccount);
        }
    },[patientAccount])

    useEffect(() => {
        if ((selectedPatient && selectedPatient.type)) {
            loadProducts(selectedPatient.type)
        }
    },[selectedTarget, selectedPatient])

    useEffect(() => {
        if (createSaleSuccess && !error) {
            if (onHold1) {
                if (props.section === "pharmacy") {
                    history("/pharmacy/invoices-list/2")
                } else {
                    history("/cashier/all-sales/2")
                }
                props.setIsCreate(false)
                setOnHold1(false)
            } else {
                if (props.section != "pharmacy") {
                    toggleCashier()
                    if(props.reloadTableData) props.reloadTableData()
                }
            }
            if (onPosted1) {
                if (props.section === "pharmacy") {
                    history("/pharmacy/invoices-list/3")
                } else {
                    history("/cashier/all-sales/3")
                }
                props.setIsCreate(false)
                setOnPosted1(false)
            } else {
                if (props.section != "pharmacy") {
                    toggleCashier()
                    if(props.reloadTableData) props.reloadTableData()
                }
            }

            setOnHold(false)
            setOnPosted(false)
            setOnKeepBalance(false)
            setCurrentPatientAccount(null)
        }
    },[createSaleSuccess])

    useEffect(() => {
        if (pharmacyProduct && pharmacyProduct.length > 0) {
            const product_list = pharmacyProduct.map( prod => {
                return {
                    label: prod.name,
                    value: prod.id,
                    qtyStock: prod.quantity,
                    unitPrice: prod.unitPrice,
                    discount: prod.discount,
                    rowTotal: (props.unitPrice * 1) - (((props.unitPrice * 1)*props.discount)/100),
                    type: prod.productType,
                    quantity: 1
                }
            })

            setProductsOption(product_list)
        }

    },[pharmacyProduct])

    useEffect(() => {
        loadPatients()
        loadBakingAccount()
    },[dispatch])

    useEffect(() => {
        if (selectedProduct != null) {
            let current_products = [...items];
            if (!current_products.some((current) => current.value === selectedProduct.value)) {
              const new_products = [...current_products, selectedProduct];
              setItems(new_products);
            }
        }
    }, [selectedProduct]);

    useEffect(() => {
        let total_amount = 0
        if (items && items.length > 0) {
            items.forEach(row => {
                total_amount = total_amount+(row.rowTotal ? row.rowTotal : 0)
            });
            setTotalAmount(total_amount)
        } else {
            setTotalAmount(0)
        }

        setAmountRecieved(null)
    },[items])

    useEffect(() => {
        if (amountRecieved && amountRecieved > 0) {
            setAmountReturn(amountRecieved-totalAmount)
        } else {
            setAmountReturn(totalAmount)
        }
    },[amountRecieved])

    useEffect(() => {
        if (searchInput === "") {
          loadPatients()
        }
    },[searchInput])

    useEffect(() => {
        if (onPosted) {
            setOnPosted1(true)
            saveBills()
        }
    },[onPosted]);

    const loadPatients = useCallback((term) => {
        if (term) {
            dispatch(onSearchPatient({term: term, page: 1, size: 10}))
        } else {
          dispatch(onSearchPatient({term: '', page: 1, size: 10}))
        }
    },[])

    const loadSale = useCallback((id) => {
        dispatch(onGetSale(id))
    })

    const loadPatientAccount = useCallback(() => {
        dispatch(onGetPatientAccount(selectedPatient ? selectedPatient.id : 0))
    })

    const loadProducts = useCallback((currentType, target, term) => {
        if (term) {
            dispatch(onGetPharmacyProductsAndSearch({ term: term, type: currentType, target: target }))
        } else {
            dispatch(onGetPharmacyProductsAndSearch({ type: currentType, target: target }))
        }
    },[])


    const loadBakingAccount = useCallback(() => {
        dispatch(onGetAllBankAccounts(true))
    },[])

    const toggleCashier = useCallback(() => {
        if (modalCashier) {
          setModalCashier(false);
          handlePrintInvoice();
        } else {
          setModalCashier(true);
        }
    }, [modalCashier])


    const handleSelectPatient =(selectedP) => {
        if (selectedP) {
            setSelectedPatient(selectedP)
        } else {
            setSelectedPatient(null)
            loadPatients()
        }
        setMenuIsOpen(false)
    }

    const handleSelectProduct =(selectedP) => {
        setSelectedProduit(selectedP)
    }

    const handleSelectMethod =(selectedM)  =>{
        setSelectedBank(selectedM)
    }

    const handleUpdateRow = (row) => {
        let current_products = [...items];
        const index = current_products.findIndex(objet => objet.value === row.value);
        if (index != -1) {
          current_products[index] = { ...current_products[index], ...row };

          
          setItems(current_products);
        }
    }

    const onClickDelete = (row) => {
        setSelectedProduit(row)
        setDeleteModal(true);
    }

    const handleDelete = () => {
        if (selectedProduct) {
          if (selectedProduct.id) {
          } else {
            let current_products = [...items];
            current_products = current_products.filter((prod) => prod.value != selectedProduct.value )
            setItems(current_products);
          }
          setDeleteModal(false)
        }
    }

    const handleSearchProduct = (value) => {
        if ((selectedPatient && selectedPatient.type)) {
            loadProducts(selectedPatient.type)
        }
    }

    const loadDefaultProducts = () => {
        if ((selectedPatient && selectedPatient.type) && (selectedTarget && selectedTarget.value) ) {
            loadProducts(selectedPatient.type, selectedTarget.value)
        }
    }

    const saveBills = (hold) => {
        let itemsObj = items.map(item => {
            return {
              productId: item.value,
              quantity: item.quantity,
              unitPrice: item.unitPrice,
              finalPrice: item.unitPrice,
              discount: item.discount
            };
        });

        if (hold) {
            setOnHold1(hold)
        }

        let bill = {
            patientRecordId: selectedPatient.value,
            bankAccountId: onHold ? null : selectedBank ? selectedBank.value : null,
            amountReceived: Number(amountRecieved || 0),
            salesOnHold: onHold ? true : hold ? true : false,
            postedToPatient: (selectedPatient && selectedPatient.patientRecord && selectedPatient.patientRecord.id) && onPosted ? true : false,
            keepBalance: onKeepBalance ? true : false,
            items: itemsObj
        }

        if(props.sale && props.sale.id) bill.id = props.sale.id;
        
        dispatch(onCreateSale(bill))
    }

    const handlePrintInvoice = () => {
        setSelectedPatient(null)
        setSelectedTarget(null)
        setSelectedProduit(null)
        setSelectedBank(null)
        setTotalAmount(0)
        setAmountRecieved(null)
        setAmountReturn(0)
        props.setIsCreate(false)
    }

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        control: (provided) => ({
            ...provided,
            width: '100%',
        }),
    };

  return (
    <>
        <div className="home-tab-content">
            <div className="heading-parent-sec">
                <div className="heading-child-one-sec d-flex pt-4" onClick={() => props.setIsCreate(false) } style={{ cursor: "pointer" }}>
                    <i className="fa-solid fa-chevron-left pt-1"></i>
                    <h6>{props.sale ? props.t("sale_on_hold") : props.t("new_sales")}</h6>
                </div>
            </div>
            <div className="row">
                <Col className="col-lg-8 pe-lg-0">
                    <div className="tab-content-left-part">
                        <div className="head-area" style={{ zIndex: "1000" }}>
                            <Col xl={4} sm={4} className='px-2'>
                                <div className='mb-2'>
                                    <Label htmlFor="patient-field" className="form-label">
                                        {props.t("patient")}<span className='text-danger'>*</span>
                                    </Label><br/>

                                    <Select
                                        name="patient"
                                        id="patient-field"
                                        value={selectedPatient}
                                        isMulti={false}
                                        isClearable={true}
                                        onChange={(e) => {
                                            setSelectedPatient(e);
                                        }}
                                        onInputChange={(inputValue, { action }) => {
                                            if (action === "input-change") {
                                                setSearchInput(inputValue);
                                                if (inputValue.length > 2) {
                                                    loadPatients(inputValue);
                                                }
                                            }
                                        }}
                                        inputValue={searchInput} 
                                        options={patientOption}
                                        closeMenuOnSelect={true}
                                        menuIsOpen={menuIsOpen}
                                        onMenuOpen={() => setMenuIsOpen(true)}
                                        onMenuClose={() => setMenuIsOpen(false)}
                                        onBlur={() => setMenuIsOpen(false)}
                                        components={animatedComponents}
                                        filterOption={(option, inputValue) => {
                                            const { label, code, phone } = option.data;
                                            const searchText = inputValue.toLowerCase();
                                            return (
                                                label.toLowerCase().includes(searchText) || 
                                                (code && code.toLowerCase().includes(searchText)) || 
                                                (phone && phone.toLowerCase().includes(searchText)) 
                                            );
                                        }}
                                        isDisabled= {props.sale && props.sale.id ? true : false }
                                    />
                                </div>
                            </Col>
                            <Col xl={4} sm={4} className='px-2'>
                                <div className='mb-2'>
                                    <Label htmlFor="product-field" className="form-label">
                                        {props.t("product")}
                                    </Label><br/>
                                    <Select
                                        name='product'
                                        id='product-field'
                                        value=""
                                        isMulti={false}
                                        isClearable={false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            handleSelectProduct(e);
                                        }}
                                        onInputChange={(inputValue, { action }) => {
                                            if (action === 'input-change') {
                                                if (inputValue.length > 0) {
                                                    handleSearchProduct(inputValue)
                                                } else {
                                                    loadDefaultProducts()
                                                }
                                            }
                                        }}
                                        onMenuOpen={() => {
                                            loadDefaultProducts()
                                        }}
                                        options={productsOption}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        isDisabled= { (!(props.sale && props.sale.id) && selectedPatient && selectedPatient.value) ? false :
                                            (props.sale && props.sale.id)  && (isAuthor === true) ? false :
                                            true
                                        }
                                    />
                                </div>
                            </Col>
                        </div>

                        <div className="table-area" id="home-table">
                            <table className="table">
                                <thead>
                                    <tr scope="row">
                                        <th style={{ width: "10%" }}>{props.t("s_no")}</th>
                                        <th style={{ width: "20%" }}>{props.t("item_name")}</th>
                                        <th style={{ width: "10%" }}>{props.t("price_unit")}</th>
                                        <th style={{ width: "10%" }}>{props.t("discount")}</th>

                                        <th style={{ width: "10%" }}>{props.t("qty")}</th>
                                        <th style={{ width: "10%" }} colSpan="2">{props.t("total")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {items && items.map((row, index) => (
                                    <tr key={row.value}>
                                      <SaleRow row={row} isAuthor={isAuthor} sale={sale} index={index} updateRow= {handleUpdateRow} deleteRow={onClickDelete}/>
                                    </tr>
                                  ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </Col>

                {/* invoiceSide */}
                <div className="col-lg-4 ps-lg-0">
                    <div className="tab-content-right-part">

                        <div className="customer-detail">
                            <h6 className="title">{props.t("customer_detail")}</h6>
                            <h6>{ selectedPatient && selectedPatient.label }</h6>
                            {selectedPatient ?
                                <>
                                    <Row>
                                        <p  style={{ fontWeight: "700" }}>{props.t("acc_balance")}:
                                            { selectedPatient && selectedPatient.patientAccBalance > 0  ?
                                            <span className={"text-success text-bold"}> {selectedPatient.patientAccBalance}</span>
                                            : selectedPatient && selectedPatient.patientAccBalance > 0  ?
                                            <span > {selectedPatient.patientAccBalance}</span>
                                            :<span className={"text-danger"}> {selectedPatient && selectedPatient.patientAccBalance || 0}</span> }
                                        </p>
                                    </Row>
                                    <Row>
                                        <p  style={{ fontWeight: "700" }}>{props.t("admission_status")}:
                                            { selectedPatient && selectedPatient.admissionStatus ?
                                            <span className={"text-success text-bold"}> {props.t("admited")}</span>
                                            :<span className={"text-danger"}> {props.t("not_admited")}</span> }
                                        </p>
                                    </Row>
                                </> : null
                            }
                        </div>

                        <div className="bill-detail">
                            <h6 className="title">{props.t("bill_details")}</h6>
                            <div className="detail-row">
                                <p className="tag">{props.t("sub_total")}</p>
                                <p className="price">{totalAmount}</p>
                            </div>
                            <div className="total-row">
                                <p className="tag">{props.t("total_amount")}</p>
                                <p className="price">{totalAmount}</p>
                            </div>
                        </div>

                        <div className="payment-detail">
                            <div className="payment-mode">
                                <Label htmlFor="bank-field" className="form-label">{props.t("banking_account")}</Label><br/>
                                <Select
                                    name='bank'
                                    id='bank-field'
                                    styles={customStyles}
                                    value={selectedBank}
                                    isMulti={false}
                                    isClearable={false}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        handleSelectMethod(e);
                                    }}
                                    options={banksOption}
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    isDisabled= {(props.section != "pharmacy") ? false : true }
                                />
                            </div>
                            <div className="payment-mode">
                                <label>{props.t("amount_recieved")}</label>
                                <Input
                                    className="form-control"
                                    type='number'
                                    value={amountRecieved}
                                    onChange={(e) => {setAmountRecieved(Number(e.target.value))}}
                                    disabled= {((props.section === "pharmacy") || (selectedBank && selectedBank.value == 'PATIENT_CREDIT')) ? true : false}
                                />
                            </div>

                            {currentPatientAccount && currentPatientAccount.balance ?
                                <div className="total-row">
                                    <p className="tag">{props.t("patient_credit_balance")}:</p>
                                    <p className="price">{currentPatientAccount.balance}</p>
                                </div>
                                :
                                <div className="total-row">
                                    <p className="tag">{props.t("amount_return")}:</p>
                                    <p className="price">{amountReturn && amountReturn > 0 ? Math.round(amountReturn) : 0}</p>
                                </div>
                            }

                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onClick={() => {
                                        setOnKeepBalance(!onKeepBalance);
                                    }}
                                />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    {props.t("keep_balance")}
                                </label>
                            </div>

                            <div className="btn-area d-flex justify-content-start pb-2" style={{ gap: "10px" }}>
                                <button
                                    className="print-btn px-2"
                                    style = { onHold ? { background: "#2563eb", color: "#fff" } : {}}
                                    id="bill-on-btn"
                                    onClick={() => {
                                        let hold = true
                                        saveBills(hold);
                                        if (onKeepBalance) {
                                            setOnKeepBalance(false)
                                        }
                                    }}
                                    disabled= { items && items.length > 0 ? false : true }
                                >
                                    { props.section != "pharmacy" ? props.t("bill_on_hold") : props.t("post_to_cashier")}
                                </button>
                                
                                { selectedPatient && selectedPatient.admissionStatus ?

                                    <button
                                        className="print-btn px-2" 
                                        style = { onPosted ? { background: "#2563eb", color: "#fff" } : {}}
                                        id="bill-on-btn"
                                        onClick={() => {
                                            setOnPosted(true)
                                            if (onKeepBalance) {
                                                setOnKeepBalance(false)
                                            }
                                        }}
                                        disabled= { items && items.length > 0 ? false : true }
                                    >
                                        {props.t("posted_to_patient")}
                                    </button>
                                    :
                                    <></>
                                }
                            </div>
                        </div>

                        <div className="btn-area">
                        {props.section != "pharmacy" ?
                            <button
                                className="print-btn"
                                id="save-print-btn"
                                onClick={(e) => {
                                    saveBills();
                                }}
                                disabled= {
                                    ((selectedBank != null) || onHold || onPosted || onKeepBalance || (totalAmount >= 0)) && (items && items.length > 0) ? false : true}
                            >
                                    {props.t("save_print")}
                            </button>
                            :
                            null
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() =>setDeleteModal(false)} />

        <PrintInvoiceComponent
             isOpen={modalCashier || false }
            toggleCashier={toggleCashier} 
            module="cashier"
            section="sale"
            items={items} 
            sale={sale} 
            totalAmount={totalAmount} 
            amountRecieved={amountRecieved || 0}
        />

    </>
  )
}

export default withRouter(withTranslation()(SalesCahier))
