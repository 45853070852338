import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import Flatpickr from "react-flatpickr"
import { createSelector } from "reselect"
import moment from 'moment'
import makeAnimated from "react-select/animated"
import Select from "react-select"
import { useReactToPrint } from 'react-to-print';
import TableContainerBase from '../../../components/Common/TableContainerBase'
import logo from "../../../assets/images/HMS-Logo.png"
import { Row, Col, Label, Card, CardBody, CardHeader, CardFooter } from 'reactstrap'
import {
    getPatientAccountSummaryDetails as onGetPatientAccountSummaryDetails,
  } from "../../../slice/thunks"

const PatientAccountSummary = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const PatientComponentRef = useRef();
    const animatedComponents = makeAnimated()


    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            PatientsummaryDetail: setting.PatientsummaryDetail,
        })
    )
    const { PatientsummaryDetail } = useSelector(SettingsProperties)

    const loadTransfertOrder = useCallback(() => {
        dispatch(onGetPatientAccountSummaryDetails(id))
    })

    useEffect(() => {
        loadTransfertOrder()
    },[dispatch])



    const handlePrint = useReactToPrint({
        content: () => PatientComponentRef.current,
        documentTitle: 'Patient Account summary',
        copyStyles: true,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                resolve();
            });
        },
    });


    const columns = useMemo(
        () => [
          {
            header: props.t("date"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <div className='d-flex align-items-center'>
                          <span>
                            {moment(cell.row.original.orderDate).format('DD / MM/ YYYY')}
                          </span>
                  </div>
            }
          },
          {
            header: props.t("ref"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{cell.row.original.reference}</span>
            }
          },
          {
            header: props.t("description"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                const truncateText = (text, maxLength) => {
                    if (text.length > maxLength) {
                        return text.substring(0, maxLength) + '...';
                    }
                    return text;
                };
    
                return <span>{truncateText(cell.row.original.description, 15)}</span>;
              }
          },
          {
            header: props.t("amount"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{cell.row.original.amount}</span>
            }
          },
          {
            header: props.t("collector"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{cell.row.original.collector}</span>
            }
          },
        ],
        []
    );

  return (
    <div className="patients-list-content">
            <div className="patients-list  px-2 pt-4 pb-5 ">
                <div className="container-fluid">
                    <div className="heading-parent-sec">
                        <div className="heading-child-one-sec">
                            <h6>{props.t("account_summary")}</h6>
                        </div>
                        <div className="heading-child-two-sec d-flex justify-content-end align-items-center">
                            <div className="create-new-patient-btn my-2 ps-2" onClick={handlePrint} >
                                <i className='fa fa-paper'></i>
                                <div className="create-new-patient-btn-child-two">
                                    <Link to="#">
                                        <button 
                                            className="btn" 
                                        >
                                            {props.t("print")}
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div  className="row pb-2">
                        <div ref={PatientComponentRef} className="see-doctor-patient m-2 p-4">
                            <div>
                                <div className='show-print'>
                                    <Row className="second-select pt-3">
                                        <h5 className='border-bottom pb-2' >{PatientsummaryDetail && PatientsummaryDetail.patientFullName}</h5>
                                    </Row>
                                    <Row >
                                        <Col xl={3} sm={4}>
                                            <label className="form-label">{props.t("patient_no")}: {PatientsummaryDetail && PatientsummaryDetail.patientRef}</label>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xl={3} sm={4}>
                                            <label className="form-label">
                                                {props.t("admission_status")}: 
                                                {PatientsummaryDetail && PatientsummaryDetail.admissionStatus ? 
                                                    <span className="text-success"> {props.t("admited")}</span> : 
                                                    <span> {props.t("not_admited")}</span>                                                }
                                            </label>
                                        </Col>
                                        <Col xl={3} sm={4}>
                                            <label className="form-label">
                                                {props.t("admission_date")}: 
                                                {PatientsummaryDetail && PatientsummaryDetail.admissionDate ? moment(PatientsummaryDetail.admissionDate).format(' DD / MM/ YYYY') : ""}
                                            </label>
                                        </Col>
                                        <Col xl={3} sm={4}>
                                            <label className="form-label">
                                                {props.t("discharge_date")}: 
                                                {PatientsummaryDetail && PatientsummaryDetail.dischargeDate ? moment(PatientsummaryDetail.dischargeDate).format(' DD / MM/ YYYY') : ""}
                                            </label>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='hiden-print'>
                                    <Row className='d-flex justify-content-between m-5 mt-2 mb-0'>
                                        <Col className ='d-flex align-items-center'>
                                            <img src={logo} className="img-fluid" style={{ height: "150px" }} alt="" />
                                        </Col>
                                        <Col className ='d-flex justify-content-end'>
                                            <div className="row">
                                                <h5 className="text-center text-primary" style={{ color: "#d13e6a", fontWeight:"700", margin: "0", padding: "0" }}><b><u>{props.t("hospital_name")}</u></b></h5>
                                                <p className='text-center'>
                                                    <b>{props.t("hospital_po")}</b><br />
                                                    <b>{props.t("hospital_contact")}</b><br />
                                                    <u className='text-primary'>{props.t("hospital_email")}</u><br />
                                                    <u>{props.t("hospital_website")}</u><br />
                                                    <i>{props.t("founder")+": "+props.t("hospital_founder")}</i>
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="d-flex justify-content-center align-items-center text-center">
                                        <div className='border-dark border-bottom w-80 mb-2'></div>
                                        <h1 className="display-6">{PatientsummaryDetail && PatientsummaryDetail.patientFullName}</h1>
                                        <div className='border-danger border-bottom w-75'></div>
                                        <p className='pt-2' >{props.t("patient_account_summary_title")}</p>
                                    </Row>
                                    <Row className='p-1'>
                                        <Col xl={3} sm={4}>
                                            <label>{props.t("admission_ref")}: {PatientsummaryDetail && PatientsummaryDetail.patientRef}</label>
                                        </Col>
                                    </Row>
                                    <Row className='p-1'>
                                        <Col xl={3} sm={4}>
                                            <label>
                                                {props.t("admission_date")}: 
                                                {PatientsummaryDetail && PatientsummaryDetail.admissionDate ? moment(PatientsummaryDetail.admissionDate).format(' DD / MM/ YYYY') : ""}
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className='p-1'>
                                        <Col xl={6} sm={6}>
                                            <label>
                                                {props.t("discharge_date")}: 
                                                {PatientsummaryDetail && PatientsummaryDetail.dischargeDate ? moment(PatientsummaryDetail.dischargeDate).format(' DD / MM/ YYYY') : ""}
                                            </label>
                                        </Col>
                                        <Col xl={6} sm={6}>
                                            {/* <Row className="d-flex justify-content-end align-items-center text-end">
                                                <Col xl={6} sm={6}>
                                                    <label>{props.t("balance")} </label>
                                                </Col>
                                                <Col xl={4} sm={6}>
                                                    <div className='border-primary border p-3'> <h6 className='text-primary'>0 FCFA</h6></div>
                                                </Col>
                                            </Row> */}
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <CardBody className="row d-flex align-items-center justify-content-between mt-3">
                                <TableContainerBase
                                    columns={columns}
                                    data={((PatientsummaryDetail && PatientsummaryDetail.products) || [])}
                                    isGlobalFilter={false}
                                    customPageSize={1000000}
                                    divClass="card table-card table-responsive p-0"
                                    tableClass="table align-middle"
                                    theadClass="table-light text-muted"
                                    tdClass="td"
                                    handlePlanClick= {loadTransfertOrder}
                                    SearchPlaceholder={props.t("search_stock_transfert")}
                                    total={PatientsummaryDetail && PatientsummaryDetail.totalBill}
                                    totalCol={4}
                                />
                            </CardBody>

                            {/* {PatientsummaryDetail && PatientsummaryDetail.totalBill && PatientsummaryDetail.amountPaid ? */}
                                <CardFooter>
                                    <Row className='d-flex justify-content-end show-print pt-4'>
                                        <Col xl={3} sm={4} className='d-flex'>
                                            <h6>
                                                {props.t("current_account_balance")}:  
                                                {(PatientsummaryDetail && PatientsummaryDetail.amountPaid) - (PatientsummaryDetail && PatientsummaryDetail.totalBill) >= 0 ?
                                                    <span className='text-success' style={{ fontSize: "24px", fontWeight: "700" }}>{" "+((PatientsummaryDetail && PatientsummaryDetail.amountPaid) - (PatientsummaryDetail && PatientsummaryDetail.totalBill))}</span>
                                                    : <span  style={{ fontSize: "24px", fontWeight: "700" }}>{" "+(PatientsummaryDetail && PatientsummaryDetail.amountPaid) - (PatientsummaryDetail && PatientsummaryDetail.totalBill)}</span>
                                                }
                                            </h6>
                                        </Col>
                                    </Row>

                                    <Row className='display mt-3'>
                                        <Col xl={6} sm={6}>
                                            <Row className="d-flex justify-content-center align-items-center text-center">
                                                <p style={{ fontWeight: "700" }}><u>{props.t("discharge_notice")}:</u></p>
                                                <div className=' w-50 border-dashed p-4' style= {{ border: "1px dashed #000" }}> </div>
                                                <Row className='mt-5 pt-2'>
                                                    <Col>
                                                        <label style={{ fontWeight: "700" }} className='border-dark border-top border-1'>{props.t("patient_care_taker")}</label>
                                                    </Col>
                                                    <Col>
                                                        <label style={{ fontWeight: "700" }} className='border-dark border-top border-1'>{props.t("cashier_accountant")}</label>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </Col>

                                        <Col xl={6} sm={6}>
                                            <Row className="d-flex justify-content-end align-items-center text-end">
                                                <Row  className='pt-4'>
                                                    <Col className='d-flex justify-content-end align-items-center text-end'>
                                                        <h6 className='align-self-center'> {props.t("total_amount")}: </h6>
                                                    </Col>
                                                    <Col>
                                                        <span style={{ fontSize: "23px" }}>{PatientsummaryDetail && PatientsummaryDetail.totalBill }</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className='d-flex justify-content-end align-items-center text-end'>
                                                        <h6 className='align-self-center'> {props.t("amount_paid")}: </h6>
                                                    </Col>
                                                    <Col className='border-black border-bottom'>
                                                        <span style={{ fontSize: "23px" }}>{PatientsummaryDetail && PatientsummaryDetail.amountPaid}</span>
                                                    </Col>
                                                </Row>
                                                <Row className='p-2'>
                                                    <Col className='d-flex justify-content-end align-items-center text-end'>
                                                        <h6 className='align-self-center' style={{ fontWeight: "700" }}> {props.t("amount_left")}: </h6>
                                                    </Col>
                                                    <Col className='border-black border-bottom'>
                                                        {(PatientsummaryDetail && PatientsummaryDetail.amountPaid) - (PatientsummaryDetail && PatientsummaryDetail.totalBill) >= 0 ?
                                                            <span className='text-success' style={{ fontSize: "23px", fontWeight: "700" }}>{" -"+((PatientsummaryDetail && PatientsummaryDetail.amountPaid) - (PatientsummaryDetail && PatientsummaryDetail.totalBill))}</span>
                                                            : <span  style={{ fontSize: "23px", fontWeight: "700" }}>{" "+(PatientsummaryDetail && PatientsummaryDetail.amountPaid) - (PatientsummaryDetail && PatientsummaryDetail.totalBill)}</span>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </Col>
                                    </Row>

                                </CardFooter>
                                {/* : <></>
                             } */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default withRouter(withTranslation()(PatientAccountSummary))
