import React, { useCallback, useEffect, useMemo, useState } from 'react'
import withRouter from '../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import {
  getSalesPostedToPatient as onGetSales,
 confirmPayment as onConfirmPayment,
 getPaymentMode as onGetPaymentMode,
  getUsers as onGetUsers,
} from '../../../slice/thunks'
import { useSelector, useDispatch } from "react-redux"
import moment from 'moment'
import "flatpickr/dist/themes/material_blue.css"
import { createSelector } from "reselect"
import { Link } from 'react-router-dom'
import SalesCahier from '../../Cashier/AllSales/SalesCahier/SalesCahier'
import TableContainer from '../../../components/Common/TableContainer'
import ConfirmModal from '../../../components/Global/ConfirmModal'
import GlobalLoader from '../../../components/Common/GlobalLoader'


const PhSalesPostedToPatient = (props) => {
  const dispatch = useDispatch()
  const selectLayoutState = (state) => state.Settings;
  const SettingsProperties = createSelector(
      selectLayoutState,
      (setting) =>({
        salesPosted: setting.salesPosted,
        payment: setting.payment,
        paymentModes: setting.paymentModes,
        users: setting.users,
        createSaleSuccess: setting.createSaleSuccess,
        confirmPaymentSuccess: setting.confirmPaymentSuccess,
        error: setting.error,
      })
  )
  const { salesPosted, payment, confirmPaymentSuccess, createSaleSuccess, error } = useSelector(SettingsProperties)
  const [isLoading, setIsLoading] = useState(true)
  const [iscreate, setIsCreate] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [currentPayment, setCurrentPayment] = useState(null)
  const [searchInput] = useState('')
  const [selectedPaymentM ] = useState(null)
  const [selectedUser] = useState(null)
  const [setFilterPayment] = useState(null)
  const [selectedSale, setSelectedSale] = useState(null)


  const loadPayments = useCallback((pageNumber, pageSize, term) => {
    let reqObject = {}
    if ((pageNumber !== null) && (pageNumber !== undefined)) reqObject.pageNumber = pageNumber;
    if ((pageSize !== null) && (pageSize !== undefined)) reqObject.pageSize = pageSize;
    if (term !== null) reqObject.term = term;

    dispatch(onGetSales(reqObject))
  })

  const searchPayments = useCallback((term, pageNumber, pageSize) => {
    if(term) {
      let reqObject = {}
      if ((pageNumber !== null) && (pageNumber !== undefined)) reqObject.pageNumber = pageNumber;
      if ((pageSize !== null) && (pageSize !== undefined)) reqObject.pageSize = pageSize;
      if (term !== null) reqObject.term = term;
      dispatch(onGetSales(reqObject))
    }
  })

  const loadPaymentMode = useCallback(() => {
      dispatch(onGetPaymentMode())
  },)

  const loadUsers = useCallback(() => {
    dispatch(onGetUsers())
  })


  const openSale = useCallback((row) => {
    setIsCreate(true)
    setSelectedSale(row)
  })

  const handleConfirmPayment = () => {
    if (currentPayment) {
      dispatch(onConfirmPayment( currentPayment.id ))
    }
  }

  useEffect(() =>{
    if (props.isActive) {
      setIsCreate(false)
      loadPayments()
      loadUsers()
      loadPaymentMode()
    }
  },[props.isActive, createSaleSuccess])

  useEffect(() => {
    if (salesPosted && salesPosted.page > 0) {
      setIsLoading(false)
    }
  },[salesPosted])

  useEffect(() => {
    if (confirmPaymentSuccess && !error) {
      loadPayments()
      setConfirmModal(false)
      setCurrentPayment(null)
    }
  },[confirmPaymentSuccess, payment, error])


  useEffect(() => {
    if (selectedPaymentM && selectedPaymentM.length > 0) {
      let filter_payment = null
      selectedPaymentM.forEach(row => {
        filter_payment = filter_payment === null ? row.value : filter_payment+","+row.value
      });
      setFilterPayment(filter_payment)
    }
  },[searchInput, selectedPaymentM, selectedUser])

  function capitalizeName(name) {
    if (!name) return '';
    return name
        .split(' ') 
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
        .join(' '); 
  }

  const columns = useMemo(
    () => [
      {
        header: props.t("no"),
        style: "cat",
        enableColumnFilter: false,
        cell: (cellProps) => {
          return <span>
              {salesPosted.page == 1 ? (cellProps.row.index + 1) : (((salesPosted.page-1)*10)+cellProps.row.index + 1) }
          </span>;
        },
      },
      {
        header: props.t("order_ref"),
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.orderRef}</span>
        }
      },
      {
        header: props.t("patient"),
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{capitalizeName(cell.row.original.patient.firstName+" "+cell.row.original.patient.lastName)}</span>
        }
      },
      {
        header: props.t("amount"),
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.amount}</span>
        }
      },
      {
        header: props.t("order_date"),
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>
              {moment(cell.row.original.orderDate).format('DD / MM/ YYYY')}
            </span>
        }
      },
      {
        header: props.t("done_by"),
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.createdBy}</span>
        }
      },
      {
        header: props.t("action"),
        style: "action",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-start">
                <ul className="list-inline hstack mb-0">
                  <li className="list-inline-item edit" title={props.t("open_sale")}>
                    <Link
                      className="text-dark d-inline-block edit-item-btn" style={{fontSize: "15px" }}
                      onClick={() => openSale(cellProps.row.original)}
                    >
                      <i className="fa fa-pencil" aria-hidden="true"></i>
                    </Link>
                  </li>
                </ul>
            </div>
          );
        },
      },
    ],
    [salesPosted, openSale, props]
  );

  return (
    <React.Fragment>
      {!iscreate ?
        <>
          {isLoading ?
            <GlobalLoader />
            :
            <>
              <ConfirmModal show={confirmModal} onConfirmClick={handleConfirmPayment} onCloseClick={() =>setConfirmModal(false)} />

              <div className="patients-list-content">
                <div className="patients-list px-2 pb-5 ">
                  <div className="container-fluid ">
                    <div className="heading-parent-sec ">
                        <div className="heading-child-one-sec pt-4">
                        </div>
                        <div className="heading-child-two-sec pt-4">
                        </div>
                    </div>

                    <div className="card border-0 row p-2">
                      <div className="row">
                        <TableContainer
                          columns={columns}
                          data={((salesPosted && salesPosted.items) || [])}
                          isGlobalFilter={true}
                          customPageSize={10}
                          divClass="card table-card table-warehouse table-responsive"
                          tableClass="table"
                          theadClass="thead-sec table-light"
                          tdClass="td"
                          tableSize={ salesPosted && salesPosted.size }
                          tablePage={ salesPosted && salesPosted.page }
                          tableTotal= { salesPosted && salesPosted.total_items }
                          tableTotalPage= { salesPosted && salesPosted.total_page }
                          onNextPage= {loadPayments}
                          onSearch= {searchPayments}
                          SearchPlaceholder={props.t("search_sale")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </>
        :
        <>
          <SalesCahier section="pharmacy" setIsCreate={setIsCreate} sale={selectedSale} />
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PhSalesPostedToPatient))
