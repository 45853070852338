import * as Yup from "yup"
import { useFormik } from 'formik'
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate} from "react-router-dom"
import { withTranslation } from 'react-i18next'
import withRouter from '../../../../components/Common/withRouter'
import BreadCrumb from '../../../../components/Common/BreadCrumb'

import {
    createPayrollSetting as onCreatePayrollSetting,
    updatePayrollSetting as onUpdatePayrollSetting,
} from "../../../../slice/thunks"
import { Card, CardBody, CardFooter, CardHeader, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"

const CreateDepartment = (props) => {
    const {id, label} = useParams();
    const dispatch = useDispatch();
    const history = useNavigate()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
      selectLayoutState,
      (setting) =>({
        payrollSettings: setting.payrollSettings,
        payrollSetting: setting.payrollSetting,
        createPayrollSetting: setting.createPayrollSetting,
        updatePayrollSetting: setting.updatePayrollSetting,
        deletePayrollSetting: setting.deletePayrollSetting,
        error: setting.error
      })
    )
    const { payrollSettings, payrollSetting, createPayrollSetting, updatePayrollSetting, deletePayrollSetting, error } = useSelector(SettingsProperties)
    const [isEdit, setIsEdit] = useState(false)
    const [currentSetting, setCurrentSetting] = useState(null)

    useEffect(() => {
        if ((id && id.length > 0) && (label && label.length > 0)) {
            setIsEdit(prev => !prev)
        }
    },[])
    
    useEffect(() => {
    if (createPayrollSetting && !error) {
        validation.resetForm()
        history("/payroll/setting/departments")
    }
    },[createPayrollSetting, error, payrollSetting])

    useEffect(() => {
        if (updatePayrollSetting && !error) {
            setIsEdit(false)
            validation.resetForm()
            setCurrentSetting(null)
            history("/payroll/setting/departments")
        }
    },[updatePayrollSetting, error, payrollSetting])

    const validation = useFormik({
        enableReinitialize: true,
    
        initialValues: {
          id: id || '',
          label: label || '',
          settingType: 'Department',
        },
        validationSchema: Yup.object({
          label: Yup.string().required(props.t("you_need_provide_name")),
        }),
        onSubmit: (values) => {
          if (isEdit) {
            const updateForm = {
              id: values.id,
              label: values.label,
              settingType: values.settingType,
            }
            const id = values.id
    
            dispatch(onUpdatePayrollSetting({ data: updateForm, id: id }))
    
          } else {
            const newForm = {
              label: values.label,
              settingType: values.settingType,
            }
            dispatch(onCreatePayrollSetting(newForm))
          }
        },
      })

  return (
    <div className="all-payrollSettings-setting px-3">
        <div className="patients-list py-4 px-2">
            <div className="container-fluid">
                <div className="row list-patients d-flex  justify-content-between">
                    <Link to="/payroll/setting/departments" className="col-lg-5">
                        <BreadCrumb title={props.t("payroll_settings")} pageTitle={props.t("departments")} />
                    </Link>
                </div>
                <Card className="container p-0">
                    <CardHeader className="bg-light p-3">
                        {isEdit ? props.t("edit") : props.t("create_new")}
                    </CardHeader>
                    <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                        }}
                    >
                        <CardBody>
                            <div className="container">
                                <Row>
                                    <Col xl={12} sm={12}>
                                        <div className="mb-3">
                                            <Label
                                                htmlFor="label-field"
                                                className="form-label"
                                            >
                                                {props.t("name")}
                                            </Label>
                                            <Input
                                                name="label"
                                                id="label-field"
                                                type="text"
                                                className="form-control"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.label || ""}
                                                invalid={
                                                    validation.touched.label && validation.errors.label ? true : false
                                                }
                                            />
                                            {validation.touched.label && validation.errors.label ? (
                                                <FormFeedback type="invalid">{validation.errors.label}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                        <CardFooter>
                            <div className='p-3'>
                                <Row className="align-items-center">
                                <div className="col-sm">
                                    <span className='text-danger align-middle'>* : {props.t("required_fields")}</span>
                                </div>
                                <div className="col-sm-auto">
                                    <div className="hstack gap-2 justify-content-end">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={
                                        () => {
                                            validation.resetForm();
                                            setCurrentSetting(null);
                                            setIsEdit(false)
                                            history("/payroll/setting/departments")
                                        }}
                                    >
                                        {props.t("close")}
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                    >
                                        {isEdit ? props.t("update") : props.t("save")}
                                    </button>
                                    </div>
                                </div>
                                </Row>
                            </div>
                        </CardFooter>
                    </Form>

                </Card>
            </div>
        </div>
    </div>
  )
}

export default withRouter(withTranslation()(CreateDepartment))
