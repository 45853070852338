import React from 'react'
import NavItemCs from './NavItemCs'

const NavHeaderCs = ({ navData, onTabChange }) => {

  return (
    <React.Fragment>
        <ul className="nav nav-pills justify-content-start" id="pills-tab" role="tablist">
            {navData.map((row, index) => (
                <NavItemCs key={index} onTabChange={onTabChange} {...row}/>
            ))}
        </ul>
    </React.Fragment>
  )
}

export default NavHeaderCs