import * as Yup from "yup"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import DeleteModal from "../../../components/Global/DeleteModal"
import {
  getBankAccounts as onGetBankAccounts,
  createBankAccount as onCreateBankAccount,
  updateBankAccount as onUpdateBankAccount,
  deleteBankAccount as onDeleteBankAccount,
  getPaymentMode as onGetPaymentMode,
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"
import TableContainerWarehouse from "../../../components/Common/TableContainerWarehouse"

const Banking = (props) => {
    const dispatch = useDispatch()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
      selectLayoutState,
      (setting) =>({
        bankingAccounts: setting.bankingAccounts,
        createBankAccountSuccess: setting.createBankAccountSuccess,
        updateBankAccountSuccess: setting.updateBankAccountSuccess,
        deleteBankAccountSuccess: setting.deleteBankAccountSuccess,
        bankingAccount: setting.bankingAccount,
        paymentModes: setting.paymentModes,
        error: setting.error
      })
    )

    const { 
        bankingAccounts, 
        bankingAccount,
        paymentModes,
        createBankAccountSuccess, 
        updateBankAccountSuccess, 
        deleteBankAccountSuccess, 
        error 
    } = useSelector(SettingsProperties)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [currentAccount, setCurrentAccount] = useState(null)
    const [selectedType, setSelectedType] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)    
    const [ paymentOption, setPaymentOption ] = useState(null)
    const [ selectedPaymentM, setSelectedPaymentM ] = useState(null)
    const [ useInSale, setUseInSale ] = useState(false)
    
    

    const accountType = [
        {label: props.t("current"), value: "Current"},
        {label: props.t("savings"), value: "Savings"},
    ]

    useEffect(() =>{
        loadBakingAccount()
        loadPaymentMode()
    }, [dispatch])

    useEffect(() => {
        if (bankingAccounts && bankingAccounts.page) {
        setIsLoading(false)
        }
    },[bankingAccounts])

    useEffect(() =>{
        if (createBankAccountSuccess && !error) {
          setIsEdit(false)
          validation.resetForm()
          setModal(false)
          setCurrentAccount(null)
          setSelectedType(null)
          setSelectedPaymentM(null)
          loadBakingAccount()
        }
    },[createBankAccountSuccess, error, bankingAccount])

    useEffect(() =>{
        if (updateBankAccountSuccess && !error) {
          setIsEdit(false)
          validation.resetForm()
          setModal(false)
          setCurrentAccount(null)
          setSelectedType(null)
          setSelectedPaymentM(null)
          loadBakingAccount()
        }
    },[updateBankAccountSuccess, error, bankingAccount])

    useEffect(() =>{
        if (deleteBankAccountSuccess && !error) {
          setIsEdit(false)
          validation.resetForm()
          setModal(false)
          setCurrentAccount(null)
          setSelectedType(null)
          setSelectedPaymentM(null)
          loadBakingAccount()
        }
    },[deleteBankAccountSuccess, error, bankingAccount])

    useEffect(() => {
            if (paymentModes && paymentModes.length > 0) {
                const payment_lists = paymentModes.map( row => {
                    return { label: row.title, value: row.value }
                })
    
                setPaymentOption(payment_lists)
            }
    },[paymentModes])

    const loadBakingAccount = useCallback((pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
          dispatch(onGetBankAccounts({page: pageNumber, size: pageSize}))
        } else {
          dispatch(onGetBankAccounts())
        }
    })

    const loadPaymentMode = useCallback(() => {
            dispatch(onGetPaymentMode())
    })

    const toggle = useCallback(() => {
        if (modal) {
          setModal(false);
          validation.resetForm();
          setCurrentAccount(null);
          setSelectedType(null);
          setSelectedPaymentM(null)
        } else {
          setModal(true);
        }
    }, [modal])

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: (currentAccount && currentAccount.id) || '',
            balance: (currentAccount && currentAccount.balance) || null,
            accountName: (currentAccount && currentAccount.accountName) || '',
            bankBranch: (currentAccount && currentAccount.bankBranch) || '',
            accountType: (currentAccount && currentAccount.accountType) || '',
            paymentMode: (currentAccount && currentAccount.paymentMode) || '',
            bankName: (currentAccount && currentAccount.bankName) || '',
            ledgerDisplayRef: (currentAccount && currentAccount.ledgerDisplayRef) || '',
            accountNumber: (currentAccount && currentAccount.accountNumber) || '',
        },

        validationSchema: Yup.object({
            bankName: Yup.string().required(props.t("you_need_provide_name")),
            bankBranch: Yup.string().required(props.t("you_need_provide_branch")),
            accountNumber: Yup.string().required(props.t("you_need_provide_number")),
            accountName: Yup.string().required(props.t("you_need_provide_account_name")),
        }),

        onSubmit: (values) => {

            if (isEdit) {
                const updateForm = {
                    balance: values.balance,
                    accountName: values.accountName,
                    bankBranch: values.bankBranch,
                    accountType: selectedType && selectedType.value,
                    paymentMode: selectedPaymentM && selectedPaymentM.value,
                    bankName: values.bankName,
                    ledgerDisplayRef: values.ledgerDisplayRef,
                    accountNumber: values.accountNumber,
                    useInSales: useInSale
                }
                const id = currentAccount.id
                dispatch(onUpdateBankAccount({ body: updateForm, id: id }))

            } else {
                const newForm = {
                    balance: values.balance,
                    accountName: values.accountName,
                    bankBranch: values.bankBranch,
                    accountType: selectedType && selectedType.value,
                    paymentMode: selectedPaymentM && selectedPaymentM.value,
                    bankName: values.bankName,
                    ledgerDisplayRef: values.ledgerDisplayRef,
                    accountNumber: values.accountNumber,
                    useInSales: useInSale
                }

                dispatch(onCreateBankAccount(newForm))
            }
        },

    })

    const handleOpen = () => {
        setIsEdit(false)
        toggle()
    }

    const handleSelectAccount = (arg) => {
        const selectedAccount = arg
        setCurrentAccount({
            id: selectedAccount.id,
            balance: selectedAccount.balance,
            accountName: selectedAccount.accountName,
            bankBranch: selectedAccount.bankBranch,
            accountType: selectedAccount.accountType,
            bankName: selectedAccount.bankName,
            ledgerDisplayRef: selectedAccount.ledgerDisplayRef,
            accountNumber: selectedAccount.accountNumber
        })
        setSelectedType(accountType.find((element) => element.value === selectedAccount.accountType))
        setSelectedPaymentM(paymentOption.find((element) => element.value === selectedAccount.paymentMode))
        setIsEdit(true)
        setUseInSale(selectedAccount.useInSales)
        toggle();
    }

    const onClickDelete = (ward) => {
        setCurrentAccount(ward);
        setDeleteModal(true);
    }

    function handleDeleteAccount() {
        if (currentAccount) {
          dispatch(onDeleteBankAccount({ data: currentAccount, id: currentAccount.id }))
          loadBakingAccount()
          setDeleteModal(false)
        }
    }

    const columns = useMemo(
        () => [
          {
            header: props.t("name"),
            accessorKey: "accountName",
            style: "cat",
            enableColumnFilter: false,
            cell: (cell) => {
                return <Link to={`/accounting/banking/${cell.row.original.id}`}>{cell.row.original.accountName}</Link>
              }
          },
          {
            header: props.t("bankName"),
            accessorKey: "bankName",
            style: "cat",
            enableColumnFilter: false,
          },
          {
            header: props.t("type"),
            style: "cat",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.accountType}</span>
            }
          },
          {
            header: props.t("account"),
            style: "type",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.accountNumber}</span>
            }
          },
          {
            header: props.t("balance"),
            style: "sale",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.balance}</span>
            }
          },
          {
            header: props.t("action"),
            style: "action",
            cell: (cellProps) => {
              return (
                <div className="d-flex justify-content-center">
                    <ul className="list-inline hstack mb-0">
                        <li className="list-inline-item edit" title="Edit">
                            <Link
                            to="#"
                            className="text-primary d-inline-block edit-item-btn"
                            onClick={() => { const accountData = cellProps.row.original; handleSelectAccount(accountData); }}
                            >
                            <img src="../assets/images/edit.png" alt="" className="me-2"></img>
                            </Link>
                        </li>
                        <li className="list-inline-item" title="Remove">
                            <Link
                            to="#"
                            className="text-danger d-inline-block remove-item-btn"
                            onClick={() => { const accountData = cellProps.row.original; onClickDelete(accountData); }}
                            >
                            <img src="../assets/images/delete.png" alt=""></img>
                            </Link>
                        </li>
                    </ul>
                </div>
              );
            },
          },
        ],
        [handleSelectAccount]
    );
 
  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <DeleteModal show={deleteModal} onDeleteClick={handleDeleteAccount} onCloseClick={() =>setDeleteModal(false)} />
                <div className="patients-list-content">
                    <div className="patients-list px-2 pb-5 ">
                        <div className="container-fluid ">
                            <div className="heading-parent-sec ">
                                <div className="heading-child-one-sec pt-4">
                                    <h6>{props.t("banking_account")}</h6>
                                </div>
                                <div className="heading-child-two-sec pt-4">
                                    <div className="create-new-patient-btn my-2 ps-2"
                                        data-bs-target="#exampleModalSec" onClick={() =>{handleOpen()}}>
                                        <div className="create-new-patient-btn-child-one">
                                            <img src="../assets/images/new-patient/img-one.png" className="img-fluid" />
                                        </div>
                                        <div className="create-new-patient-btn-child-two">
                                            <button className="btn" >{props.t("create_new_account")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card border-0 row p-2">
                                <div className="row">
                                    <TableContainerWarehouse
                                        columns={columns}
                                        data={((bankingAccounts && bankingAccounts.items) || [])}
                                        isGlobalFilter={false}
                                        customPageSize={10}
                                        divClass="card table-card table-warehouse table-responsive"
                                        tableClass="table"
                                        theadClass="thead-sec table-light"
                                        tdClass="td"
                                        tableSize={ bankingAccounts && bankingAccounts.size }
                                        tablePage={ bankingAccounts && bankingAccounts.page }
                                        tableTotal= { bankingAccounts && bankingAccounts.total_items }
                                        tableTotalPage= { bankingAccounts && bankingAccounts.total_page }
                                        onNextPage= {loadBakingAccount}
                                        onSearch= {loadBakingAccount}
                                        SearchPlaceholder={props.t("search_account")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal id='showModal' size='lg' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                        {!!isEdit ? props.t("edit_account") : props.t("create_new_account")}
                    </ModalHeader>
                    <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                    }}>
                    <ModalBody>
                        <input type="hidden" id="id-field" />
                        <Row>
                            <Col xl={6} sm={6}>
                                <div className="mb-3">
                                <Label
                                    htmlFor="wards-field"
                                    className="form-label"
                                >
                                    {props.t("type")}
                                </Label>
                                <Select
                                      name='accountType'
                                      id='accountType-field'
                                      className="dev_kevin"
                                      value={selectedType}
                                      isMulti={false}
                                      isClearable={false}
                                      autoComplete="off" 
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        setSelectedType(e);
                                      }}
                                      options={accountType}
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                />
                                </div>
                            </Col>
                            <Col xl={6} sm={6}>
                                <div className="mb-3">
                                <Label
                                    htmlFor="wards-field"
                                    className="form-label"
                                >
                                    {props.t("payment_mode")}
                                </Label>
                                <Select
                                      name='accountType'
                                      id='accountType-field'
                                      className="dev_kevin"
                                      value={selectedPaymentM}
                                      isMulti={false}
                                      isClearable={false}
                                      autoComplete="off" 
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        setSelectedPaymentM(e);
                                      }}
                                      options={paymentOption}
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                />
                                </div>
                            </Col>

                            <Col xl={4} sm={6}>
                                <div className="mb-3">
                                <Label
                                    htmlFor="wards-field"
                                    className="form-label"
                                >
                                    {props.t("ledger_display_ref")}
                                </Label>
                                <Input
                                    name="ledgerDisplayRef"
                                    id="ledgerDisplayRef-field"
                                    className="form-control"
                                    autoComplete="off"
                                    type="text"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.ledgerDisplayRef || ""}
                                    invalid={
                                        validation.touched.ledgerDisplayRef && validation.errors.ledgerDisplayRef ? true : false
                                    }
                                />
                                {validation.touched.ledgerDisplayRef && validation.errors.ledgerDisplayRef ? (
                                    <FormFeedback type="invalid">{validation.errors.ledgerDisplayRef}</FormFeedback>
                                ) : null}
                                </div>
                            </Col>
                            <Col xl={4} sm={6}>
                                <div className="mb-3">
                                <Label
                                    htmlFor="bankName-field"
                                    className="form-label"
                                >
                                    {props.t("bankName")}<span className='text-danger'>*</span>
                                </Label>
                                <Input
                                    name="bankName"
                                    id="bankName-field"
                                    className="form-control"
                                    autoComplete="off"
                                    type="text"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.bankName || ""}
                                    invalid={
                                    validation.touched.bankName && validation.errors.bankName ? true : false
                                    }
                                />
                                {validation.touched.bankName && validation.errors.bankName ? (
                                    <FormFeedback type="invalid">{validation.errors.bankName}</FormFeedback>
                                ) : null}
                                </div>
                            </Col>
                            <Col xl={4} sm={6}>
                                <div className="mb-3">
                                <Label
                                    htmlFor="bankBranch-field"
                                    className="form-label"
                                >
                                    {props.t("bankBranch")}<span className='text-danger'>*</span>
                                </Label>
                                <Input
                                    name="bankBranch"
                                    id="bankBranch-field"
                                    className="form-control"
                                    autoComplete="off"
                                    type="text"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.bankBranch || ""}
                                    invalid={
                                    validation.touched.bankBranch && validation.errors.bankBranch ? true : false
                                    }
                                />
                                {validation.touched.bankBranch && validation.errors.bankBranch ? (
                                    <FormFeedback type="invalid">{validation.errors.bankBranch}</FormFeedback>
                                ) : null}
                                </div>
                            </Col>

                            <Col xl={6} sm={6}>
                                <div className="mb-3">
                                <Label
                                    htmlFor="accountNumber-field"
                                    className="form-label"
                                >
                                    {props.t("accountNumber")}<span className='text-danger'>*</span>
                                </Label>
                                <Input
                                    name="accountNumber"
                                    id="accountNumber-field"
                                    className="form-control"
                                    autoComplete="off"
                                    type="text"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.accountNumber || ""}
                                    invalid={
                                    validation.touched.accountNumber && validation.errors.accountNumber ? true : false
                                    }
                                />
                                {validation.touched.accountNumber && validation.errors.accountNumber ? (
                                    <FormFeedback type="invalid">{validation.errors.accountNumber}</FormFeedback>
                                ) : null}
                                </div>
                            </Col>
                            <Col xl={6} sm={6}>
                                <div className="mb-3">
                                <Label
                                    htmlFor="accountName-field"
                                    className="form-label"
                                >
                                    {props.t("accountName")}<span className='text-danger'>*</span>
                                </Label>
                                <Input
                                    name="accountName"
                                    id="accountName-field"
                                    className="form-control"
                                    autoComplete="off"
                                    type="text"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.accountName || ""}
                                    invalid={
                                    validation.touched.accountName && validation.errors.accountName ? true : false
                                    }
                                />
                                {validation.touched.accountName && validation.errors.accountName ? (
                                    <FormFeedback type="invalid">{validation.errors.accountName}</FormFeedback>
                                ) : null}
                                </div>
                            </Col>
                            
                            {!isEdit ?
                                <Col xl={12} sm={12}>
                                    <div className="mb-3">
                                    <Label
                                        htmlFor="balance-field"
                                        className="form-label"
                                    >
                                        {props.t("balance")}
                                    </Label>
                                    <Input
                                        name="balance"
                                        id="balance-field"
                                        className="form-control"
                                        autoComplete="off"
                                        type="number"
                                        validate={{
                                        required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.balance || null}
                                        invalid={
                                        validation.touched.balance && validation.errors.balance ? true : false
                                        }
                                    />
                                    {validation.touched.balance && validation.errors.balance ? (
                                        <FormFeedback type="invalid">{validation.errors.balance}</FormFeedback>
                                    ) : null}
                                    </div>
                                </Col>
                                :
                                <></>
                            }
                            <Col xl={6} sm={12}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                        defaultChecked = {useInSale}
                                        onClick={() => {
                                            setUseInSale( prev => !prev);
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        {props.t("use_in_sales")}
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <div className='p-3'>
                        <Row className="align-items-center">
                        <div className="col-sm">
                            <span className='text-danger align-middle'>* :{props.t("required_fields")}</span>
                        </div>
                        <div className="col-sm-auto">
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-secondary" 
                                    onClick={() => { 
                                        setModal(false); 
                                        validation.resetForm(); 
                                        setCurrentAccount(null); 
                                        setIsEdit(false); 
                                        setSelectedType(null); 
                                        setSelectedPaymentM(null) 
                                    }}
                                > 
                                    {props.t("close")} 
                                </button>
                                <button type="submit" className="btn btn-success"> {!!isEdit ? props.t("update") : props.t("save")} </button>
                            </div>
                        </div>
                        </Row>
                    </div>
                    </Form>
                </Modal>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Banking))