import React, { useCallback, useEffect, useState, useRef } from 'react'
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux"
import Select from "react-select" 
import makeAnimated from "react-select/animated"
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useReactToPrint } from 'react-to-print'
import { Row, Col,Label, Input } from 'reactstrap'
import {
    getAllClassesAccount as onGetClassesAccount,
    getIncomesReport as onGetIncomesReport,
    getFinancialYearsList as onGetFinancialYearsList
} from "../../../slice/thunks"
import withRouter from '../../../components/Common/withRouter'
import GlobalLoader from "../../../components/Common/GlobalLoader"
import logo from "../../../assets/images/HMS-Logo.png"
import ExportAsExcel from '../../../components/Global/ExportAsExcel'


const IncomesR = (props) => {
    const dispatch = useDispatch()
    const animatedComponents = makeAnimated()
    const PrintComponentRef = useRef();
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
      selectLayoutState,
      (setting) =>({
        allClassesAccount: setting.allClassesAccount,
        incomeStreamData: setting.incomeStreamData,
        financialYearsList: setting.financialYearsList,
        error: setting.error
      })
    )
    const {
        allClassesAccount,
        incomeStreamData,
        financialYearsList,
        error
    } = useSelector(SettingsProperties)

    const [isLoading, setIsLoading] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [optionClassAccount, setOptionClassAcount] = useState(null);
    const [selectedClassAccount, SetSelectedClassAccount] = useState([]);
    const [financialOption, setFinancialOption] = useState([]);
    const [selectedFinancial, setSelectedFinancial] = useState(null);
    const [stats, setStats] = useState(null);
    const [totals, setTotals] = useState(null);
    const [downloadData, setDownloadData] = useState(null);
    const maxItems = 9;

    const optionMonths = [
        {label: props.t("january"), value: "01"},
        {label: props.t("february"), value: "02"},
        {label: props.t("march"), value: "03"},
        {label: props.t("april"), value: "04"},
        {label: props.t("may"), value: "05"},
        {label: props.t("june"), value: "06"},
        {label: props.t("july"), value: "07"},
        {label: props.t("august"), value: "08"},
        {label: props.t("september"), value: "09"},
        {label: props.t("october"), value: "10"},
        {label: props.t("november"), value: "11"},
        {label: props.t("december"), value: "12"}
    ]


    const loadClassAcount = useCallback(() => {
        dispatch(onGetClassesAccount())
    })

    const loadFinancialYear = useCallback(() => {
        dispatch(onGetFinancialYearsList())
    })

    const loadIncomes = useCallback((financialY, month, classAcounts) => {
        const action = {
            "financialYearId": financialY,
            "filterDate": month,
            "classAccountIds": classAcounts
        }
        dispatch(onGetIncomesReport(action))
    })

    const handlePrint = useReactToPrint({
        content: () => PrintComponentRef.current,
        documentTitle: 'Incomes report',
        copyStyles: true,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                resolve();
            });
        },
    });

    useEffect(() => {
        loadClassAcount()
        loadFinancialYear()
    },[dispatch])

    useEffect(() => {
        if (allClassesAccount && (allClassesAccount && allClassesAccount.length) > 0) {
            setIsLoading(false)
            const data_lists = allClassesAccount.map(row => ({
                label: row.name,
                value: row.id
            }));
            setOptionClassAcount(data_lists);
        }
    },[allClassesAccount])

    useEffect(() => {
        if (financialYearsList && (financialYearsList && financialYearsList.length) > 0) {
            const data_lists = financialYearsList.map(row => ({
                label: row.yearName,
                value: row.id
            }));
            setFinancialOption(data_lists);
        }
    },[financialYearsList])

    useEffect(() => {
        if (selectedYear && (selectedMonth && selectedClassAccount.length >0 && (selectedFinancial && selectedFinancial.value))) {
                const account_ids = selectedClassAccount.map(row => (
                    row.value
                ));
                loadIncomes(selectedFinancial.value, getFormatedDate(selectedYear && selectedYear.value, selectedMonth && selectedMonth.value), account_ids);
        }
    },[selectedFinancial, selectedYear, selectedMonth, selectedClassAccount])

    useEffect(() => {
        if (incomeStreamData && incomeStreamData.stats) {
            const formattedStats = incomeStreamData.stats.map(item => {
                const date = item.date;
                const values = Object.keys(item).filter(key => key !== "date").map(key => item[key]);
                return [date, ...values];
            });

            setStats(formattedStats);
        }
        if (incomeStreamData && incomeStreamData.totals) {
            const values = Object.keys(incomeStreamData.totals).map(key => incomeStreamData.totals[key]);            
            setTotals(prev => [...values]);
        }
    },[incomeStreamData]);

    const getYears = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = currentYear; i >= 2015; i--) {
          years.push({label: i, value: i});
        }
        return years;
    };
    
    const years = getYears();


    
    const getFormatedDate = (year, month) => {
        return `${year}-${month}-01`;
    }

    const getFormatedTextDate = (year, month) => {
        return `${month} ${year}`;
    }

    const handleChange = (selectedOptions) => {
        if (!selectedOptions || selectedOptions.length <= maxItems) {
            SetSelectedClassAccount(selectedOptions || []);
        }
      };


  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <div className="budgeting-sec">
                <div className="container-fluid pt-4">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="heading-budget">
                                <h6 className="m-0 p-0">{props.t("incomes_statement")}</h6>
                            </div>
                        </div>

                    </div>
                    {(selectedMonth && selectedMonth.value) && (selectedClassAccount.length > 0) && (selectedFinancial && selectedFinancial.value)?
                        <div className="filter-record pt-2 d-flex justify-content-between">
                            <div className="filter1 d-flex  align-items-center justify-content-start ">
                            </div>

                            <div className="filter1 d-flex  align-items-center justify-content-start ">
                                <ExportAsExcel data={incomeStreamData && incomeStreamData.stats} fileName={`INCOMES REPORT OF ${selectedMonth && getFormatedTextDate(selectedYear && selectedYear.label, selectedMonth && selectedMonth.label)}`}/>
                                <a className="btn  main_print_detail" onClick={handlePrint}>
                                    {props.t("download_pdf_file")}
                                </a>
                            </div>
                        </div>
                        : <></>
                    }

                    <Row>
                        <Col xl={4} sm={4}>
                            <div className='mb-2'>
                                <Label htmlFor="financialY-field" className="form-label">{props.t("financial_year")}</Label><br/>
                                <Select
                                    name='financialY'
                                    id='financialY-field'
                                    value={selectedFinancial}
                                    isMulti={false}
                                    isClearable={true}
                                    onChange={(e) => {
                                        setSelectedFinancial(e);
                                    }}
                                    options={financialOption}
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                />
                            </div>
                        </Col>
                        <Col xl={4} sm={4}>
                            <div className='mb-2'>
                                <Label htmlFor="month-field" className="form-label">{props.t("year")}</Label><br/>
                                <Select
                                    name='status'
                                    id='status-field'
                                    value={selectedYear}
                                    isMulti={false}
                                    isClearable={false}
                                    onChange={(e) => {
                                        setSelectedYear(e);
                                    }}
                                    options={years}
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                />
                            </div>
                        </Col>
                        <Col xl={4} sm={4}>
                            <div className='mb-2'>
                                <Label htmlFor="month-field" className="form-label">{props.t("month")}</Label><br/>
                                <Select
                                    name='status'
                                    id='status-field'
                                    value={selectedMonth}
                                    isMulti={false}
                                    isClearable={false}
                                    onChange={(e) => {
                                            setSelectedMonth(e);
                                    }}
                                    options={optionMonths}
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                />
                            </div>
                        </Col>
                        <Col xl={12} sm={12}>
                            <div className='mb-2'>
                                <Label htmlFor="status-field" className="form-label">{props.t("classes_account")}</Label><br/>
                                <Select
                                    name='status'
                                    id='status-field'
                                    value={selectedClassAccount}
                                    isMulti={true}
                                    isClearable={true}
                                    onChange={handleChange}
                                    options={optionClassAccount}
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div ref={PrintComponentRef} className="row banks-or-cash-table p-4 mt-0">
                        <div className='hiden-print'>
                            <Row className='d-flex justify-content-between m-5 mb-2'>
                                <Col className ='d-flex align-items-center'>
                                    <img src={logo} className="img-fluid" style={{ height: "150px" }} alt="" />
                                </Col>
                                <Col className ='d-flex justify-content-end'>
                                    <div className="row">
                                        <h5 className="text-center text-primary"><b><u>{props.t("hospital_name")}</u></b></h5>
                                        <p className='text-center'>
                                            <b>{props.t("hospital_po")}</b><br />
                                            <b>{props.t("hospital_contact")}</b><br />
                                            <u className='text-primary'>{props.t("hospital_email")}</u><br />
                                            <u>{props.t("hospital_website")}</u><br />
                                            <i>{props.t("founder")+": "+props.t("hospital_founder")}</i>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="col-12 mb-5 pt-0 mt-0">
                            <div className="custom-table-new-detail pt-0 mt-0">
                                <div className="table-wrapper table-responsive">

                                    <table className="table">
                                        <thead className="table-title-bg">
                                            <tr>
                                                {incomeStreamData && incomeStreamData.columns ?
                                                    incomeStreamData.columns.map((col, index) => {
                                                        return <th key={index} style={{ textAlign: "center" }}>{col}</th>
                                                    })
                                                    : null
                                                }

                                            </tr>
                                        </thead>
                                        <tbody className="main_dable_detail">
                                            {stats && stats.length > 0 ?
                                                    stats.map((row, index) => {
                                                        return <tr key={index}> 
                                                            {row.map((col, i) => {
                                                                return <td key={i} style={{ textAlign: "center" }}>{col}</td>
                                                            })}
                                                        </tr>
                                                    })
                                                    : null
                                            }
                                            <tr>
                                                {totals && totals.length > 0 ?
                                                    <>
                                                        <th style={{ textAlign: "center" }}><h6>{props.t("total")} </h6></th>
                                                        {totals.map((colTotal, index) => {
                                                            return <th key={index} style={{ textAlign: "center" }}>{colTotal}</th>
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(IncomesR))
