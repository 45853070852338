import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { withTranslation } from 'react-i18next';
import withRouter from '../Common/withRouter';


const ExportAsExcel = (props) => {
    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(props.data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
        saveAs(blob, `${props.fileName}.xlsx`);
    };
    
    return (
        <button onClick={exportToExcel} type='button' className="btn  main_print_detail">
            {props.t("download_excel_file")}
        </button>
    );
}

export default withRouter(withTranslation()(ExportAsExcel))
