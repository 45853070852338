import { postLogin, postRefresh, validateToken, postLogout, getUser } from "../../helpers/backend_helper"
import { toast } from 'react-toastify'
// i18n
import { t } from 'i18next'

import { loginSuccess, logoutUserSuccess, apiError, reset_login_flag } from './reducer'
import { setAuthorization, removeAuthorization } from "../../helpers/api_helper"

export const loginUser = (user, history) => async (dispatch) => {
    try {
        let response = postLogin({
            username:user.username,
            password: user.password
        })
        var data = await response
        if (data.data) {
            const token = data && data.accessToken ? data.accessToken : data.data.accessToken
            const refreshToken = data && data.accessToken ? data.accessToken : data.data.refreshToken
            localStorage.setItem("accessToken", JSON.stringify(token))
            localStorage.setItem("refreshToken", JSON.stringify(refreshToken))
            var finallogin = JSON.stringify(data)
            finallogin = JSON.parse(finallogin)

            if (finallogin.message === "successful logged in") {
                setAuthorization(token)
                history('/dashboard')
                dispatch(getCurrentUser())
            } else {
                dispatch(apiError(finallogin))
            }
        }else {
            // toast.error(data.message, { autoClose: 5000 })
            toast.error("Invalid username and password", { autoClose: 5000 })
            dispatch(apiError(data))
        }
    } catch (error) {
        toast.error(t("connection_error"), { autoClose: 5000 })
        dispatch(apiError(error))
    }
}

export const getCurrentUser = () => async (dispatch) => {
    try {
        let response = getUser();
        var data = await response;

        if(data) {
            dispatch(loginSuccess(data.data))
            localStorage.setItem("perm", JSON.stringify(data.data.permissionRefs))
            localStorage.setItem("role", JSON.stringify(data.data.role.roleName))
            localStorage.setItem("username", data.data.username)
        }

    } catch (error) {
        return error
    }
}
export const geValidateToken = (navigate) => async () => { 
    try {
        let resp = await validateToken();
        if (resp.data === "") {
            navigate('/login'); // Redirection si pas de data
        }
    } catch (error) {
        navigate('/login'); // Redirection en cas d'erreur
    }
};

export const refreshToken = (history) => async () => { 
    try {

        const data = {
            "accessToken": JSON.parse(localStorage.getItem("accessToken")),
            "refreshToken": JSON.parse(localStorage.getItem("refreshToken"))
        }

        let resp = await postRefresh(data)
        if (resp.data) {
            // localStorage.removeItem("accessToken")
            // localStorage.removeItem("refreshToken")

            localStorage.setItem("accessToken", JSON.stringify(resp.data.accessToken))
            localStorage.setItem("refreshToken", JSON.stringify(resp.data.refreshToken))
            setAuthorization(resp.data.accessToken)
        } else {
            // history('/login')
        }
    } catch (error) {
        // return history('/login')
    }
}

export const logoutUser = (history) => async (dispatch) => {
    try {
        await postLogout()
        logoutUserSuccess()
    } catch (error) {
        dispatch(apiError(error));
    }

    removeAuthorization()
    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("perm")
    localStorage.removeItem("role")
    history('/login')
}

export const resetLoginFlag = () => async (dispatch) =>{
    try {
      const response = dispatch(reset_login_flag());
      return response;
    } catch (error) {
        dispatch(apiError(error));
    }
}